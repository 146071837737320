import React from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../background-router";

export default function RainbowChart() {
  // const _html =
  //   '<iframe title="Workload e RC" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiNGMwZTYwNWQtMTdiZS00NDY2LTk3MGMtNmNkNTQ1MGU2Mjc1IiwidCI6IjgwMTNjOTFhLTRjNzktNDc5ZC1iMjYxLWJjZDNhMDRkM2I5MSJ9" frameborder="0" allowFullScreen="true"></iframe>';
  const _html =
    '<iframe title="Workload e RC" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiMDhlZGY4ZTItZDM1ZC00NzdhLTg5OWMtNWNlMWU5YjY0YWZkIiwidCI6IjgwMTNjOTFhLTRjNzktNDc5ZC1iMjYxLWJjZDNhMDRkM2I5MSJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>
    </BackgroundRouter>
  );
}
