import { Stack, useTheme } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EscalonadaGoBack from "../components/escalonada-go-back";
import EscalonadaCalendarioLista from "./escalonada-calendario-lista";
import EscalonadaCalendarioTitulo from "./escalonada-calendario-titulo";
import { selectPrizeDrawModal } from "../escalonada-selectors";
import { prizeDrawCloseModal } from "../escalonada-actions";
import { DrawerDialog } from "../../../../extra-components";
import EscalonadaModal from "../escalonada-modal";
import { useDispatch, useSelector } from "react-redux";

function EscalonadaCalendarioVerDia({ escalonates, escalonateDay }) {
  const dispatch = useDispatch();
  const isMobile = useTheme()?.isMobile;
  const history = useHistory();
  const modal = useSelector(selectPrizeDrawModal);
  const closeModal = () => dispatch(prizeDrawCloseModal());

  let escalonateView = [],
    escalonateDayView = "";

  if (history.location?.state) {
    escalonateView = history.location.state.escalonates;
    escalonateDayView = history.location.state.escalonateDay;
  } else {
    escalonateView = escalonates;
    escalonateDayView = escalonateDay;
  }

  return (
    <>
      {modal && (
        <DrawerDialog
          fnGetCorpo={() => <EscalonadaModal modal={modal} />}
          fecharModal={closeModal}
        />
      )}
      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "space-between" }}
      >
        {!isMobile && <EscalonadaGoBack />}
        <EscalonadaCalendarioTitulo day={escalonateDayView} />
        {!isMobile && <div />}
      </Stack>
      <EscalonadaCalendarioLista escalonates={escalonateView} />
    </>
  );
}

EscalonadaCalendarioVerDia.rota = "/escalonada_calendario";

export default EscalonadaCalendarioVerDia;
