import {
  Avatar,
  Box,
  Button,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import React from "react";
import { Body1, Icone } from "../../../../components";
import { format, parseISO } from "date-fns";
import {
  getStatusColor,
  getStatusIcon,
  getStatusName,
} from "../escalonada-utils";
import { red } from "@mui/material/colors";

const STR = {
  areaName: "Área",
  modelsName: "Modelos",
  dateCreated: "Data de criação",
  dateRegister: "Data do registro",
  pontuationName: "Pontuação",
  auditorName: "Auditor",
  auditorTitle: "Auditor sorteado para a escalonada",
  titleAuditorConclused: "Auditor respondeu o questionário",
  attachName: "Anexo",
  titleAttachName: "Visualizar anexo relacionado a vistoria",
};

function PrizeDrawItem({
  areaName,
  modelsName,
  dateCreated,
  dateRegister,
  mat,
  avatar,
  status,
  name,
  pontuation,
  options,
  auditor,
  questionAnswer,
  attachment,
}) {
  return (
    <ListItem secondaryAction={options}>
      <ListItemAvatar>
        <Avatar alt={name} src={avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack alignItems="flex-start">
            <Stack alignItems="end" direction="row" gap={1.5}>
              <Chip
                icon={<Icone icone={getStatusIcon(status)} />}
                label={getStatusName(status)}
                color={getStatusColor(status)}
              />
              <PrizeDrawPontuation pontuation={pontuation} />
            </Stack>
            <Stack direction="row" gap={1} sx={{ mt: 1 }}>
              <Body1>
                {mat} - {name}
              </Body1>
              {questionAnswer && (
                <Box
                  title={STR.titleAuditorConclused}
                  display="flex"
                  alignItems="center"
                >
                  <Icone sx={{ color: red[700] }} icone="QuestionAnswer" />
                </Box>
              )}
            </Stack>
          </Stack>
        }
        secondary={
          <PrizeDrawItemSecondary
            areaName={areaName}
            modelsName={modelsName}
            dateCreated={dateCreated}
            dateRegister={dateRegister}
            pontuation={pontuation}
            auditor={auditor}
            attachment={attachment}
          />
        }
      />
    </ListItem>
  );
}

const PrizeDrawPontuation = ({ pontuation }) => {
  const colorBox = useTheme().palette.success.main;
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="end" gap={1}>
      <Body1>{STR.pontuationName}: </Body1>
      <Box
        sx={{
          p: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          minWidth: 30,
          borderRadius: 2,
          background: colorBox,
        }}
      >
        {pontuation}
      </Box>
    </Stack>
  );
};

const PrizeDrawItemSecondary = ({
  areaName,
  modelsName,
  dateRegister,
  auditor,
  attachment,
}) => {
  return (
    <Stack gap={0.5}>
      <Body1>
        {STR.areaName}: {areaName}
      </Body1>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={0.5}>
          <Body1>{STR.modelsName}: </Body1>
          <Stack direction="row" gap={0.5}>
            {modelsName.map((model) => (
              <Chip
                key={model[0]}
                label={model[1]}
                size="small"
                color="primary"
              />
            ))}
          </Stack>
        </Stack>
        <Body1>
          {STR.dateRegister}: {format(parseISO(dateRegister), "dd/MM/yy")}
        </Body1>
      </Stack>
      {auditor && (
        <Body1 title={STR.auditorTitle} fontWeight="bold">
          {STR.auditorName}:{" "}
          <Chip size="small" color="secondary" label={auditor} />
        </Body1>
      )}
      <Stack direction="row">
        {attachment && (
          <Button
            startIcon={<Icone icone="AttachFile" />}
            variant="outlined"
            size="small"
            target="_blank"
            title={STR.titleAttachName}
            href={attachment}
          >
            {STR.attachName}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default PrizeDrawItem;
