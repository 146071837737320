import React from "react";
import { ContainerAdaptavel } from "../../../extra-components";
import EscalonadaAddUpd from "./escalonada-add-upd";
import EscalonadaDel from "./escalonada-del";
import EscalonadaVer from "./escalonada-ver";
import EscalonadaBaixarExcel from "./escalonada-baixar-excel";
import EscalonadaAuditorFormAdd from "./auditores/escalonada-auditor-form-add";
import EscalonadaAuditorDel from "./auditores/escalonada-auditor-del";
import EscalonadaAuditorFormEdit from "./auditores/escalonada-auditor-form-edit";
import EscalonadaQuestionarioResponder from "./calendario/escalonada-questionario-responder";
import EscalonadaQuestionarioVerResposta from "./calendario/escalonada-questionario-ver-resposta";

function EscalonadaModal({ modal }) {
  let body;
  switch (modal.type) {
    case EscalonadaModal.modal.VIEW:
      body = <EscalonadaVer item={modal.data} />;
      break;
    case EscalonadaModal.modal.ADD:
      body = <EscalonadaAddUpd />;
      break;
    case EscalonadaModal.modal.DEL:
      body = <EscalonadaDel id={modal.data} />;
      break;
    case EscalonadaModal.modal.UPD:
      body = <EscalonadaAddUpd item={modal.data} />;
      break;
    case EscalonadaModal.modal.DOWNLOAD_TO_EXCEL:
      body = <EscalonadaBaixarExcel />;
      break;
    case EscalonadaModal.modal.ADD_AUDITOR:
      body = <EscalonadaAuditorFormAdd />;
      break;
    case EscalonadaModal.modal.DEL_AUDITOR:
      body = (
        <EscalonadaAuditorDel
          mat={modal.data.matricula}
          plant={modal.data.planta}
          idAudit={modal.data.id_auditoria}
        />
      );
      break;
    case EscalonadaModal.modal.EDIT_AUDITOR:
      body = (
        <EscalonadaAuditorFormEdit
          mat={modal.data.matricula}
          name={modal.data.nome}
          plant={modal.data.planta}
          idAudit={modal.data.id_auditoria}
        />
      );
      break;
    case EscalonadaModal.modal.RESPONSE_QUESTION:
      body = <EscalonadaQuestionarioResponder {...modal.data} />;
      break;
    case EscalonadaModal.modal.VIEW_ANSWER:
      body = <EscalonadaQuestionarioVerResposta {...modal.data} />;
      break;
    default:
      body = <div>DEFAULT</div>;
  }
  return (
    <ContainerAdaptavel
      sx={{
        minHeight:
          modal.type === EscalonadaModal.modal.VIEW_CALENDAR ? "80vh" : "50vh",
      }}
    >
      {body}
    </ContainerAdaptavel>
  );
}

EscalonadaModal.modal = {
  VIEW: "VIEW",
  ADD: "ADD",
  DEL: "DEL",
  UPD: "UPD",
  DOWNLOAD_TO_EXCEL: "DOWNLOAD_TO_EXCEL",
  ADD_AUDITOR: "ADD_AUDITOR",
  DEL_AUDITOR: "DEL_AUDITOR",
  EDIT_AUDITOR: "EDIT_AUDITOR",
  RESPONSE_QUESTION: "RESPONSE_QUESTION",
  VIEW_ANSWER: "VIEW_ANSWER",
};

export default EscalonadaModal;
