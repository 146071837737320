/**
 * Arquivo de traduções de strings de texto do sistema baseado no idioma informado.
 * Esta estrutura é padrão a todos os arquivos dentro desta pasta de deve seguir a
 * mesma sequencia de chave_valor dos demais.
 *
 * O padrão é link: { chave: valor }, sendo o "link" o nome da URL do menu em acesso
 * via lowercase.
 *
 * TRADUÇÃO PT-BR
 */

const translate = {
  components: {
    calendario: {
      sunday: "Domingo",
      monday: "Segunda",
      tuesday: "Terça",
      wednesday: "Quarta",
      thursday: "Quinta",
      friday: "Sexta",
      saturday: "Sábado",
      prev: "Recuar",
      next: "Avançar",
      beforeMonth: "Mês anterior",
      afterMonth: "Próximo mês",
      today: "Hoje",
    },
    filtro_por_periodo: {
      titleApplyFilter: "Aplicar filtro para dados",
      fromToday: "de hoje.",
      lastDays: "dos ultimos",
      days: "dias",
      today: "Hoje",
      perPeriod: "Por periodo",
      filterApplied: "Filtro aplicado no periodo",
      filterAppliedErrors: "Filtro aplicado para erros por periodo definido",
      titleClearFilter: "Limpar filtro aplicado",
      labelClearFilter: "Limpar filtro",
      labelFrom: "De",
      labelTo: "Ate",
      errorDateFrom: "* Data de obrigatória",
      errorDateTo: "* Data até obrigatória",
      title: "Escolha entre as datas de e ate",
      subtitle:
        "Se atenha para não escolher a data DE maior que a ATE, pois assim seu filtro ficará vazio.",
    },
    filtro_avancado: {
      title: "Escolha o tipo de filtro a ser aplicado.",
    },
    row_paginate_template: {
      labelButtonAdd: "ADD",
      titlePagination: "Clique para alterar entre as paginas",
      titleButtonOptions: "Clique para exibir as opções",
      labelButtonOptions: "Opções",
      titlePage: "Template de paginação como registro",
      titleButtonAdd: "Clique para incluir",
    },
    aniversariantes: {
      title: "Lista de aniversariantes",
      titleCompany: "Aniversariantes da empresa",
      erroGetBirthDay: "Erro ao tentar recuperar aniversariantes",
      altImg: "Imagem aniversariantes",
      today: "Hoje",
      labelBtnNext: "Próximos",
      greeting: "Dê os parabéns 🥳",
    },
    entrada_form: {
      labelBtnSend: "ENVIAR",
    },
    drawer_dialog: {
      title: "Clique para fechar",
      labelBtnClose: "FECHAR",
    },
    fab_extend: {
      title: "Clique para incluir",
      text: "Adicionar",
    },
    confirmar: {
      yes: "SIM",
      no: "NÃO",
    },
  },
  lesson: {
    titleHeaderTable: "LISTA MASTER DE LIÇÕES APRENDIDAS",
    nameAndEmail: "NOME / EMAIL",
    dateCreated: "DATA DE CRIAÇÃO",
    plant: "PLANTA",
    pn: "PART NUMBER",
    descriptionPiece: "DESCRIÇÃO DA PEÇA",
    categoryAnomaly: "CATEGORIA DE ANOMALIA",
    anomalyFound: "ANOMALIA ENCONTRADA",
    identifyLL: "IDENTIFICAÇÃO DA LL",

    title: "FORMULÁRIO DE LIÇÕES APRENDIDAS",
    labelButtonGoBack: "Voltar",
    titleButtonBack: "Retornar a página de Lições Aprendidas",
    labelDownloadPDF: "Baixar Em PDF",
    titleButtonDownloadPdf: "Clique para baixar em PDF",
    placeholder: "Digite aqui...",
    labelImpact: "IMPACTO DO PROJETO",
    labelPlant: "PLANTA",
    labelPN: "PART NUMBER",
    labelProcessUnit: "UNIDADE DO PROCESSO",
    labelOperation: "OPERAÇÃO",
    labelLine: "LINHA",
    labelAreaAffected: "AREA AFETADA",
    labelDate: "DATA",
    labelDescAnomaly: "DESCRIÇÃO DA ANOMALIA",
    labelTitleEffect1: "QUAL É O SINTOMA efeito do problema ?",
    labelImageKO: "IMAGEM KO",
    labelImageOK: "IMAGEM OK",
    labelCauseRoot: "CAUSA RAIZ",
    labelTitleDescriptionProblem: "DESCRIÇÃO DO PROBLEMA 5W2H",
    labelWhat: "What/ What happened?",
    labelWhy: "Why/ Why it is a problem? ",
    labelWhen: "When/ When did it happen ?",
    labelWhere: "Where/ Where has been detected ?",
    labelWho: "Who/ Who has detected it ?",
    labelHow: "How/ How has it been detected?",
    labelHowMany: "How many/ How many ?",
    labelTitleEffect: "EFEITO",
    labelProblemImage: "PROBLEMA",
    labelSolutionImage: "SOLUÇÃO",
    labelDescProblem: "DESCRIÇÃO DO PROBLEMA",
    labelDesSolution: "DESCRIÇÃO DA SOLUÇÃO",
    labelDrawingMathematicalImage: "DESENHO MATEMÁTICA",
    labelFabricant: "FABRICANTE",
    labelTitleDocDefault: "ATUALIZAÇÃO DOS DOCUMENTOS PADRÃO",
    labelTableTitleDesc: "DESCRIÇÃO",
    labelTableTitleRevision: "NECESSÁRIO REVISAR? - SIM/NÃO",
    labelTableResponsible: "RESPONSÁVEL",
    labelTableData: "DATA",
    labelTableAccomplished: "REALIZADO",
    labelObservation: "OBSERVAÇÃO",
    labelCust: "CUSTO",
    labelBenef: "BENEFICIO",
    labelImageInsert: "Insira a imagem aqui",
    labelBtnAddEffect: "Incluir Efeito",
    labelBtnAddUpdateDoc: "CLIQUE AQUI PARA INSERIR",
    titleRemoveImageUpload: "Clique para escolher uma imagem para upload",
    titleRemoveEffect: "Clique para remover o efeito",
    titleFormAddTypeUpdate: "PREENCHA OS CAMPOS ABAIXO",
    errorListUpdate: "* Campo obrigatório",
    errorRevision: "* Campo obrigatório",
    errorResponsible: "* Campo obrigatório",
    errorDate: "* Campo obrigatório",
    errorAccomplished: "* Campo obrigatório",
    errorObservation: "* Mínimo de 3 caracteres",
    labelBtnSend: "ENVIAR",
    placeholderMoney: " 0,00",
    messageErrors: {
      idImpact: "* Escolha um impacto do projeto",
      plant: "* Escolha uma planta",
      idUnitProcess: "* Escolha uma unidade de processo.",
      data: "* Escolha uma data.",
      operacao: "* Escolha uma operação",
      area: "* Escolha uma linha.",
      pn: "* Escolha um Part Number",
      arvore: "* Escolha um setor",
      anomaly: "* A anomalia deve ser informada",
      imageKO: "imagem_ko",
      imageOK: "imagem_ok",
      causeRoot: "* A causa raiz deve ser informada",
      what: '* A pergunta "O que" deve ser informada',
      why: '* A pergunta "Por que" deve ser informada',
      when: '* A pergunta "Quando" deve ser informada',
      where: '* A pergunta "Onde" deve ser informada',
      who: '* A pergunta "Quem" deve ser informada',
      how: '* A pergunta "Como" deve ser informada',
      how_many: '* A pergunta "Quantos" deve ser informada',
      imageProblem: "imagem_problema",
      imageSolution: "imagem_solucao",
      descriptionProblem: "* A descrição do problema precisa ser informada",
      descriptionSolution: "* A descrição da solução precisa ser informada",
      imageDrawing: "imagem_desenho",
      fabric: "* O fabricante  precisa ser informado",
      effect: "efeito_",
      updateDoc: "atualizacao_documentos",
      coust: "* O custo precisa ser informado",
      benef: "* O beneficio precisa ser informado",
    },
  },
  ponto_eletronico: {
    title: "Registro de marcações",
    closeDrawer: "Fechar",
    btnEnviar: "Enviar",
    labelField: "Periodo",
    mobileFieldDay: "Dia",
    mobileFieldData: "Data",
    mobileFieldInput: "Entrada",
    mobileFieldOut: "Saída",
    mobileFieldView: "Ver",
    goBackEquip: "Retornar a equipe",
    titleUpdateCache: "Clique aqui para atualizar o cache do ponto eletrônico",
    resultPartial: "Resultados Parciais",
    headerOne: "DIA",
    headerTwo: "DATA_BATIDA",
    headerThree: "1E",
    headerFour: "1S",
    headerFive: "2E",
    headerSix: "2S",
    headerSeven: "EXTRA",
    headerEight: "ADICIONAL",
    headerNine: "FALTA",
    headerTen: "ABONO",
    headerEleven: "SITUACAO",
  },
  contra_cheque: {
    titulo: "Contra-cheque",
    campoAnos: "Anos",
    campoMeses: "Meses,",
    contraChequeTitulo: "Informações Pessoais",
    contraChequeMatricula: "Matricula",
    contraChequeNome: "Nome",
    contraChequeFuncao: "Função",
    contraChequeAdmissao: "Data de admissão",
    contraChequeVencimentos: "Vencimentos",
    contraChequeDescontos: "Descontos",
    contraChequeLiquido: "Liquido",
    contraChequeBaseDeCalculo: "Base de calculo",
    contraChequeCod: "Cód",
    contraChequeDescricao: "Descrição",
    contraChequeRef: "Ref",
    contraChequeValor: "Valor",
    btnBaixar: "Baixar",
    btnBaixarTitulo: "Gerar PDF deste Contra-cheque",
    cnpj: "CNPJ",
    endereco: "Endereco",
    reciboSalario: "RECIBO DE PAGAMENTO DE SALÁRIO",
    codigo: "Código",
    descricao: "Descrição",
    referencia: "Referência",
    vencimentos: "Vencimentos",
    descontos: "Descontos",
    oneMonth: "JAN",
    twoMonth: "FEV",
    threeMonth: "MAR",
    fourMonth: "ABR",
    fiveMonth: "MAI",
    sixMonth: "JUN",
    sevenMonth: "JUL",
    eightMonth: "AGO",
    nineMonth: "SET",
    tenMonth: "OUT",
    elevenMonth: "NOV",
    twelveMonth: "DEZ",
    thirteenMonth: "13°",
    updateCache: "Atualizar cache da folha em aberto",
  },
  galeria_de_fotos: {
    titleBtnAdd: "Adicione um novo album",
    labelBtnAddAlbum: "Novo Album",
    labelBtnAddPhoto: "Adicionar Fotos",
    titleEditAlbum: "Alterar assunto e titulo do album",
    titleDelAlbum: "Excluir album, inclui fotos,curtidas e comentarios",
    labelEditAlbum: "Editar Album",
    labelDelAlbum: "Excluir Album",
    titleViewOptions: "Clique para ver as opções",
    titleViewPhotosAlbum: "Clique aqui para ver as fotos",
    postedOn: "Postado em",
    titleTotalOf: "Total de",
    titleInAlbum: "fotos no album",
    titleAlbumVisibleTo: "Album visivel para",
    titleFormAddAlbum: "Formulario para cadastro de album",
    descriptionFormAddAlbum:
      "Preencha os campos para a intenção de criação de um album de fotos descrevendo um titulo, assunto e visibilidade do album",
    labelFormTitleAlbum: "* Titulo",
    placeholderTitleAlbum: "Descreva o titulo aqui",
    errorTitleAlbum: "* Minimo de 3 caracteres",
    labelFormSubjectAlbum: "* Assunto",
    placeholderSubjectAlbum: "Descreva o assunto aqui",
    errorSubjectAlbum: "* Minimo de 3 caracteres",
    labelPlantAlbum: "* Album visivel para",
    errorPlantAlbum: "Escolha ao menos uma planta",
    labelBtnSendAlbum: "SALVAR",
    titleFormDelAlbum: "Tem certeza que deseja excluir ?",
    subjectFormDelAlbum:
      "Se optar por excluir perderá fotos, curtidas e comentários sobre este album.",
    labelBtnNo: "Não",
    labelBtnYes: "Sim",
    listOfPhotos: "Fotos do album",
    titleImageOfAlbum: "Imagem do album",
    titleTotalOfLikes: "Total de curtidas",
    titleClickViewLegend: "Clique para editar a legenda da foto",
    titleClickDelPhoto: "Clique para editar a legenda da foto",
    titleTotalOfComments: "Total de comentários",
    altPhoto: "Imagem da album",
    titlePhotoOfAlbum: "Foto do album",
    goBack: "Recuar",
    downloadPhoto: "Baixar",
    closePhoto: "FECHAR",
    nextPhoto: "AVANÇAR",
    thisFile: "Este arquivo",
    notAccept: "não é um tipo aceitável",
    labelFormFilesPhoto:
      "Escolha as fotos que serão inseridas para fazerem parte deste album.",
    labelFormFile: "Fotos",
    labelBtnSendPhoto: "ENVIAR",
    listOfComments: "Lista de comentarios",
    confirmationDelComment: "Deseja realmente excluir este comentário ?",
    placeholderAddComment: "Digite seu comentário",
    listOfLikes: "Lista de Curtidas",
    titleClickChooseLike: "Clique para escolher a curtida",
    youLike: "Você curtiu",
    closeLike: "Fechar",
    like: "Curtir",
    titleFormUpdLegend: "Formulario para editar legenda",
    writeNewLegendPhoto: "Escreva a nova legenda para a foto",
    labelFormLegend: "* Legenda",
    placeholderFormLegend: "Descreva a legenda aqui",
    errorLegend: "Minimo de 3 caracteres",
    labelBtnSendLegend: "Minimo de 3 caracteres",
    titleFormDelPhoto: "Tem certeza que deseja excluir ?",
    subtitleFormDelPhoto:
      "Se você optar por excluir perderá todas as curtidas e comentarios sobre esta foto",
  },
  feed_rh: {
    titleBirthDayCompany: "Aniversariantes de Empresa ",
    errStatusNotOK: "Erro ao tentar obter o estado emocional",
    errUnknown: "ERRO DESCONHECIDO",
    titleEmotionalClimate: "Clima empresarial",
    descriptionEmotionalClimate: "Como você esta se sentindo ?",
    today: "Hoje",
    btnNewPost: "NOVO POST",
    feedNotFound: "Nenhum Feed criado até o momento.",
    btnEdit: "Editar",
    btnDelete: "Excluir",
    titlePostedOn: "Postado em:",
    viewLikes: "Ver curtidas",
    notComments: "nenhum comentário",
    notLikes: "nenhuma curtida",
    viewMinus: "Ver menos",
    viewMore: "Ver mais",
    postedOn: "Postado em ",
    titleWhenComments: "Quantos comentários",
    altImageFeed: "Imagem de logo no feed",
    comment: "comentário",
    comments: "comentários",
    titleLikedYou: "Você curtiu isso",
    titleLike: "Curtir a postagem",
    like: "curtida",
    likes: "curtidas",
    animationLike: "Curtir",
    animationHappy: "Alegre",
    animationZen: "Entusiasmado",
    animationLove: "Amando",
    animationConfused: "Confuso",
    animationSad: "Triste",
    animationUngry: "Nervoso",
    titleFeedLikes: "Quantidade de curtidas por tipo",
    formDelTitleFeedDel: "Você tem certeza disto ?",
    formDelSubtitleFeedDel:
      "Se você optar por confirmar a exclusão do feed perderá todos os comentários e curtidas sobre este feed.",
    formAddUpdLabelTitle: "Titulo",
    formAddUpdLabelSubject: "Assunto",
    formAddUpdLabelObservation: "Observação",
    formAddUpdLabelFile: "Arquivo",
    formAddUpdLabelPlant: "Feed visível para",
    formAddUpdPlaceholderTitle: "Descreva o titulo aqui",
    formAddUpdPlaceholderSubject: "Descreva o assunto aqui",
    formAddUpdPlaceholderObservation: "Descreva a observação aqui",
    formAddUpdPlaceholderFile: "Arquivo",
    formAddUpdPlaceholderPlant: "Feed visível para",
    formAddUpdErrorTitle: "* O campo do titulo deve ter no mínimo 2 caracteres",
    formAddUpdErrorSubject: "* O campo assunto deve ter no mínimo 2 caracteres",
    formAddUpdErrorFile: "* O arquivo não foi enviado",
    formAddUpdErrorPlant: "* Escolha ao menos uma unidade a ser anexada",
  },
  ouvidoria_view: {
    title: "Ouvidoria",
    id: "ID",
    total: "Total de ouvidorias",
    subject: "Assunto",
    situation: "Situação",
    dateCreated: "Data de criação",
    interaction: "Interação: ",
    labelComponentPagination: "Clique para alternar entre as paginas",
    themePanel: "Temas",
    typePanel: "Tipos",
    titleBtnAdd: "Clique para abrir uma nova ouvidoria",
    titleBtnSearch: "Clique para pesquisar sobre uma ouvidoria pelo Token",
    labelBtnAdd: "OUVIDORIA",
    labelBtnSearch: "TOKEN",
    noData: "Não foram encontradas ouvidorias",
    noDataInteraction: "Ouvidoria não encontrada",
    titleAdd: "Abrir ouvidoria",
    description: "Descrição",
    plant: "Planta",
    attach: "Anexo",
    placeholderSubject: "Escreva o assunto",
    placeholderText: "Mais detalhes sobre a ouvidoria",
    errorId: "* Escolha ao menos um ID",
    errorType: "* Necessário escolher um tipo",
    errorTheme: "* Necessário escolher um tema",
    errorSubject: "* Mínimo de 3 caracteres",
    errorDescription: "* Descreva a sua solicitação",
    errorSituation: "* Escolha o novo status",
    detailsOmbudsman: "Detalhes",
    titleBtnGoBack: "Retornar a tela de ouvidorias",
    labelBtnGoBack: "Retornar",
    titleInteraction: "Interagir",
    labelBtnInteraction: "INTERAGIR",
    ombudsman: "Ouvidoria: ",
    titleTab1: "Histórico",
    titleTab2: "Detalhes",
    placeholderSearchToken: "Token",
    titleSearchToken: "Busca por Token",
    titleOmbudsmanCopyToken: "Ouvidoria anônima criada",
    descriptionToken:
      "Caso deseje acompanhar esta ouvidoria clique no token para copia-lo.",
    tokenCopySuccess: "Token copiado com sucesso",
    labelBtnFilter: "Filtro Avançado",
    titleBtnFilter: "Clique para realizar um filtro avançado.",
    titleFilter: "Selecione o filtro",
    titleFilterId: "Clique para selecionar o filtro por ID",
    titleFilterTheme: "Clique para selecionar o filtro por tema",
    titleFilterType: "Clique para selecionar o filtro por tipo",
    titleFilterSituation: "Clique para selecionar o filtro por status",
    statusOpen: "Em Aberto",
    statusClosed: "Encerrada",
    statusMonitoring: "Em Analise",
    ombudsmanStatus: "Status",
    ombudsmanNumber: "Ouvidoria Número",
    createdAt: "Criada em",
    lastInteraction: "Ultima interação",
    type: "Tipo",
    theme: "Tema",
    owner: "Solicitante",
    situacao: "Situação",
  },
  colaboradores: {
    pageTitle: "Manutenção colaboradores",
    fabTitle: "Adicionar um novo colaborador",
    fabLabel: "ADICIONAR",
    labelMat: "Matricula",
    placeholderMat: "000000",
    labelThree: "ARVORE",
    placeholderThree: "Escolha a arvore",
    labelIsManager: "É gestor ?",
    errorMat: "* Campo obrigatório",
    errorThree: "* Campo obrigatório",
    formAddTitle: "Cadastro de colaborador",
    formAddDescription:
      "Informe a matricula do colaborador, setor e se o mesmo é um gestor.",
    filterLabelManager: "Gestor",
    filterLabelEligible: "Elegivel",
    filterLabelActive: "Ativo",
    filterLabelInactive: "Inativo",
    labelTotal: "Total",
    optionsLabel: "Opções",
    optionLabelMenu: "ALTERAR MENU(S)",
    optionLabelManager: "GESTOR X SITUAÇÃO X ELEGIVEL",
    optionLabelInfoPerson: "ALTERAR INFORMAÇÕES PESSOAIS",
    dateOfContract: "Adimissão",
    labelManagerOfThree: "Gestor da Árvore",
    colabItemTitle: "Quantidade de menus atribuidos",
    menuOfAccessTitle: "Lista de menus de acesso",
    menuOfAccessDescription:
      "Listagem de todos os menus disponíveis que podem ser (ou estão) atribuídos ao colaborador",
    placeholderFilterColab: "Filtro para os menus disponiveis",
    labelActiveInactiveAll: "Ativar / Desativar TODOS",
    managerActiveEligibleTitle: "Gestor X Ativo X Elegível",
    managerActiveEligibleDescription:
      "Ativa ou desativa o colaborador para ser gestor, ativa ou desativa para ser elegível ou se o mesmo está autorizado a logar no sistema.",
    labelEnableDisable: "Habilita / Desabilita",
    labelPasswdTitle: "Senha",
    labelPasswdDescription: "Digite uma nova senha para o colaborador",
    labelPasswdPlaceholder: "Digite a senha",
    errorPasswd: "* A senha deve ter ao menos 6 caracteres",
    labelBtnSavePasswd: "Salvar Nova Senha",
    labelThreeTitle: "Árvore",
    labelThreeDescription: "Altere a árvore do colaborador caso desejado",
    labelThreePlaceholder: "Escolha a arvore",
    labelBtnSaveThree: "Atualizar Arvore",
    labelEmailTitle: "E-mail",
    labelEmailDescription: "Altere ou atribua um novo e-mail ao colaborador",
    labelEmailPlaceholder: "Ex: nome@email.com",
    errorEmail: "* Ex: nome@email.com",
    labelBtnSaveEmail: "Alterar Email",

    labelName: "Nome",
    placeholderName: "Nome do colaborador",
    errorName: "* Campo obrigatório",
    labelPlant: "Planta",
    placeholderPlant: "Escolha a planta",
    errorPlant: "* Campo obrigatório",
    labelFunction: "Cargo",
    placeholderFunction: "Escolha o cargo do colaborador",
    errorFunction: "* Campo obrigatório",
    labelDateAdmission: "Data de admissão",
    placeholderDateAdmission: "Escolha a data de admissão",
    errorDateAdmission: "* Campo obrigatório",
    placeholderPassword: "Digite a senha",
    labelPassword: "Senha",
    errorPassword: "* A senha deve ter ao menos 6 caracteres",
    addColabTab1: "INCLUSÃO AUTOMÁTICA",
    addColabTab2: "INCLUSÃO MANUAL",
    formAddTitleManual: "Inclusão manual de colaborador",
    formAddDescriptionManual:
      "Informe os dados do colaborador para a inclusão manual.",
  },
  minha_equipe: {
    noDataTitle1: "Escolha uma das opções",
    noDataTitle2: "laterais",
    noDataTitle3: "do painel para exibir dados",
    placeholderSearchEmployee: "Digite o nome ou o cargo",
    optionsPanelPointTitle: "Ponto eletrônico",
    optionsPanelPointDescription: "Veja o ponto eletrônico da equipe",
    optionsPanelBankOfHoursTitle: "Banco de horas",
    optionsPanelBankOfHoursDescription: "Veja o banco de horas da equipe",
    optionsPanelBankOfHoursGeneralTitle: "Banco de horas geral",
    optionsPanelBankOfHoursGeneralDescription: "Veja o banco de horas geral",
    optionsPanelPerformanceEvaluationTitle: "Avaliação De Desempenho",
    optionsPanelPerformanceEvaluationDescription: "",
    myEquipBankOfHoursGeneralPageTitle: "Banco de horas geral",
    myEquipBankOfHoursGeneralCredit: "Crédito",
    myEquipBankOfHoursGeneralDebit: "Débito",
    myEquipBankOfHoursGeneralBalance: "Saldo",
    myEquipBankOfHoursPageTitle: "Banco de horas da equipe",
    myEquipBankOfHoursFinalBalance: "SALDO FINAL",
    myEquipPointPageTitle: "Ponto eletrônico da equipe",
    myEquipPointLabelPeriod: "Periodo do ponto",
    myEquipPointTotalResultPartial: "Resultados Parcial",
    myEquipQuestionTitle: "Questionário de competências comportamentais",
    myEquipQuestionView:
      "Clique sobre um colaborador para exibir a avaliação de desempenho.",
    myEquipQuestionErrorName: "* Necessário realizar o preenchimento",
    myEquipQuestionObsEvaluatorLabel: "Observação do Avaliador",
    myEquipQuestionObsEvaluatorPlaceholder:
      "Digite as observações do avaliador",
    myEquipQuestionObsEvaluatedLabel: "Observação do Avaliado",
    myEquipQuestionObsEvaluatedPlaceholder: "Digite as observações do avaliado",
    myEquipQuestionEvaluatorError: "* Digite detalhes sobre o avaliador",
    myEquipQuestionEvaluatedError: "* Digite detalhes sobre o avaliado",
    myEquipQuestionErrorUnknown: "ERRO DESCONHECIDO",
    myEquipQuestionTitleObsQuestion: "Observações sobre o questionário",
    myEquipQuestionEvaluationResponseTitle: "Avaliações respondidas",
    myEquipQuestionDownloadPdfTitle:
      "Clicar no botão para baixar a avaliação em PDF",
    myEquipQuestionDownloadPdfBtnLabel: "Baixar PDF",
    myEquipQuestionHeaderResponseName: "Nome",
    myEquipQuestionHeaderResponseMat: "Matricula",
    myEquipQuestionHeaderResponseFunction: "Função",
    myEquipQuestionHeaderResponseEvaluator: "Avaliador",
    myEquipQuestionHeaderResponseDateOfEvaluation: "Data da Avaliação",
    myEquipQuestionHeaderResponsePageTitle: "Avaliação de Desempenho",
    myEquipQuestionViewEvaluationResponsePageTitle: "Observações",
    myEquipQuestionEvaluationResponseAskBody: "Resposta",
    myEquipQuestionEvaluationResponseObsEvaluatorTitle: "Resposta do Avaliador",
    myEquipQuestionEvaluationResponseObsEvaluatedTitle: "Resposta do Avaliado",
  },
  cadastro_projetos: {
    titlePage: "Cadastro de Projetos",
    title: "Título",
    includeProject: "Incluir Projeto",
    titleAdvancedFilter: "Filtros avançados de busca",
    advancedFilter: "Filtro avançado",
    titleILeader: "Listar projetos que sou lider",
    ILeader: "Sou lider",
    titleIMember: "Listar projetos que sou membro",
    IMember: "Sou membro",
    titleMyDeport: "Listar projetos do meu departamento",
    myDeport: "Meu departamento",
    valueNotFound: "Não encontrado",
    labelSituation: "Situação",
    labelCreatorProject: "Criador Projeto",
    labelLeader: "Lider",
    labelCode: "Código",
    labelOpeningDate: "Data abertura",
    labelExpectedDate: "Data prevista",
    labelDriver: "Direcionador",
    labelDescription: "Descrição",
    labelExpansion: "Expansão",
    labelArea: "Área",
    labelOperation: "Operação",
    labelLoss: "Perda",
    labelLossType: "Tipo de perda",
    labelProjectType: "Tipo de projeto",
    labelPillar: "Pilar",
    labelIDMatrixDQA: "ID Matriz D / QA",
    labelSaving: "Saving",
    closeButton: "FECHAR",
    modelsTitle: "Modelos",
    membersTitle: "Membros",
    attachmentsTitle: "Anexos",
    attach: "Anexo",
    wait: "Aguarde ...",
    dateHistory: "Data do historico",
    mat: "Matricula",
    fromSituation: "Da situação",
    toSituation: "Para situação",
    observation: "Observação",
    titleJustify: "Justificativas definidas no projeto",
    subtitle: "Abaixo as justificativas que foram definidas no projeto",
    descriptionLabel: "Descrição",
    delayFactorLabel: "Fator de atraso",
    actionDescriptionLabel: "Descrição da ação",
    executedActionLabel: "Ação executada",
    newExpectedDateLabel: "Nova data prevista",
    justificationDateLabel: "Data da justificativa",
    plant: "Planta",
    leaderProject: "Lider Projeto",
    descriptionChecklist: "Descrição Checklist",
    matEvaluator: "Matricula Avaliador",
    situation: "Situação",
    descriptionProject: "Descrição Projeto",
    dateAvailable: "Data de avaliação",
    overvallGrade: "Nota Geral",
    responseChecklist: "Respostas do checklist",
    titleChecklist: "Titulo:",
    description: "Descrição:",
    response: "Resposta:",
    comment: "Comentário:",
    approved: "Aprovado",
    reproved: "Reprovado",
    pending: "Pendente",
    open: "Aberto",
    fi: "Validação FI",
    cd: "Validação CD",
    canceled: "Cancelado",
    justify: "Justificar",
    seeJustify: "Ver justificativa",
    seeChecklist: "Ver checklists",
    seeValuesCD: "Valores do CD",
    changeMembers: "Alterar membro(s)",
    returnCD: "Retornar Validação CD",
    closed: "Encerrado",
    clearFilter: "Limpar filtro",
    titleCard: "Clique para limpar filtro",
    days: "dia(s)",
    leader: "Lider",
    late: "Atrasado há",
    members: "Membros",
    code: "Código",
    area: "Área",
    openListProject: "Abertura",
    previst: "Previsto",
    newDatePrevist: "Nova Data Prevista",
    labelOpen: "Abertura",
    labelNewDatePrevist: "Nova Data Prevista",
    viewHist: "Ver histórico",
    btnOptions: "Opções",
    alterStatus: "Alteração de status",
    descriptionUpdStatus:
      "Descreva (opcional) porque você esta alterando para o status",
    observationPlaceholder: "Uma observação a ser fazer",
    attachFI: "Anexo FI (obrigatório)",
    attachCD: "Anexo CD",
    attachLabel: "Anexo",
    labelDateClosedLabel: "Data de Fechamento",
    placeholderDateClosed: "Defina data de fechamento",
    btnSend: "ENVIAR",
    errorAttachFI: "Para submeter a Validação FI torna-se obrigatório o anexo",
    errorDateClosed: "A nova data de fechamento precisa ser informada",
    errorNoLimit: "Sem limite",
    formDateSaving: "Formulário dados Saving",
    valBenefic: "Valor do beneficio",
    valCust: "Valor do custo",
    valBC: "Valor BC",
    valSaving: "Valor Saving",
    typeSaving: "Tipo de Saving",
    typeBC: "Tipo BC",
    fieldRequired: "Campo obrigatório",
    register: "REGISTRAR",
    chooseChecklist: "Escolha o checklist",
    chooseChecklistPlaceholder: "Escolha um checklist",
    responseChecklistInfo: "RESPONDER",
    commentPlaceholder: "Comente a sua resposta",
    optionError: "Escolha uma opção",
    commentError: "Não deve ficar em branco",
    titleFilterAdvanced: "FILTRO APLICADO",
    titleCancelMember: "Cancelar a inclusao do membro",
    titleRemoveMember: "Remover o membro do projeto",
    titleAlterMember: "Alteração de membros do projeto",
    projectMembers: "Membros do projeto",
    newMember: "Novo Membro",
    membersParticipants: "Membros participantes",
    save: "SALVAR",
    addMember: "ADICIONAR MEMBRO",
    collaborator: "Colaborador",
    cancel: "Cancelar a inclusão do membro",
    chooseCollaborator: "Favor escolher o colaborador",
    defineParticipation: "Falta definir {0} % de participação",
    removeMember: "Remover o membro do projeto",
    changeProjectMembers: "Alteração de membros do projeto",
    participantMembers: "Membros participantes",
    error100Percent:
      "100% de participação atingida. Impossível incluir membros",
    errorBefore100Percent1: "Falta definir ",
    errorBefore100Percent2: " % de participação",

    pilar: "Pilar",
    sector: "Setor",
    lateFilterAdvanced: "Atrasados",
    typeOfProject: "Tipo de projeto",
    applyFilter: "Aplicar Filtro",
    errorProjectCode: "Necessário escolher ao menos 1 código de projeto",
    errorPlant: "Necessário escolher ao menos 1 planta",
    errorPilar: "Necessário escolher ao menos 1 pilar",
    errorSector: "Necessário escolher ao menos 1 setor",
    errorLate: "Necessário escolher ao menos 1 setor",
    errorTypeOfProject: "Necessário escolher ao menos 1 tipo de projeto",
    descricao: "Descrição",
    expansao: "É um projeto de expansão ?",
    placeholderDescription: "Digite uma descrição",
    errorDateOpen: "Formato DD/MM/AAAA",
    errorDirection: "Escolha um direcionador",
    errorDescription1: "Não deve ficar em branco (maximo ",
    errorDescription2: " caracteres)",
    errorExpansion: "Escolha uma opção",
    errorArea: "Escolha uma área",
    errorModel: "Escolha ao menos um modelo",
    errorOperation: "Escolha uma operação",
    errorLose: "Escolha uma perda",
    errorTypeLose: "Escolha uma opção",
    errorTypeProject: "Escolha uma opção",

    errorMatriz: "Não deve ficar em branco",
    errorSaving: "Digite no formato 0,00",
    errorLider: "Escolha uma opção",
    errorMemberNull: "🤔 Preencha o membro",
    errorPercentNot100: "O total de percentuais é inferior á 100%",
    errorPercentMore100: "O total de percentuais é maior que 100%",
    errorNotLeader: "🤔 Preencha o lider do projeto.",
    errorMemberLast: "🤔 Preencha o ultimo membro incluso.",
    errorTotal100: "100% de participação atingida",
    btnClose: "FECHAR",
    btnSave: "Salvar",
    titleLaunchProject: "Lançamento de projeto",

    labelDateOpen: "Data de abertura",
    labelDirection: "Direcionador",

    labelExpansionProject: "É um projeto de expansão ?",

    labelModel: "Modelos",

    labelLose: "Perda",
    labelTypeLose: "Tipo de perda",
    labelTypeOfProject: "Tipo de projeto",
    labelPilar: "Pilar",
    labelMatriz: "ID Matriz D / QA",
    labelSavingAdd: "Saving previsto",
    labelLeaderAdd: "Líder do projeto",
    labelMember: "Membro",
    labelPercent: "Participação do membro",
    labelAnexo: "Anexo(s)",
    labelMemberNumber: "Membro",
    labelPercentMember: "Participação do membro",
    labelSavingInfo:
      "Atenção insira um valor de saving previsto, se você não sabe digite o número 0,00",
    btnNewMember: "Novo Membro",
    optYes: "Sim",
    optNo: "Não",
    optCronic: "Crônico",
    optExpordic: "Esporádico",
    titleRemoveMemberAdd: "Remover membro",
    titleFormAdd: "Formulário de justificativa",
    descriptionAddJustify: "Descreva os detalhes do atraso do projeto",

    descricao_da_acao: "Descrição da solução",
    fator_atraso: "Fator do atraso",
    acao: "Ação",
    registrar: "Registrar",
    placeholderDescriptionAddJustify: "Descreva o motivo do atraso aqui",
    placeholderDescriptionAction:
      "Descreva a ação para não recorrência deste fator de atraso",
    errorDescription: "Minimo de 3 caracteres",
    errorDescriptionAction: "Minimo de 3 caracteres",
    errorFactor: "Escolha ao menos um fator",
    errorAction: "Escolha uma acao",
    pointTitle: "Pontuação atingida",
    considerAcceptCd:
      "Considera aceitavel para avançar o projeto a VALIDAÇÃO CD ?",
    btnResponseAfter: "RESPONDER DEPOIS",
    btnReproved: "REPROVADO",
    btnApproved: "APROVADO",
  },
  cartao_seguranca: {
    sector: "Setor",
    errorSector: "* Precisa ser preenchida",
    labelSector: "Setor",
    errorOpenDate: "* Precisa ser preenchida",
    errorTypeCard: "* Deve ser escolhido um",
    errorCategory: "* Informe a categoria",
    errorDescriptionCard:
      "* Não deve ficar em branco e não deve ultrapassar 230 caracteres",
    errorMat: "* Escolha uma matricula",
    errorOperation: "* Informe a operação ou N/A",
    errorTurn: "* Informe o turno",
    errorArea: "* Informe uma área",
    errorModel: "* Infome o modelo do cartão",
    labelTypeCard: "Escolha o tipo de cartão",
    nameLeader: "NOME LÍDER",
    mat: "MATRICULA",
    labelArea: "ÁREA",
    labelModel: "MODELO",
    labelCategory: "CATEGORIA",
    labelOperation: "OPERAÇÃO",
    labelTurn: "TURNO",
    labelDateOpen: "DATA DE ABERTURA",
    labelDescription: "DESCRIÇÃO",
    placeholderDescription: "Descreva o problema...",
    labelAttachment: "ANEXO(S)",
    textBtnSave: "SALVAR",
    titleFormJustify: "Justificativa / Observação",
    labelJustify: "* Justificativa",
    placeholderJustify: "Digite a sua justificativa...",
    errorMinChar: "Mínimo de 3 caracteres",
    textBtnReprove: "REPROVAR",
    textBtnApprove: "APROVAR",
    titleMoveToAnalitycs: "Movimentar para analise",
    subtitleMoveToAnalitycs:
      "Preencha os campos do formulario para determinar a causa raiz e a ação prevista.",
    labelRootCause: "Causa Raiz",
    placeholderRootCause: "Digite a causa raiz do problema...",
    labelAction: "Ação prevista",
    placeholderAction: "Digite a ação prevista para resolução do problema",
    labelDatePrev: "Data prevista",
    labelResponsible: "Responsável",
    titleJustifyAndObservation: "Justificativa / Observação",
    subtitleJustifyAndObservation:
      "Decida se o cartão deve seguir para o status em Aberto ou ser Cancelado.",
    textBtnCancel: "CANCELAR",
    textBtnValidate: "VALIDAR",
    attachment: "Anexo",
    titleCompleteEnvironment: "VALIDAÇÃO DO MEIO AMBIENTE",
    titleCompleteHealth: "VALIDAÇÃO DA ÁREA DE SAÚDE",
    titleCompleteSafety: "VALIDAÇÃO DA SEGURANÇA",
    titleCompleteValidation: "VALIDAÇÃO DA(O)",
    card: "CARTÃO",
    date: "DATA",
    identify: "IDENTIFICAÇÃO",
    name: "NOME",
    leaderName: "NOME LÍDER",
    areaAndTurn: "ÁREA/TURNO",
    descriptionProblem: "DESCRIÇÃO PROBLEMA",
    local: "LOCAL",
    model: "MODELO",
    activity: "ATIVIDADE",
    operation: "OPERAÇÃO",
    description: "DESCRIÇÃO",
    justifyAndObservation: "JUSTIFICATIVA / OBSERVAÇÃO",
    planType: "PLANO DE AÇÃO (SOLUÇÃO)",
    rootCause: "CAUSA RAIZ",
    actions: "AÇÕES PREVISTAS",
    dateInclude: "DATA INCLUSÃO",
    datePreview: "DATA PREVISTA",
    analyticsAndEfficiency: "ANÁLISE DA EFICÁCIA",
    statusInclude: "Incluso (A validar)",
    statusCancel: "Cancelado",
    statusOpen: "Aberto",
    statusAnalisys: "Em analise",
    statusClosed: "Encerrado",
    statusOpenTitle: "Cartão com status em aberto",
    statusIncludeTitle: "Cartão com status incluso á validar",
    statusCancelTitle: "Cartão com status cancelado",
    statusAnalisysTitle: "Cartão com status em analise",
    statusClosedTitle: "Cartão com status encerrado",
    searchTitleLeader: "Busca por lider",
    searchTitleCode: "Busca por código",
    searchTitleTurn: "Busca por turno",
    searchTitleArea: "Busca por área",
    titlePage: "Cartão Segurança",
    textCardTypeSecurity: "Segurança",
    textCardTypeHealth: "Saúde",
    textCardTypeEnvironmental: "Ambiental",
    titleFabAdd: "Adicionar novo cartão",
    titleButtonCleanFilter: "Limpar filtro de status aplicado",
    textButtonCleanFilter: "Limpar filtro",
    textOptionViewCard: "Ver cartão",
    textOptionValidateReject: "Validar / Rejeitar",
    textOptionSendAnalysis: "Enviar para análise",
    textOptionJustify: "Justificar",
    textOptions: "Opções",
    textType: "Tipo",
    textLeader: "Líder",
    textDescription: "Descrição",
    textOpenDate: "Data abertura",
    textFabAdd: "ADICIONAR",
    view: "Ver",
    front: "Frente",
    back: "Verso",
  },
  helpdesk: {
    titleMoreOptions: "Clique para ver as demais opções.",
    titleInfoRequester: "Todos os tickets abertos por este solicitante",
    placeholderGetRequestTicket: "Digite o nome",
    labelFilterAgent: "Sou Agente",
    titleFilterAgent: "Exibe somente tickets onde eu sou o agente",
    labelFilterRequester: "Solicitantes",
    titleFilterRequester: "Determina de qual solicitante deseja ver os tickets",
    labelMaintenanceSubjectSuperAgent: "MANUTENÇÃO ASSUNTO",
    labelMaintenanceStatusSuperAgent: "MANUTENÇÃO STATUS",
    labelMaintenanceCriticalitySuperAgent: "MANUTENÇÃO CRITICIDADE",
    labelBtnNewTicket: "NOVO TICKET",
    titleSubnects: "Assuntos",
    titleStatus: "Status",
    titleAnimationNoData: "Não há tickets no filtro informado",
    titleComponentPagination: "Clique para alternar entre as paginas",
    titleFieldId: "ID",
    titleFieldPlant: "Planta",
    titleFieldRequester: "Solicitante",
    titleFieldCriticality: "Criticidade",
    titleFieldAgent: "Agente",
    titleFieldStatus: "Status",
    titleFieldTitle: "Titulo",
    titleFieldLastInteraction: "Interação / Prazo",
    titleBtnOrderTicket: "Clique para ordenar.",
    titleTotalTickets: "Total de tickets criados",
    titleBtnNewTicket: "Clique para abrir um novo ticket",
    titleTicket: "Clique para ver detalhes do ticket",
    titleTicketNumber: "Ticket N°",
    titleTicketNotAssigned: "Não atribuido",
    titleSubject: "Assunto",
    titleInteraction: "Interação",
    titlePlant: "Planta",
    titleAgent: "Agente",
    titleIndicatorLate: "Este ticket encontra-se atrasado (o prazo se expirou)",
    labelIndicatorLate: "Atrasado",
    titleBtnDelete: "Clique para excluir o status",
    titleBtnMove: "Clique para definir para qual status este poderá levar",
    title: "Manutenção de status",
    subtitle:
      "Criar, alterar, excluir e ativar/desativar status usados no helpdesk.",
    headerName: "Status",
    headerAuthorized: "Autorizado Interagir",
    headerColor: "Cor",
    headerActive: "Situação",
    headerDelete: "Excluir",
    headerMove: "De / Para",
    labelActive: "Ativo",
    labelInactive: "Inativo",
    labelAgent: "Agente",
    labelRequester: "Solicitante",
    titleBtnAdd: "Incluir",
    titleFromTo: "Definição de destino Status",
    subtitleFromTo:
      "Escolha os destinos em que o status que esta do lado esquerdo pode seguir quando tiver que ser movimentado.",
    canInteract: " pode interagir neste status.",
    labelBtn: "SALVAR",
    fromStatus: "HELPDESK NESTE STATUS",
    toStatus: "PODE IR PARA ESTES STATUS",
    titleDel: "Exclusão de status",
    subtitleDel: "Deseja realmente excluir o status ?",
    titleAddStatus: "Adicionar Status",
    labelName: "Nome do status",
    labelAuthorized: "Autorizado Interagir",
    labelSituation: "Situação",
    labelColor: "Cor",
    errorName: "* Nome deve ter ao menos 3 caracteres",
    errorAuthorized: "* Defina quem poderá interagir neste status",
    erroSituation: "* Escolha entre ativar ou desativar o assunto",
    errorColor: "* Escolha uma cor para o status",
    placeholderName: "Nome do status",
    placeholderAuthorized: "Autorizado Interagir",
    placeholderSituation: "Situação",
    placeholderColor: "Cor",
    labelActivated: "Ativado",
    labelDeactivated: "Desativado",
    titleBtnGoBack: "Retornar a tela de chamados",
    labelBtnGoBack: "RETORNAR",

    labelFilterAdvancedTicket: "Ticket",
    labelFilterAdvancedSubject: "Assunto",
    labelFilterAdvancedAgent: "Agente",
    labelFilterAdvancedStatus: "Status",
    labelFilterAdvancedDelayed: "Atrasado",
    labelFilterAdvancedApplicant: "Solicitante",
    labelFilterAdvancedSeverity: "Criticidade",
    labelFilterAdvancedExclude: "Deseja realmente excluir o filtro ?",
    titleFilterAdvancedMyFilters: "Meus filtros salvos",
    labelNoAccessToLocalStorage: "Sem acesso ao recurso de gravação local",
    titleFilterAdvancedChooseFilter: "Escolha o filtro a ser aplicado",
    titleFilterAdvancedNoFilterSelected: "Nenhum filtro selecionado",
    labelFilterAdvancedSaveFilter: "Salvar Filtro",
    labelFilterAdvancedQuestionSaveFilter: "Defina um nome para o filtro",
    errorFilterAdvancedTicket: "* Favor escolher ao menos 1",
    errorFilterAdvancedSubject: "* Favor escolher ao menos 1",
    errorFilterAdvancedAgent: "* Favor escolher ao menos 1 agente",
    errorFilterAdvancedStatus: "* Favor escolher ao menos 1 status",
    errorFilterAdvancedApplicant: "* Favor escolher ao menos 1 solicitante",
    errorFilterAdvancedSeverity: "* Favor escolher ao menos 1 criticidade",
    titleFilterAdvancedDeleteFilter: "Deseja realmente excluir o filtro ?",

    titleDetails: "Detalhes",
    titleInteractions: "Interações",
    labelStatus: "Status",
    labelTicketNumber: "Ticket Número",
    labelCreated: "Criado em",
    labelLastInteraction: "Ultima interação",
    labelDeadline: "Prazo final",
    labelSubject: "Assunto",
    titleCriticality: "Nível de criticidade",
    titleAlterCriticality: "Alterar criticidade",
    labelNoAgent: "Não atribuído",
    titleRequestersInCopy: "Colaboradores em cópia",
    labelBtnEdit: "Editar",
    labelAgentResponsible: "Agente Responsável",
    titleClickHereToStartAnInteraction:
      "Clique aqui para iniciar uma interação",
    titleClickToIncrement:
      "Clique para incrementar mais detalhes sem atualizar o status",
    labelClose: "FECHAR",
    labelIncrement: "INCREMENTAR",
    labelBtnInteraction: "INTERAGIR",
    labelAttachment: "ANEXO",
    placeholderDescription: "Digite a descrição",
    errorDescription: "Necessário preencher a descrição do ocorrido",
    placeholderStatusTo: "Mudar o status do ticket...",
    errorStatusTo: "Escolha o status que irá receber a solicitação",
    labelSendEmail: "Enviar e-mail de notificação",
    titleAlterAgent: "Alterar agente responsável",
    titleMainCritical: "Manutenção de criticidade",
    subtitleMainCritical:
      "Criar, alterar, excluir e ativar/desativar criticidades usadas no helpdesk.",
    headerNameCritical: "Criticidade",
    headerIcon: "Ícone",
    labelDeactive: "Inativo",
    titleBtnAddActive: "Ativo",
    titleAddCritical: "Adicionar Criticidade",
    titleAddCriticalUpd: "Atualizar criticidade",
    labelNameAddCritical: "Nome da criticidade",
    labelIcon: "Icone",
    errorNameAddCritical: "* Nome deve ter ao menos 3 caracteres",
    errorIcon: "* O icone deve ter ao menos 1 caractere",
    errorSituationAddCritical:
      "* Escolha entre ativar ou desativar a criticidade",
    placeholderNameAddCritical: "Nome da criticidade",
    placeholderIcon: "Icone",
    placeholderSituationAddCritical: "Situação",
    labelDeactiveAddCritical: "Desativado",
    titleSubjectMain: "Manutenção de assuntos",
    subtitleSubject:
      "Criar, alterar, excluir e ativar/desativar assuntos usados no helpdesk.",
    headerNameSubject: "Assunto",
    headerPraz: "Prazo (dias)",
    titleSubjectBtnAdd: "Adicionar",
    titleSubjectDel: "Exclusão de assunto",
    subtitleSubjectDel: "Deseja realmente excluir o assunto ?",

    titleSubjectAdd: "Adicionar Assunto",
    titleSubjectAddUpd: "Atualizar assunto",
    labelNameSubjectAdd: "Nome do assunto",
    labelPraz: "Prazo (em dias)",
    errorNameSubjectAdd: "* Nome deve ter ao menos 3 caracteres",
    errorPraz: "* Prazo deve ter ao menos 1 dia",
    errorSituationSubjectAdd: "* Escolha entre ativar ou desativar o assunto",
    placeholderNameSubjectAdd: "Nome do assunto",
    placeholderPraz: "Prazo (em dias)",
    placeholderSituationSubjectAdd: "Situação",
    titleAlterEnvolved: "Alterar lista de colaboradores em cópia",
    labelInvolved: "Colaboradores em Cópia",
    errorInvolved: "* Escolha ao menos 1 colaborador",
    titleAlterCritical: "Alterar Criticidade do Chamado",
    labelCriticality: "Criticiade do Chamado",
    errorCriticality: "* Escolha uma criticidade válida",
    labelTitleAdd: "Título",
    labelFiles: "Arquivos",
    labelColabCopy: "Colaboradores em Cópia",
    placeholderTitle: "Descreva um titulo ao problema",
    errorTitle: "* Mínimo de 3 caracteres",
    errorSubject: "* Escolha um assunto",
    errorMat: "* Escolher a matricula do solicitante",
    titleNewTicket: "Abertura de Ticket",
    advancedFilter: "Filtro avançado",
  },
  rnci_ncp: {
    titleCancelStatus: "Deseja realmente cancelar este status ?",
    subtitleCancelStatus:
      "O RNCI será excluído e não poderá mais ser recuperado",
    subtitleCancelStatus2: "O RNCI retornará para o status ABERTO",
    subtitleCancelStatus3: "O RNCI retornará para o status SUBIDA DE LINHA",
    tituloBuscaAvancada: "Busca avançada",
    descricaoBuscaAvancada:
      "Escolha entre as opções e monte uma busca avançada.",
    wait_generate_pdf: "Aguarde, gerando PDF...",
    rotuloBtnFiltro: "BUSCA AVANÇADA",
    tituloSemDados: "Nenhum dado foi encontrado",
    tituloSemDadosRetorno: "Tentando carregar dados necessários...",
    titulo: "RCNI",
    tituloPaginacao: "Clique para alternar entre as paginas",
    codigo: "Código",
    anomalia: "Anomalia: ",
    data: "Data Criação: ",
    area: "Área: ",
    rotuloBotaoAdd: "Novo RNCI",
    tituloBotaoAdd: "Criar novo RNCI",
    tituloForm: "ALERTA DE QUALIDADE INTERNO",
    formAuditor: "AUDITOR",
    formConjunto: "CONJUNTO",
    formModelo: "MODELO",
    formTurno: "TURNO",
    formQtdKO: "QUANTIDADE KO",
    formQtdSuspeita: "QUANTIDADE SUSPEITA",
    formOperacao: "OPERAÇÃO",
    formRNCI: "RNCI N°",
    formPN: "PN",
    formRastreabilidade: "RASTREABILIDADE",
    formSubidaDeLinha: "SUBIDA DE LINHA",
    formFalhaForaControle: "FALHA FORA DE CONTROLE",
    formTwttp: "TWTTP",
    formData: "DATA",
    formHora: "HORA",
    formErrorAuditor: "Informe ao menos um colaborador",
    formErrorConjunto: "Escolha ao menos uma área",
    formErrorModelo: "Escolha ao menos um modelo",
    formErrorTurno: "Escolha ao menos um turno",
    formErrorQtdKO: "Mínimo 0",
    formErrorQtdSuspeita: "Mínimo 0",
    formErrorOperacao: "Operação",
    formErrorRNCI: "RNCI N°",
    formErrorPN: "Informe o PN",
    formErrorRastreabilidade: "Informe a rastreabilidade",
    formErrorSubidaDeLinha: "Subiu de liha ?",
    formErrorFalhaForaControle: "Foi falha fora de controle ?",
    formErrorTwttp: "Obrigatório",
    formErrorData: "Informe da data",
    formErrorHora: "Informe a hora",
    formErrorAnomalia: "Informe a anomalia",
    formAuditorPlaceholder: "Informe o auditor",
    formConjuntoPlaceholder: "Área",
    formModeloPlaceholder: "Modelo",
    formTurnoPlaceholder: "Turno",
    formQtdKOPlaceholder: "EX: 1",
    formQtdSuspeitaPlaceholder: "EX: 1",
    formOperacaoPlaceholder: "Operacação",
    formRNCIPlaceholder: "RNCI N°",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Rastreabilidade",
    formSubidaDeLinhaPlaceholder: "Subida de linha",
    formFalhaForaControlePlaceholder: "Falha fora de controle",
    formTwttpPlaceholder: "Twttp",
    formDataPlaceholder: "Data",
    formHoraPlaceholder: "Hora",
    formAnomaliaPlaceholder: "Descreva a anomalia...",
    formAnomalia: "ANOMALIA",
    formSim: "SIM",
    formNao: "NÃO",
    twttpGerou: "Gerou o problema",
    twttpDeixou: "Deixou passar",
    twttpNaoAplicavel: "Não aplicável",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "IDENTIFICAÇÃO DE RACK OU CAIXA",
    formOque: "O QUÊ ?",
    formQuando: "QUANDO ?",
    formOnde: "ONDE ?",
    formQuem: "QUEM ?",
    formQual: "QUAL ?",
    formComo: "COMO ?",
    formErrorOque: "Obrigatório",
    formErrorQuando: "Obrigatório",
    formErrorOnde: "Obrigatório",
    formErrorQuem: "Obrigatório",
    formErrorQual: "Obrigatório",
    formErrorComo: "Obrigatório",
    formOquePlaceholder: "O que houve ?",
    formQuandoPlaceholder: "Quando aconteceu ?",
    formOndePlaceholder: "Onde aconteceu ?",
    formQuemPlaceholder: "Quem identificou ?",
    formQualPlaceholder: "Qual é o nível de problema ?",
    formComoPlaceholder: "Como que ocorreu ?",
    formFenomenoRevisado: "FENOMENO REVISADO",
    formErrorFenomenoRevisado: "Obrigatório",
    formFenomenoRevisadoPlaceholder: "Descreva o fenomeno.",
    formCausaRaiz: "POSSÍVEL CAUSA RAIZ",
    formCausaRaizPlaceholder: "Descreva aqui...",
    formErrorCausaRaiz: "Obrigatório",
    tituloBotaoVoltar: "Clique para retornar",
    rotuloBotaoFormSalvar: "SALVAR",
    tituloBotaoRemoverImagem: "Clique para remover Imagem",
    formRotuloImagem: "Insira aqui a imagem",
    formErrorImagemOK: "Imagem obrigatória",
    formErrorImagemKO: "Imagem obrigatória",
    formErrorImagemIdent: "Erro envio da imagem",
    situacaoQualidade: "QUALIDADE",
    situacaoSubida: "SUBIDA DE LINHA",
    situacaoAberto: "ABERTO",
    opcaoDetalhes: "Detalhes",
    opcaoSubida: "Subida de Linha",
    opcaoDownloadPdf: "Baixar em PDF",
    opcaoQualidade: "Mover Qualidade",
    rotuloBotaoVerOpcoes: "OPÇÕES",
    tituloBotaoVerOpcoes: "Exibe as opções de manipulação do RNCI",
    tituloFormSubida: "Formulário de subida de linha",
    formPlaceholderTempoGasto: "Informe o tempo gasto",
    formTempoGasto: "Tempo gasto",
    formPlaceholderMod: "Informe a mão de obra direta",
    formMod: "MOD",
    formPlaceholderMoi: "Informe a mão de obra indireta",
    formMoi: "MOI",
    formPlaceholderOK: "Informe a quantidade OK",
    formOK: "Quantidade OK",
    formPlaceholderKO: "Informe a quantidade KO",
    formKO: "Quantidade KO",
    formPlaceholderObservacao: "Descreva uma observação",
    formObservacao: "Observação",
    formErrorTempoGasto: "* Favor informar o tempo gasto HH:MM",
    formErrorMod: "* Valor mínimo 0",
    formErrorMoi: "* Valor mínimo 0",
    formErrorOK: "* Valor mínimo 0",
    formErrorKO: "* Valor mínimo 0",
    formErrorObservacao: "* Descreva uma observação minimo 3 caracteres",
    tituloFormQualidade: "Formulário de Qualidade",
    formPlaceholderCodigoProjeto: "Informe o codigo do projeto",
    formCodigoProjeto: "Código do Projeto",
    formPlaceholderProjeto: "Este RNCI tem vinculo algum projeto ?",
    formProjeto: "Este RNCI tem vinculo algum projeto ?",
    formPlaceholderAcoes: "Descreva as ações",
    formAcoes: "Ação",
    formErrorProjeto: "* Escolha Sim ou não",
    formErrorCodigoProjeto: "* Informe o código do projeto",
    formErrorAcoes: "* Descreva a ação minimo 3 caracteres",
    abaForm1: "RNCI",
    abaSubida: "SUBIDA LINHA",
    abaQualidade: "QUALIDADE",
    rotuloBotaoVoltar: "VOLTAR",
    subidaLinhaMod: "Mão de obra Direta",
    subidaLinhaMoi: "Mão de obra Indireta",
    subidaLinhaTempo: "Tempo de trabalho",
    subidaLinhaOK: "Quantidade peças OK",
    subidaLinhaKO: "Quantidade peças KO",
    subidaLinhaObs: "Observação da mão de obra",
    qualidadeCodProjeto: "Número do Projeto",
    qualidadeObs: "Ações",
    filtroNomeStatus: "STATUS",
    filtroNomeStatusErro: "* Escolha ao menos um",
    filtroNomeMatricula: "MATRICULA",
    filtroNomeMatriculaErro: "* Escolha ao menos um",
    filtroNomeArea: "AREA",
    filtroNomeAreaErro: "* Escolha ao menos um",
    filtroNomeTurno: "TURNO",
    filtroNomeTurnoErro: "* Escolha ao menos um",
    filtroNomeRnci: "CODIGO RNCI",
    filtroNomeRnciErro: "* Escolha ao menos um",
    filtroNomeOperacao: "OPERAÇÃO",
    filtroNomeOperacaoErro: "* Escolha ao menos um",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Escolha ao menos um",
    opcaoExcluirRnci: "Excluir RNCI",
    opcaoRetornarAbertoRnci: "Voltar para aberto",
    opcaoRetornarSubidaDeLinhaRnci: "Voltar para subida de linha",
  },
  manutencao_variaveis: {
    titlePage: "Manutenção de variáveis",
    tabOne: "Colaboradores",
    tabTwo: "Variáveis",
    tabThree: "Variáveis Globais",
    labelBtnAddVar: "Adicionar",
    labelBtnEditVar: "Editar",
    labelBtnDelVar: "Excluir",
    labelBtnAddVarGlobal: "Adicionar",
    titleEditVarGlobal:
      "Edite a variavel tanto em sua descrição quanto em seu valor",
    labelBtnEditVarGlobal: "Editar",
    titleDelVarGlobal: "Exclua esta variavel global (Somente autorizado)",
    labelBtnDelVarGlobal: "Excluir",
    labelValueActualy: "Valor atual:",
    labelVarName: "Nome da Variável",
    placeholderVarName: "Digite o nome da variável",
    labelVarDescription: "Descrição",
    placeholderVarDescription: "Digite a descrição da variavel",
    labelVarValue: "Valor",
    placeholderVarValue: "Digite o valor inicial da variável",
    errorVarDescription: "* Mínimo de 3 caracteres",
    errorVarName: "* Mínimo de 3 caracteres",
    errorVarValue: "* Campo Obrigatório",
    titleNewVar: "Cadastro de nova variável global",
    descriptionNewVar:
      "Preencha os valores abaixo para registrar uma nova variável global no sistema.",
    titleFormAddVarUser: "MANUTENÇÃO DE VARIÁVEIS DE USUÁRIOS",
    labelVarUserName: "Variável",
    placeholderVarUserName: "Digite o nome da variável",
    errorVarUserName: "* Mínimo de 3 caracteres",
    labelVarUserDescription: "Descrição",
    placeholderVarUserDescription: "Uma descrição do que ela pretende atender",
    errorVarUserDescription: "* Mínimo 3 caracteres",
    labelBtnVarUserSend: "ENVIAR",
    titleFormDelVarGlobal: "Você realmente deseja excluir a variável ?",
    descriptionFormDelVarGlobal1: "Se realmente deseja excluir a variavel ",
    descriptionFormDelVarGlobal2: " clique em SIM.",
    tabVarGlobal1: "Editar valor",
    tabVarGlobal2: "Editar descrição",
    labelEditVarGlobalValue: "Valor",
    placeholderEditVarGlobalValue: "Digite o novo valor da variavel",
    errorEditVarGlobalValue: "* Campo obrigatório",
    descriptionEditVarGlobal:
      "Digite abaixo o valor que deve ser atribuído a variável",
    labelEditVarGlobalDescription: "Descrição",
    placeholderEditVarGlobalDescription: "Digite a descrição da variavel",
    errorEditVarGlobalDescription: "* Mínimo de 3 caracteres",
    descriptionEditVarGlobalDesc:
      "Digite abaixo a descrição a ser atribuída a variável",
    titleListVarColab: "Lista de variaveis do usuário",
  },
  absenteismo: {
    title: "Absenteísmo",
    labelDateFrom: "Data de",
    labelDateTo: "Data até",
    errorDateFormat: "Formato inválido",
    labelBtnSearch: "Pesquisar",
    labelViewAll: "Ver todos",
  },
  twttp: {
    labelSituationViewDetails: "Situação",
    labelSectorViewDetails: "Setor",
    labelInterviewedViewDetails: "Entrevistado",
    labelInterviewerViewDetails: "Entrevistador",
    labelInterviewDateViewDetails: "Data da entrevista",
    labelPrevDateViewDetails: "Data prevista",
    labelActionDateViewDetails: "Prazo para ação",
    labelTypeViewDetails: "Tipo de TWTTP",
    labelProblemViewDetails: "Problema",
    labelProcessViewDetails: "Processo / Máquina",
    labelModelViewDetails: "Modelo(s)",
    labelErrorFeatureViewDetails: "Característica do erro",
    labelErrorDetailViewDetails: "Detalhe sobre o erro",
    labelProblemOriginViewDetails: "Origem do problema",
    labelFactorViewDetails: "Fator",
    labelShiftViewDetails: "Turno",
    labelRejectionJustifyViewDetails: "Justificativa de reprovação",
    labelTimeViewDetails: "Time necessário para análise",
    labelAttachmentViewDetails: "Anexo",
    labelAttachmentValidationViewDetails: "Anexo(s) Validação",
    labelCodeFilterAdvanced: "Código",
    labelTypeFilterAdvanced: "Tipo",
    labelOriginFilterAdvanced: "Origem",
    labelInterviewerFilterAdvanced: "Entrevistador",
    labelInterviewedFilterAdvanced: "Entrevistado",
    labelSectorFilterAdvanced: "Setor",
    labelAreaFilterAdvanced: "Área",
    labelLateFilterAdvanced: "Atrasados",
    labelColabInterviewToInterview: "Colaborador(a) Entrevistado(a)",
    labelInterviewerToInterview: "Entrevistador(a)",
    errorValueRequiredToInterview: "* Valor obrigatório",
    labelJustifyToInterview: "Nota Explicativa",
    placeholderJustifyToInterview: "Escreva uma nota explicativa...",
    errorJustifyRequiredToInterview: "* Mínimo de 3 caracteres",
    labelActionToInterview: "Ação a ser tomada",
    descriptionColabToInterview: "Colaborador(a) a ser entrevistado(a)",
    descriptionToInterview: "Entrevisador(a)",
    titleTwttpResponseQuestion: "TWTTP ID -",
    titleAvatarToInterview: "Entrevistado",
    titleAvatarToInterviewer: "Entrevistador (avaliador)",
    titleTwttpResponseDateOpen: "Data da entrevista",
    labelTwttpResponseQuestion: "Pergunta",
    labelTwttpResponse: "Resposta",
    labelTwttpResponseNote: "Nota explicativa",
    labelJustifyFormMove: "* Justificativa",
    placeholderJustifyFOrmMove: "Digite uma justificativa...",
    errorJustifyRequiredFormMove: "* Mínimo 3 caracteres",
    titleReprovedFormMove: "Reprovar",
    subtitleReprovedFormMove:
      "Informe o motivo pelo qual deseja reprovar este TWTTP.",
    titleConfirmFormMove1: "Voc^e deseja mesmo atualizar para",
    subtitleConfirmFormMove:
      "Esta alteração não poderá ser desfeita. Caso confirme o status do twttp será alterado para",
    errorAttachmentRequiredFormMoveValidation: "Obrigatório anexo",
    titleFormMoveValidation: "Mover TWTTP para Validação",
    subtitleFormMoveValidation:
      "Para que este TWTTP possa ser movimentado para validação deve-se incluir um anexo no campo abaixo",
    errorUnknown: "Erro desconhecido",
    labelOpenDateFormAdd: "Data de abertura",
    labelTwttpTypeFormAdd: "Tipo de TWTTP",
    labelProblemFormAdd: "Problema",
    labelProcessMachineFormAdd: "Processo / Máquina",
    labelModelsFormAdd: "Modelos",
    labelSectorInterviewedFormAdd: "Setor a ser entrevistado",
    labelErrorFeatureFormAdd: "Característica do erro",
    labelErrorDetailFormAdd: "Descreva no detalhe o erro",
    labelProblemOriginFormAdd: "Origem do problema",
    labelFactorFormAdd: "Fator",
    labelShiftFormAdd: "Turno",
    labelAnalysisTeamFormAdd: "Time necessário para análise",
    labelAttachmentFormAdd: "Anexo",
    titleTwttpFormAdd: "TWTTP",
    placeholderDetailErrorFormAdd: "Descreva o detalhe do erro",
    errorAttachmentRequiredFormAdd: "Obrigatório anexo",
    errorOpenDateRequiredFormAdd: "Favor informar a data",
    errorTwttpTypeRequiredFormAdd: "Favor informar o tipo de TWTTP",
    errorProblemRequiredFormAdd: "Favor informar o problema",
    errorAreaRequiredFormAdd: "Favor informar a area",
    errorModelRequiredFormAdd: "Ao menos um modelo precisa ser selecionado",
    errorSectorRequiredFormAdd: "Favor informar o setor",
    errorDetailErrorRequiredFormAdd: "Favor informar o detalhe do erro",
    errorProblemOriginRequiredFormAdd: "Favor informar a origem do problema",
    errorErrorFeatureRequiredFormAdd: "Favor informar a característica",
    errorAnalysisTeamRequiredFormAdd: "Favor informar o(s) times para análise",
    errorFactorRequiredFormAdd: "Preencher o fator",
    errorShiftRequiredFormAdd: "Preencher o turno",
    titleTwttpConfirm: "Deseja realmente cancelar este TWTTP ?",
    subtitleTwttpConfirm:
      "Uma vez feita esta alteração não será mais possível cancelar este procedimento",
    labelWaitToPdf: "Aguarde, gerando PDF...",
    errorInternal: "ERRO INTERNO DO SERVIDOR",
    titleTwttp: "TWTTP - HERCA",
    labelCleanFilter: "Limpar Filtro",
    titleNoData: "Sem Dados para exibir",
    labelToInterview: "Entrevistar",
    labelFinishInterview: "Finalizar entrevista",
    labelToValidation: "Enviar para validação",
    labelValidation: "Aprovar",
    labelMoveToExecution: "Mover para execução",
    labelCancel: "Cancelar",
    labelCode: "Código",
    labelSector: "Setor",
    labelArea: "Área",
    labelProblem: "Problema",
    labelOptions: "Opções",
    titleOpenDateTwttp: "Data de abertura do Twttp",
    titleOpenDate: "Data de abertura",
    titlePrazActionTwttp: "Prazo para ação do Twttp",
    titlePrazAction: "Prazo para ação",
    labelFilterAdvanced: "Filtro Avançado",
    titleAddNewTwttp: "Adicionar novo Twttp",
    labelBtnAddTwttp: "ADICIONAR",
    twttpStatusA: "Aberto",
    twttpStatusV: "Validação",
    twttpStatusP: "Em Entrevista",
    twttpStatusS: "Em Execução",
    twttpStatusE: "Encerrado",
    twttpStatusVR: "Ver Respostas",
    twttpStatusC: "Cancelado",
    twttpStatusBPDF: "Baixar TWTTP PDF",
    twttpStatusR: "Reprovado",
    labelViewAnswers: "Ver Respostas",
    labelReprove: "Reprovar",
    twttpStatusY: "V. Produção",
    labelToValidationHigher: "Enviar para validação produção",
    titleFormMoveValidationProd:
      "Enviando o TWTTP do status Validação Produção para Validação",
    subtitleFormMoveValidationProd:
      "Se você tem certeza de que deseja mover o TWTTP para validação clique em SIM",
  },
  registro_emocional: {
    errorInternal: "Erro interno do servidor",
    errorUnknown: "Erro desconhecido ao tentar recuperar emoções",
    title: "Registro de clima empresarial",
    labelQuantity: "Quantidade",
    subtitleNotFound: "Nada encontrado",
    subtitleNoFeelings: "Nenhum sentimento registrado",
    subtitleBiggestFeeling: "Maior Sentimento no periodo",
    titleCompanyFeels: "Como a empresa se sente no geral",
    titleEmotionGraph: "Clima empresarial por dia",
    subtitleEmotionGraph: "Maior sentimento registrado em cada dia",
    titleEmotionGraphLottie1: "Tivemos mais colaboradores",
    titleEmotionGraphLottie2: "neste dia",
    titleEmotionGraphToday: "Lista de emoções selecionadas neste dia",
    labelSector: "Setor",
    subtitleOtherReaction: "Outras reações apresentadas no periodo",
    titleOtherReactionLottie: "Como a empresa se sente no geral",
    labelPerQuantity: "(por quantidade)",
  },
  gestao_helpdesk: {
    labelFilter: "Filtro por periodo",
    labelFilterAdvanced: "Filtro avançado",
    tickets: "Tickets",
    titleChartStatus: "Tickets por status",
    titleChartSubject: "Tickets por assunto",
    titleChartAgent: "Tickets por agente",
    titleChartSector: "Tickets por setor",
    titleChartUser: "Tickets por usuario",
    titleChartMonth: "Tickets por mês",
    timeMedium: "Tempo médio de atendimento (geral)",
    titleChartTimeMedium: "Tempo médio de atendimento por Assunto",
    filterNameStatus: "Status",
    filterErrorStatus: "* Escolha ao menos um Status",
    filterNameAgent: "Agente",
    filterErrorAgent: "* Escolha ao menos um Agente",
    filterNameSubject: "Assunto",
    filterErrorSubject: "* Escolha ao menos um Assunto",
    filterNameSector: "Setor",
    filterErrorSector: "* Escolha ao menos um Setor",
    filterNameUser: "Usuário",
    filterErrorUser: "* Escolha ao menos um Usuário",
    filterNamePlant: "Planta",
    filterErrorPlant: "* Escolha ao menos um Planta",
    titleSearchAdvanced: "Busca avançada",
    descriptionSearchAdvanced:
      "Escolha entre as opções e monte uma busca avançada.",
  },
  ferias: {
    titleBtnDownloadReport: "Baixar relatório de férias não assinadas",
    labelBtnDownloadReport: "FÉRIAS NÃO ASSINADAS",
    labelGeoAccept: "Você já informou a sua localização !",
    labelGeo: "Permitir Localização",
    titleSignature: "Sua assinatura",
    clearSignature: "Limpar",
    saveSignature: "Salvar",
    titleImageLoad: "Imagem do usuário carregada",
    labelPhoto: "Tirar Foto",
    labelPhotoInsert: "Foto inserida",
    labelPhotoRemove: "Remover foto",
    alertRemoveImage: "Deseja realmente remover a foto ?",
    labelTerm: "Marque aqui se concorda com os valores informados acima.",
    labelSaveBtn: "CONFIRMAR RECEBIMENTO",
    labelBtnDownloadWarningVacation: "Recibo de Férias",
    errorNotFoundIP: "Erro ao tentar recuperar o IP",
    errorNotExistsWarningVacation:
      "Não existe aviso de férias para ser baixado",
    title: "Recibos de férias",
    labelBtn: "Assinar",
    initialVacation: "Ínicio",
    finalVacation: "Término",
    returnOfVacation: "Retorno das férias",
    quantityOfDays: "Quantidade de dias",
    dateOfPayment: "Data do pagamento",
    signature: "Assinado",
    notSignature: "Não assinado",
    labelBtnVacation: "Recibo Férias",
    labelBtnSalaryBonus: "Recibo Abono",
    titleSalaryBonus: "ABONO (TOTAL)",
    titleVacation: "FÉRIAS (Total)",
    verb: "Verba",
    provent: "PROVENTO",
    discount: "DESCONTO",
  },
  estatisticas: {
    labelToday: "Hoje",
    labelThisMonth: "Este mês",
    labelLastSixMonths: "Ult. 6 meses",
    labelByPeriod: "Por periodo",
    labelAccessBySO: "Acessos por S.O",
    labelAccessByBrowser: "Acessos por navegador",
    labelAccessByRoute: "Acessos por rota",
    title: "Estatisticas de acesso",
    labelQuantity: "Quantidade",
    periodOf: "Período de",
    periodTo: "até",
    access: "acesso(s)",
    titleAccessByMenu: "Acessos por página",
    titleTopSevenUser: "Top 7 Usuários",
    titleAccessByDay: "Acessos por dia",
    dateFrom: "Data de",
    dateTo: "Data até",
    titleFilterByPeriod: "Filtro por periodo 🧐",
    subtitleFilterByPeriod: "🔎 Escolha uma data em que deseja ver os dados",
    format: "Formato",
    btnSend: "ENVIAR",
  },
  manutencao_ativos: {
    titlePage: "Manutenção de Ativos",
    subtitlePage:
      "Escolha entre as opções disponiveis para realizar a manutenção de ativos que são utilizados em várias páginas.",
    labelArea: "Área",
    titleLabelArea: "Clique aqui para realizar a manutenção da Área",
    labelModelo: "Modelo",
    titleLabelModelo: "Clique aqui para realizar a manutenção do modelo",
    labelPerda: "Perda",
    titleLabelPerda: "Clique aqui para realizar a manutenção do perda",
    labelPilar: "Pilar",
    titleLabelPilar: "Clique aqui para realizar a manutenção do pilar",
  },
  manutencao_area: {
    titleArea: "Manutenção de Área",
    searchAreaPlaceholder: "Digite o nome da área",
    modelInactive: "Modelo Inativo (não pode ser usado)",
    modelActive: "Modelo ativo",
    active: "ATIVA",
    inactive: "INATIVA",
    titleBtnIconCancel: "Cancelar edição de área",
    titleBtnIconEdit: "Clique para editar a área",
    titleSituationArea: "Situação da área",
    titleSituationAreaActive: "Ativa para uso",
    titleSituationAreaInactive: "Desativada para uso",
    titlePlant: "Planta",
    listOfModels: "Lista de modelos",
    labelModelName: "Modelo",
    titleModelThis: "Este modelo está",
    titleModelActive: " ativado ",
    titleModelInactive: " desativado ",
    titleModelThis2: "para esta área",
    placeholderAreaName: "Digite nome da área",
    errorAreaName: "* Campo obrigatório",
    labelArea: "Área",
    titleName: "Nome",
    errorAreaMin: "* Mínimo de 3 caracteres",
    titleAddArea: "Inclusão de uma nova área",
    subtitleAddArea: "Informe o nome da nova área que será inclusa",
  },
  manutencao_modelo: {
    situationA: "Situacao: ATIVO",
    situationDefault: "Situacao: INATIVO",
    labelSituationActive: "ATIVO",
    titleSituationActive: "Exibir somente modelos ativos",
    labelSituationInactive: "INATIVO",
    titleSituationInactive: "Exibir somente modelos inativos",
    placeholderModelName: "Digite o nome do modelo",
    labelNoDataModel: "Nenhum modelo encontrado no filtro informado",
    labelModelName: "Modelo",
    errorModelName: "* Obrigatório",
    titleModelAdd: "Cadastro de modelo",
    subtitleModelAdd: "Insira abaixo o nome do novo modelo a ser inserido",
    titleName: "Nome",
    titleBtnCancel: "Cancelar edição do modelo",
    titleBtnEdit: "Clique para editar o modelo",
    titleModelEdit: "Visualização e edição do modelo",
    titleModel: "Manutenção de modelos",
    subtitleModel:
      "Clique sobre algum dos modelos para ver as opções disponíveis",
  },
  manutencao_perda: {
    labelWaitDesenv: "Aguarde... Página em desenvolvimento",
  },
  manutencao_pilar: {
    labelWaitDesenv: "Aguarde... Página em desenvolvimento",
  },
  dados_cadastrais: {
    errorServer: "Erro interno do servidor",
    male: "Masculino",
    female: "Feminino",
    depLabelNameDepend: "Nome do dependente",
    depPlaceholerNameDepend: "Nome do dependente",
    depLabelCpfDepend: "CPF do dependente",
    depPlaceholerCpfDepend: "EX: 000.000.000-00",
    depLabelBirthDepend: "Data de nascimento",
    depPlaceholerBirthDepend: "dd/mm/yyyy",
    depLabelSexDepend: "Sexo",
    depLabelSchoolingDepend: "Escolaridade",
    depLabelDegreeDepend: "Grau de parentesco",
    depErrorNameDepend: "* Minimo de 3 caracteres",
    depErrorSexDepend: "* Obrigatório",
    depErrorCpfDepend: "* EX: 000.000.000-00",
    depErrorBirthDepend: "* EX: 00/00/0000",
    depErrorSchoolingDepend: "* Obrigatório",
    depErrorDegreeDepend: "* Nome do dependente",
    titleDepend: "Formulário para manutenção de dependente",
    depConfirmDelete: "Deseja realmente excluir este dependente ?",
    depTitleBtnCConfigm: "Clique para confirmar o cadastro do dependente",
    depLabelBtnConfigm: "Confirmar",
    depTitleBtnEdit: "Clique para alterar algum campo do dependente",
    depLabelBtnEdit: "Editar",
    depTitleBtnDelete: "Clique para remover este dependente",
    depLabelBtnDelete: "Excluir",
    depLabelBirthNasDepend: "Nascimento",
    depLabelCpfDepend2: "CPF",
    titlePage: "Dependentes",
    titleFabAddDepend: "Clique para adicionar um dependente",
    labelFabAddDepend: "Add Dependente",
    subtitleListOfDependent:
      "Lista com os dependentes vinculados ao seu cadastro. Verifique as informações e confirme-as (quando necessário) ou edite o dependente excluindo-o.",
    titleNotDependent: "Não foram encontrados dependentes para você",

    labeName: "Nome",
    placeholderName: "Nome Completo",
    labelCpf: "CPF",
    placeholderCpf: "EX: 000.000.000-00",
    labelPhone: "Telefone",
    placeholderPhone: "EX: (00) 00000-0000",
    labelRg: "RG",
    placeholderRg: "Escreva aqui",
    labelEmail: "E-mail",
    placeholderEmail: "email@exemplo.com",
    labelBirth: "Nascimento",
    placeholderBirth: "DD/MM/YYYY",
    labelCourse: "Curso",
    placeholderCourse: "Curso ...",
    labelCourseDate: "Data do curso",
    labelMaritalStatus: "Estado civil",
    labelSchooling: "Escolaridade",
    labelDisability: "Deficiência",
    labelDisabilityType: "Tipo de deficiência",
    labelCep: "CEP",
    placeholderCep: "EX: 00000-000",
    labelAddress: "Logradouro",
    placeholderAddress: "Rua de exemplo",
    labelNumber: "Número",
    placeholderNumber: "Ex: 100",
    labelComplement: "Complemento",
    placeholderComplement: "complemento",
    labelCityCode: "Código municipio",
    placeholderCityCode: "Código municipio",
    labelDistrict: "Bairro",
    placeholderDistrict: "Bairro de exemplo",
    labelCity: "Cidade",
    placeholderCity: "Cidade",
    labelState: "Estado",
    placeholderState: "Estado",
    labelReferencePoint: "Ponto de referencia",
    placeholderReferencePoint: "Ponto de referência",
    labelEmergencyName: "Nome emergência",
    placeholderEmergencyName: "Nome de emergência",
    labelEmergencyContact: "Contato Emergência",
    placeholderEmergencyContact: "EX: (00) 00000-0000",
    labelAttachments: "Anexo(s)",
    errorName: "* Obrigatório",
    errorCpf: "Ex: 000.000.000-11",
    errorPhone: "Ex: (00) 00000-0000",
    errorRg: "* Mínimo 3 caracteres",
    errorEmail: "Ex: exemplo@email.com",
    errorBirth: "Ex: 00/00/0000",
    errorMaritalStatus: "* Escolha um estado civil",
    errorSchooling: "* Escolha um escolaridade",
    errorDisability: "* Obrigatório",
    errorDisabilityType: "* Obrigatório",
    errorCep: "* EX: 00000-000",
    errorAddress: "* Obrigatório",
    errorNumber: "* Obrigatório",
    errorCityCode: "* Obrigatório",
    errorDistrict: "* Obrigatório",
    errorCity: "* Obrigatório",
    errorState: "* Obrig",
    errorReferencePoint: "* Obrigatório",
    errorEmergencyName: "* Obrigatório",
    errorEmergencyContact: "* Ex: (00) 00000-0000",
    errorCepNot200: "Erro ao tentar consultar o CEP. Tente mais tarde.",
    errorCepNotFound: "Cep inexistente",
    titleFormUpd: "Dados Cadastrais",

    titleUpdPasswd: "Configurações do usuário",
    titleForm: "Atualização de senha",
    titleField1: "Nova senha",
    titleField2: "Confirmar senha",
    placeholderField1: "Digite a nova senha",
    placeholderField2: "Confirme a nova senha",
    btnUpdate: "Atualizar Senha",
    msgError: "AS SENHAS NÃO SÃO IGUAIS",
    msgFieldError: "Minimo de 3 caracteres",

    cardTitleName: "Nome",
    cardTitleCpf: "CPF",
    cardTitlePhone: "Telefone",
    cardTitleBirth: "Nascimento",
    cardTitleRg: "RG",
    cardTitleEmail: "E-mail",
    cardTitleMaritalStatus: "Estado civil",
    cardTitleSchooling: "Escolaridade",
    cardTitleCourse: "Curso",
    cardTitleCourseDate: "Data do curso",
    cardTitleDisability: "Deficiência",
    cardTitleDisabilityType: "Tipo de deficiência",
    cardTitleCep: "Cep",
    cardTitleStreet: "Logradouro",
    cardTitleNumber: "N°",
    cardTitleComplement: "Complemento",
    cardTitleReferencePoint: "Ponto de referência",
    cardTitleNeighborhood: "Bairro",
    cardTitleCity: "Cidade",
    cardTitleState: "Estado",
    cardTitleMunicipalityCode: "Código municipio",
    cardTitleEmergencyName: "Nome emergência",
    cardTitleEmergencyContact: "Contato emergência",
    cardTitleAttachment: "Anexo",
    cardTitleAttachmentClickHere: "Clique aqui",

    approved: "Aprovado",
    inAnalysis: "Em análise",
    pending: "Pendente",
    titleApproved: "Informação recebida e confirmada",
    titleInAnalysis: "Informação pendente de análise",
    titlePending: "Aguardando definição da situação",

    tab1: "Pessoais",
    tab2: "Dependentes",
    tab3: "Senha",
  },
  dados_cadastrais_page: {
    titlePage: "Relação de dados cadastrais",
    subtitlePage:
      "Clique sobre algum registro para alterar sua situação, ou ver dependentes.",
    titleViewAttachments: "Anexo(s) referentes ao cadastro",
    subtitleViewAttachments:
      "Abaixo segue uma lista com todos os anexos que foram enviados pelo colaborador que fazem parte deste cadastro dos dados",
    attachment: "Anexo",
    titleBtnAttach1: "Existem",
    titleBtnAttach2: "anexo(s) neste registro",
    titleConfirm: "Confirme o recebimento do cadastro",
    titleReject: "Rejeite os dados cadastrais enviados",
    titleActiveDeactivePend: "Ativa/Desativa a exibição de registros pendentes",
    titleActiveDeactiveOk: "Ativa/Desativa a exibição de registros confirmados",
    titleViewDependents: "Veja os dependentes ligados ao colaborador",
    titleDependents: "Dependentes relacionados ao colaborador",
    errorInternal: "ERRO INTERNO DO SERVIDOR",
    errorUnknown: "Erro desconhecido",
    labelFilterPendent: "PENDENTE",
    labelFilterOK: "OK",
  },
  banco_de_horas: {
    titlePage: "Banco de horas",
    titleUpdateCache: "Atualizar cache do banco de horas",
    saldoFinal: "SALDO FINAL",
  },
  documentos: {
    detailsHeaderFileVisibleForPlant: "Visivel para todos de",
    detailsHeaderFileOwner: "Dono do arquivo",
    editNameFileNewName: "Novo Nome",
    editNameFileNewNamePlaceholder: "Digite o novo nome do arquivo",
    editNameFileNewNameError: "* Mínimo de 3 caracteres",
    editNameFileTitle: "Digite o novo nome",
    labelEditVisibilityBy: "Visivel por",
    labelEditFuncPermissionView:
      "Funcionários com permissão de ver (deixe em branco para sem restrição)",
    errorVisibilityBy: "* Escolha ao menos uma planta para visualizar",
    titleEditVisibility: "Definir nível de visibilidade",
    labelItemFileMobileCreated: "Criado",
    labelItemFIleMobileFile: "Arquivo",
    labelItemCreated: "Criado",
    labelItemFile: "Arquivo",
    withPermissionToView: "tem permissão de visualizar",
    labelItemMenuDetails: "Detalhes",
    labelItemMenuRename: "Renomear",
    labelItemMenuCut: "Recortar",
    labelItemMenuAlterVisibility: "Alterar Visibilidade",
    labelItemMenuDelete: "Excluir",
    labelItemTrashDetails: "Detalhes",
    labelItemTrashRecover: "Recuperar da lixeira",
    labelItemTrashDeleteForever: "Excluir permanentemente",
    titleConfirmDelete: "Deseja realmente excluir ?",
    subtitleConfirmDelete:
      "Esta ação não pode ser desfeita. Caso seja um diretório, todos os arquivos abaixo serão removidos também.",
    subtitleConfirmDeleteFile:
      "Esta ação não pode ser desfeita. O item será eliminado definitivamente da lixeira",
    labelNewFolder: "Nome da pasta",
    placeholderNewFolder: "Digite o nome da pasta",
    labelVisibilityBy: "Visivel por",
    labelFuncPermittedView:
      "Funcionários com permissão de ver (deixe em branco para sem restrição)",
    errorNewFolder: "* O nome deve ter ao menos 2 caracteres.",
    errorNotFiles: "Necessário existir arquivos",
    titleNewFolder: "Criação de nova pasta",
    titleTrash: "Lixeira de itens excluidos",
    labelNewFiles: "Novos arquivos",
    placehlderNewFiles: "Escolha arquivos para enviar",
    titleUploadNewFiles: "Novos arquivos na pasta",
    errorInternalServer: "ERRO INTERNO DO SERVIDOR",
    titleViewFile: "Detalhes sobre o item",
    file: "Arquivo",
    fromFile: "do arquivo",
    fromFolder: "da pasta",
    created: "Criado",
    createdBy: "Criado por",
    linkToDownload: "Link para Download",
    secondaryLinkToDownload: "Clique para baixar o arquivo",
    titleAccessRestricted: "Acesso restrito somente para",
    titleAccessPermitted: "Acesso permitido há",
    labelAllPlantsView: "Todos das plantas com visualização podem ver",
    labelBtnGoBack: "VOLTAR",
    labelBtnZoomOut: "ZOOM OUT",
    labelBtnZoomIn: "ZOOM IN",
    labelBtnNext: "AVANÇAR",
    labelBtnClose: "FECHAR",
    descPageOf1: "Página",
    descPageOf2: "de",
    titleAccessTrash: "Acesso a lixeira",
    titleUploadFiles: "Upload de arquivos",
    titleCreateFolder: "Criar nova pasta",
    labelBtnCancel: "Cancelar",
    labelBtnPaste: "Colar Aqui",
    titleAnimationNoData: "Não há arquivos nesta pasta",
  },
  oportunidades: {
    titleHistVacation: "Histórico de movimentações do candidato a vaga",
    fromPhase: "Da fase",
    toPhase: "Para fase",
    observation: "Observação",
    btnHistAttachment: "Anexo",
    histData: "Data",
    errorInternal: "ERRO INTERNO DO SERVIDOR",
    errorUnknown: "ERRO DESCONHECIDO",
    illiterate: "ANALFABETO",
    incompletePrimary: "ATE 4ª SERIE INCOMPLETA (PRIMARIO INCOMPLETO)",
    completePrimary: "COM 4ª SERIE COMPLETA DO 1º GRAU (PRIMARIO COMPLETO)",
    incompleteSecondary: "PRIMEIRO GRAU (GINASIO) INCOMPLETO",
    completeSecondary: "PRIMEIRO GRAU (GINASIO) COMPLETO",
    incompleteHighSchool: "SEGUNDO GRAU (COLEGIAL) INCOMPLETO",
    completeHighSchool: "SEGUNDO GRAU (COLEGIAL) COMPLETO",
    incompleteCollege: "SUPERIOR INCOMPLETO",
    completeCollege: "SUPERIOR COMPLETO",
    completeMaster: "MESTRADO COMPLETO",
    completeDoctorate: "DOUTORADO COMPLETO",
    specialization: "POS-GRADUACAO/ESPECIALIZACAO",
    postDoctorate: "POS-DOUTORADO",
    labelWaitGeneratePDF: "Aguarde, gerando PDF...",
    isOk: "Preenchida",
    isOpen: "Em aberto",
    labelBtnClose: "Fechar",
    titleScholarship: "Escolaridade",
    titlePhone: "Celular",
    titleDateInscription: "Data da inscrição",
    titleSector: "Setor",
    titleEmail: "E-mail",
    titleAttachment: "Anexo(s) do candidato",
    attachment: "Anexo",
    titleAnswerQuestionnaire: "Respostas do questionário",
    titleBtnDownloadPdf: "Clique aqui para baixar uma versão em PDF",
    labelBtnDownloadPdf: "BAIXAR RESPOSTAS",
    hidden: "Ocultar",
    show: "Exibir Candidatos Registrados a vaga",
    labelPhaseActualy: "Fase atual",
    titleVacationPage: "Candidatos a vaga",
    phase: "FASE",
    titleBtnViewHistory: "Veja o histórico da movimentação",
    labelBtnViewHistory: "Histórico",
    titleBtnViewAnswers: "Veja as respostas ao questionário",
    labelBtnViewAnswers: "Respostas",
    titleBtnChangePhase: "Alterar candidato a próxima fase",
    labelBtnChangePhase: "Alterar Fase",
    labelEmail: "E-mail",
    placeholderEmail: "Seu email",
    labelPhone: "Celular",
    placeholderPhone: "(00) 00000-0000",
    labelSchooling: "Escolaridade *",
    placeholderWriteHere: "Escreva aqui",
    errorQuestion: "* Favor responder a pergunta",
    labelToPhase: "Próxima fase a ser movimentada",
    labelObservation: "Observação",
    placeholderObservation: "Digite aqui a próxima fase",
    labelFile: "Arquivo",
    placeholderFile: "Insira um arquivo",
    errorObservation: "* Favor digitar ao menos 3 caracteres",
    errorToPhase:
      "* Escolha a fase em que o candidato será movido há próxima fase",
    titleAlterPhase: "Mudança de fase",
    subtitleNewPhase: "Informe a nova fase em que o candidato foi transferido.",
    labelOpenDate: "Data de abertura",
    labelVacation: "Vaga",
    labelFunction: "Função",
    labelDescription: "Descrição",
    labelActivity: "Atividade",
    labelIamCandidate: "Já sou candidato",
    labelRegister: "ME CANDIDATAR",
    titleOpenDate: "Data de inclusão da vaga",
    titlePage: "Oportunidades",
    titleNoData: "Sem oportunidades no momento",
    titleSituationActualy: "Situação atual da vaga",
  },
  painel_de_notificacao: {
    titlePage: "Painel de notificações",
    subtitleSendMessageBySector: "Envio de mensagem por setor",
    titleSendNotificationBySector: "Envio de notificação por setor",
    titleSendNotificationByPlant: "Envio de notificação por planta",
    errorTitle: "Mínimo de 3 caracteres",
    errorDescription: "Mínimo de 3 caracteres",
    errorSector: "Selecione ao menos um setor",
    labelSector: "Escolha o setor",
    labelTitle: "Titulo",
    placeholderTitle: "Escreva um titulo",
    labelDescription: "Descricao",
    placeholderDescription: "Escreva uma descricao",
    labelBtnRemoveSector: "Remover setores selecionados",
    titleCanReceiveNotifications: "Podem receber notificações",
    titleCannotReceiveNotifications: "Não podem receber notificações",
    labelClearFilter: "Limpar filtro",
    titlePageSendOneNotification: "Envio de notificação individual",
    subtitlePageSendOneNotification1: "Notificando o usuário",
    subtitlePageSendOneNotification2: "do setor",
    titlePageSendPlantNotification: "Envio de notificação individual",
    subtitlePageSendPlantNotification:
      "Preencha os campos para enviar uma notificação a toda a planta de",
  },
  manutencao_componentes: {
    titlePage: "Manutenção de estruturas",
    titleFab: "Inserção de novo componente",
    labelFab: "NOVO COMPONENTE",
    components: "componentes",
    descriptionPortuguese: "Descrição Português",
    titlePageFormAdd: "INSERÇÃO DE NOVO COMPONENTE",
    labelCode: "Código",
    placeholderCode: "Código",
    labelDescriptionPortuguese: "Descrição Português",
    placeholderDescriptionPortuguese: "Descrição em português",
    labelDescriptionEnglish: "Descrição Ingles",
    placeholderDescriptionEnglish: "Descrição em ingles",
    labelDescriptionItalian: "Descrição Italiano",
    placeholderDescriptionItalian: "Descrição em italiano",
    labelComponentMath: "Matematica do componente",
    placeholderComponentMath: "Matemática do componente",
    labelSupplier: "Fornecedor",
    placeholderSupplier: "Fornecedor",
    labelType: "Tipo",
    placeholderType: "Tipo",
    labelMass: "Massa",
    placeholderMass: "Massa",
    labelMaterialStandard: "Norma material",
    placeholderMaterialStandard: "Especificação de Pintura",
    labelWeldingAreaSpecification: "Especificação areame solda",
    placeholderWeldingAreaSpecification: "Especificação areame solda",
    labelUnitMeasure: "Unidade de medida",
    placeholderUnitMeasure: "Unidade de medida",
    labelThreadProtectiveApplication: "Aplic protetivo da rosca",
    placeholderThreadProtectiveApplication: "Aplic protetivo da rosca",
    labelComponentImage: "Imagem do componente",
    placeholderComponentImage: "arquivo",
    expectMax: "* É esperado no máximo",
    characters: "caracteres",
  },
  informe_rendimentos: {
    titlePage: "Informe de rendimentos",
    titleNoData: "Clique sobre algum ano para baixar o informe de rendimento",
    titleChip: "Ano de",
    titleAnimation: "Informe de rendimentos. Clique no botão para baixar",
    titleButton: "Clique para baixar o informe do ano de",
    labelButton: "BAIXAR ANO",
  },
  budget: {
    titleClickValue: "Clique para ver detalhes",
    infoTable: "* Clique sobre os valores de VLR REAL para ver os detalhes",
    month: "",
    nothing: "Não há dados a serem retornados",
    tab1: "Sintético",
    tab2: "Analitico",
    title: "Detalhes",
    headerDescription: "DESCRIÇÃO DA CONTA",
    headerHistory: "HISTÓRICO",
    headerClientSupplier: "CLIENTE / FORNECEDOR",
    headerMonth: "MÊS",
    headerAccount: "CONTA",
    headerValue: "VALOR",
    titleNoData: "Não há dados",
  },
  ncp: {
    tituloBuscaAvancada: "Busca avançada",
    descricaoBuscaAvancada:
      "Escolha entre as opções e monte uma busca avançada.",
    tituloNaoConformidade: "EVIDÊNCIAS DE NÃO CONFORMIDADE",
    rotuloBtnFiltro: "BUSCA AVANÇADA",
    tituloSemDados: "Nenhum dado foi encontrado",
    tituloSemDadosRetorno: "Tentando carregar dados necessários...",
    titulo: "NCP",
    tituloPaginacao: "Clique para alternar entre as paginas",
    codigo: "Código",
    anomalia: "Anomalia: ",
    data: "Data Criação: ",
    area: "Área: ",
    rotuloBotaoAdd: "Novo NCP",
    tituloBotaoAdd: "Criar novo NCP",
    tituloForm: "ALERTA DE NÃO CONFORMIDADE DO PROCESSO",
    formAuditor: "AUDITOR",
    formConjunto: "CONJUNTO",
    formModelo: "MODELO",
    formTurno: "TURNO",
    formQtdKO: "QUANTIDADE KO",
    formQtdSuspeita: "QUANTIDADE SUSPEITA",
    formOperacao: "OPERAÇÃO",
    formNCP: "NCP N°",
    formPN: "PN",
    formRastreabilidade: "RASTREABILIDADE",
    formData: "DATA",
    formHora: "HORA",
    formErrorAuditor: "Informe ao menos um colaborador",
    formErrorConjunto: "Escolha ao menos uma área",
    formErrorModelo: "Escolha ao menos um modelo",
    formErrorTurno: "Escolha ao menos um turno",
    formErrorQtdKO: "Mínimo 0",
    formErrorQtdSuspeita: "Mínimo 0",
    formErrorOperacao: "Operação",
    formErrorNCP: "NCP N°",
    formErrorPN: "Informe o PN",
    formErrorRastreabilidade: "Informe a rastreabilidade",
    formErrorSubidaDeLinha: "Subiu de liha ?",
    formErrorFalhaForaControle: "Foi falha fora de controle ?",
    formErrorTwttp: "Obrigatório",
    formErrorData: "Informe da data",
    formErrorHora: "Informe a hora",
    formErrorAnomalia: "Informe a anomalia",
    formAuditorPlaceholder: "Informe o auditor",
    formConjuntoPlaceholder: "Área",
    formModeloPlaceholder: "Modelo",
    formTurnoPlaceholder: "Turno",
    formQtdKOPlaceholder: "EX: 1",
    formQtdSuspeitaPlaceholder: "EX: 1",
    formOperacaoPlaceholder: "Operacação",
    formNCPPlaceholder: "NCP N°",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Rastreabilidade",
    formDataPlaceholder: "Data",
    formHoraPlaceholder: "Hora",
    formAnomaliaPlaceholder: "Descreva a anomalia...",
    formAnomalia: "ANOMALIA",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "IDENTIFICAÇÃO DE RACK OU CAIXA",
    formOque: "O QUÊ ?",
    formQuando: "QUANDO ?",
    formOnde: "ONDE ?",
    formQuem: "QUEM ?",
    formQual: "QUAL ?",
    formComo: "COMO ?",
    formErrorOque: "Obrigatório",
    formErrorQuando: "Obrigatório",
    formErrorOnde: "Obrigatório",
    formErrorQuem: "Obrigatório",
    formErrorQual: "Obrigatório",
    formErrorComo: "Obrigatório",
    formOquePlaceholder: "O que houve ?",
    formQuandoPlaceholder: "Quando aconteceu ?",
    formOndePlaceholder: "Onde aconteceu ?",
    formQuemPlaceholder: "Quem identificou ?",
    formQualPlaceholder: "Qual é o nível de problema ?",
    formComoPlaceholder: "Como que ocorreu ?",
    formFenomenoRevisado: "FENOMENO REVISADO",
    formErrorFenomenoRevisado: "Obrigatório",
    formFenomenoRevisadoPlaceholder: "Descreva o fenomeno.",
    formCausaRaiz: "POSSÍVEL CAUSA RAIZ",
    formCausaRaizPlaceholder: "Descreva aqui...",
    formErrorCausaRaiz: "Obrigatório",
    tituloBotaoVoltar: "Clique para retornar",
    rotuloBotaoFormSalvar: "SALVAR",
    tituloBotaoRemoverImagem: "Clique para remover Imagem",
    formRotuloImagem: "Insira aqui a imagem",
    formErrorImagemOK: "Imagem obrigatória",
    formErrorImagemKO: "Imagem obrigatória",
    formErrorImagemIdent: "Erro envio da imagem",
    situacaoQualidade: "QUALIDADE",
    situacaoSubida: "SUBIDA DE LINHA",
    situacaoAberto: "ABERTO",
    opcaoDetalhes: "Detalhes",
    opcaoSubida: "Subida de Linha",
    opcaoDownloadPdf: "Baixar em PDF",
    opcaoQualidade: "Mover Qualidade",
    rotuloBotaoVerOpcoes: "OPÇÕES",
    tituloBotaoVerOpcoes: "Exibe as opções de manipulação do NCP",
    tituloFormSubida: "Formulário de subida de linha",
    formPlaceholderTempoGasto: "Informe o tempo gasto",
    formTempoGasto: "Tempo gasto",
    formPlaceholderMod: "Informe a mão de obra direta",
    formMod: "MOD",
    formPlaceholderMoi: "Informe a mão de obra indireta",
    formMoi: "MOI",
    formPlaceholderOK: "Informe a quantidade OK",
    formOK: "Quantidade OK",
    formPlaceholderKO: "Informe a quantidade KO",
    formKO: "Quantidade KO",
    formPlaceholderObservacao: "Descreva uma observação",
    formObservacao: "Observação",
    formErrorTempoGasto: "* Favor informar o tempo gasto HH:MM",
    formErrorMod: "* Valor mínimo 0",
    formErrorMoi: "* Valor mínimo 0",
    formErrorOK: "* Valor mínimo 0",
    formErrorKO: "* Valor mínimo 0",
    formErrorObservacao: "* Descreva uma observação minimo 3 caracteres",
    tituloFormQualidade: "Formulário de Qualidade",
    formPlaceholderCodigoProjeto: "Informe o codigo do projeto",
    formCodigoProjeto: "Código do Projeto",
    formPlaceholderProjeto: "Este NCP tem vinculo algum projeto ?",
    formProjeto: "Este NCP tem vinculo algum projeto ?",
    formPlaceholderAcoes: "Descreva as ações",
    formAcoes: "Ação",
    formErrorProjeto: "* Escolha Sim ou não",
    formErrorCodigoProjeto: "* Informe o código do projeto",
    formErrorAcoes: "* Descreva a ação minimo 3 caracteres",
    abaForm1: "NCP",
    abaSubida: "SUBIDA LINHA",
    abaQualidade: "QUALIDADE",
    rotuloBotaoVoltar: "VOLTAR",
    subidaLinhaMod: "Mão de obra Direta",
    subidaLinhaMoi: "Mão de obra Indireta",
    subidaLinhaTempo: "Tempo de trabalho",
    subidaLinhaOK: "Quantidade peças OK",
    subidaLinhaKO: "Quantidade peças KO",
    subidaLinhaObs: "Observação da mão de obra",
    qualidadeCodProjeto: "Número do Projeto",
    qualidadeObs: "Ações",
    filtroNomeStatus: "STATUS",
    filtroNomeStatusErro: "* Escolha ao menos um",
    filtroNomeMatricula: "MATRICULA",
    filtroNomeMatriculaErro: "* Escolha ao menos um",
    filtroNomeArea: "AREA",
    filtroNomeAreaErro: "* Escolha ao menos um",
    filtroNomeTurno: "TURNO",
    filtroNomeTurnoErro: "* Escolha ao menos um",
    filtroNomeNcp: "CODIGO NCP",
    filtroNomeNcpErro: "* Escolha ao menos um",
    filtroNomeOperacao: "OPERAÇÃO",
    filtroNomeOperacaoErro: "* Escolha ao menos um",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Escolha ao menos um",
    opcaoExcluirNcp: "Excluir NCP",
    opcaoRetornarAbertoNcp: "Voltar para aberto",
    opcaoRetornarSubidaDeLinhaNcp: "Voltar para subida de linha",
    titlePageCancel: "Deseja realmente cancelar este status ?",
    subtitleCancel: "O NCP será excluído e não poderá mais ser recuperado",
    subtitleCancel2: "O NCP retornará para o status ABERTO",
    subtitleCancel3: "O NCP retornará para o status SUBIDA DE LINHA",
  },
  treinamento: {
    trainningDateTraining: "Data do Treinamento",
    instructor: "Instrutor",
    local: "Local",
    titleViewTrainning: "Ver informações do Treinamento",
    labelViewTrainning: "VER TREINAMENTO",
    labelBtnOptions: "Opções",
    titleEditTrainning: "Editar informações do Treinamento",
    labelEditTrainning: "EDITAR TREINAMENTO",
    titleAddParticipant: "Adicionar alunos ao treinamento",
    labelAddParticipant: "ADICIONAR ALUNOS",
    labelViewHistory: "VER HISTÓRICO",
    titleViewHistory: "VER HISTÓRICO DO TREINAMENTO",
    labelAlterStatus: "ALTERAR STATUS",
    titleAlterStatus: "Alterar status do treinamento",
    optionTextNewTrainning: "Novo Treinamento",
    optionTitleNewTrainning: "Clique aqui para cadastrar novos treinamentos",
    optionTextMaintenceCouse: "Manutenção de Cursos",
    optionTitleMaintenceCouse: "Clique aqui para manutenção de cursos",
    optionTextProfessor: "Manutenção de Instrutor",
    optionTitleProfessor: "Clique aqui para manutenção de professores",
    titlePage: "Treinamento",

    titlePaginationFilter: "Exibição paginada.",
    trainningNoData: "Não há dados para serem exibidos",
    labelDescription: "Descrição",
    placeholderDescription: "Descrição do curso",
    labelDuration: "Duração",
    placeholderDuration: "Duração do curso",
    labelModel: "Modelo",
    placeholderModel: "Modelo do curso",
    errorDescription: "Mínimo de 1 e máximo de 150 caracteres",
    errorDuration: "Formato inválido. Exemplo: 00:00",
    trainningAddUpdInstTitleAdd: "Adicionar Instrutor",
    trainningAddUpdInstTitleUpd: "Atualizar Instrutor",
    trainningAddUpdInsLabelName: "Nome",
    trainningAddUpdInsErrorName:
      "O nome do instrutor deve ter entre 1 e 250 caracteres",
    trainningAddUpdInsErrorMat:
      "A matrícula do instrutor deve ter 6 caracteres",
    trainningAddUpdInsErrorPlant: "Selecione a planta",
    trainningAddUpdInsErrorOrigin: "Selecione a origem do instrutor",
    trainningAddUpdInsErrorEntity:
      "A entidade do instrutor deve ter entre 1 e 150 caracteres",
    trainningAddUpdInsPlaceholderName: "Nome do instrutor",
    trainningAddUpdInsPlaceholderMat: "Matrícula do instrutor",
    trainningAddUpdInsPlaceholderPlant: "Selecione a planta",
    trainningAddUpdInsPlaceholderEntity: "Entidade do instrutor",
    trainningAddUpdInsLabelMat: "Matrícula",
    trainningAddUpdInsLabelPlant: "Planta",
    trainningAddUpdInsLabelOrigin: "Origem do instrutor",
    trainningAddUpdInsLabelEntity: "Entidade",
    trainningAddUpdInsLabelInstructor: "Instrutor interno",
    titleTrainningCourse: "Manutenção de Cursos",
    textFabNewCouse: "Novo Curso",
    titleFabNewCouse: "Clique aqui para cadastrar novos cursos",
    labelCourseBtnOptions: "Opções",
    titleEdtCourse: "Editar Curso",
    labelEdtCourse: "Editar",
    titleDelCourse: "Excluir Curso",
    labelDelCourse: "Excluir",

    subtitleDelCourse: "Deseja realmente excluir o curso ?",
    titleDelInstructor: "Excluir Instrutor",
    subtitleDelInstructor: "Deseja realmente excluir o instrutor ?",
    titleFormAddNewCourse: "Adicionar novo treinamento",
    labelCourse: "* Curso",
    labelInstructor: "* Instrutor",
    labelDateInit: "* Data de início",
    labelLocal: "* Local",
    placeholderCourse: "Selecione um curso",
    placeholderInstructor: "Selecione um instrutor",
    placeholderDateInit: "Informe a data de início",
    placeholderLocal: "Informe o local",
    errorCourse: "Selecione um curso.",
    errorInstructor: "Selecione um instrutor.",
    errorDateInit: "Informe a data de início.",
    errorLocal: "Informe o local.",
    labelTypeOfCourse: "Tipo de curso",
    labelEfficacy: "Eficácia",

    labelTheme: "Temas",
    labelApply: "Desenvolvimento (ONDE APLICÁVEL)",
    labelDevelopment: "Desenvolvimento de Formação",
    labelObservation: "Observações",
    labelDocument: "Documentos",
    labelMoreTimeEfficiency:
      "Precisa de mais tempo para verificar a eficácia ?",
    labelMoreTrainning: "Necessita de mais treinamento ?",
    labelApproved: "Para aprovação do curso",
    labelFinish: "Para encerramento do relacionamento",
    placeholderTypeOfCourse: "Informe o tipo de curso",
    placeholderEfficacy: "Informe a eficácia",

    placeholderTheme: "Informe os temas",
    placeholderApply: "Informe o desenvolvimento onde aplicável",
    placeholderDevelopment: "Informe o desenvolvimento de formação",
    placeholderObservation: "Informe as observações",
    placeholderDocument: "Informe os documentos",
    placeholderMoreTimeEfficiency:
      "Informe se precisa de mais tempo para verificar a eficácia",
    placeholderMoreTrainning: "Informe se necessita de mais treinamento",
    placeholderApproved: "Informe para aprovação do curso",
    placeholderFinish: "Informe para encerramento do relacionamento",
    labelReponsibility: "* Responsável",
    placeholderResponsibility: "Informe o responsável",
    labelDateRealized: "Data de realização",
    placeholderDateRealized: "Informe a data de realização",
    labelPresence: "Presença",
    placeholderPresence: "Informe a presença",
    labelCertificate: "Certificado",
    placeholderCertificate: "Informe o certificado",
    errorResponsibility: "Informe o responsável.",
    trainningFormAlterStatusTitle: "Alterar situação do treinamento",
    trainningFormAlterStatusObservation: "Observação",
    trainningFormAlterStatusErrorObservation: "A observação é obrigatória",
    trainningFormAlterStatusFrom: "De",
    trainningFormAlterStatusTo: "Para",
    trainningInstructorTitle: "Manutenção de Instrutores",
    textFabNewInstructor: "Novo Instrutor",
    titleFabNewInstructor: "Adicionar novo instrutor",
    tilteEdtInstructor: "Editar Instrutor",
    labelEdtInstructor: "Editar",

    labelDelInstructor: "Excluir",
    titleFormStudents: "Adicionar alunos",
    titleFormStudentsSubtitle: "Adicione alunos ao treinamento",
    studentNumber: "Aluno N°",
    studentNewAdd: "Adicionar aluno ao treinamento",
    studentRemove: "Remover aluno do treinamento",
    labelBtnSaveStudents: "SALVAR",
    labelBtnClearAllStudents: "REMOVER TODOS",
    statusOpen: "ABERTO",
    statusInTraining: "EM TREINAMENTO",
    statusDelivered: "ATA ENTREGUE",
    trainningViewHistoryTitle: "Ver histórico do treinamento",
    trainningViewHistoryLabelMat: "Matrícula",
    trainningViewHistoryLabelName: "Nome",
    trainningViewHistoryLabelFromStatus: "Da situação",
    trainningViewHistoryLabelToStatus: "Para situação",
    trainningViewHistoryLabelDate: "Data do registro",
    trainningViewHistoryLabelObservation: "Observação",
    titleBtnGoBack: "Clique para voltar",
    labelBtnGoBack: "VOLTAR",
    labelNoDataTrainning: "Nenhum treinamento encontrado",
    titleListOfTrainning: "LISTA DE TREINAMENTO",
    labelCodeN: "RAD N°",
    labelData: "DATA",
    titleTypeOfTrainning: "TIPO DE TREINAMENTO",
    titleEfficiency: "EFICÁCIA, a ser avaliada por:",
    labelEfficiencyInfo:
      "Por favor, no final do RAD, descreva a metodologia de avaliação da eficácia e anexe evidências quando aplicável",
    labelLocale: "LOCAL",
    titleDeleteTrainning: "Excluir Treinamento",
    labelDeleteTrainning: "EXCLUIR TREINAMENTO",
    titlePageDeleteTrainning: "Excluir Treinamento",
    subtitlePageDeleteTrainning: "Deseja realmente excluir o treinamento ?",
    themeAndAttachs: "TEMAS E ANEXOS",
    trainningName: "(Nome)",
    trainningAssigned: "(Assinatura)",
    trainningProffessor: "PROFESSOR(ES)",
    trainningPersonal: "TREINADOR(ES)",
    titleDevelop: "Onde aplicável: DESENVOLVIMENTO ESPERADO DA FORMAÇÃO",
    tableCellNumber: "NÚMERO DO CRACHÁ",
    tableName: "NOME E SOBRENOME",
    tableAssigned: "ASSINATURA",
    tableAvailable: "AVALIAÇÃO DE EFICÁCIA\n(resultado)",
    titleDevelopFormattion:
      "DESENVOLVIMENTO DE FORMAÇÃO ALCANÇADO (após verificação de eficácia)",
    subtitleDevelopFormattion1: "Nível",
    subtitleDevelopFormattion2:
      "atingiu o nível? Insira o número (#) associado ao funcionário",
    labelObservationInfo: "OBSERVAÇÃO",
    labelResponsibility: "RESPONSÁVEL",
    labelDocumentDistribution: "DOCUMENTOS DISTRIBUIDOS",
    titleMoreEfficiency: "Precisa de mais tempo para verificar a eficácia?",
    titleMoreTrainning:
      "Em caso de resultado negativo ou parcialmente positivo da avaliação da eficácia de um ou mais participantes:",
    titleMoreTrainning2: "Precisa de mais treinamento ?",
    toApproved: "PARA APROVAÇÃO DO CURSO (HR/EHS/QS/PLM)",
    toFinish: "PARA ENCERRAMENTO DE RELACIONAMENTO (HR/EHS/QS/PLM)",
    downloadPDF: "Baixar PDF",
    clearFilter: "Limpar filtro",
    optionTextDownloadExcel: "Baixar Excel",
    optionTitleDownloadExcel: "Clique aqui para baixar os dados em Excel",
    trainningDownloadExcelTitle: "Download Excel",
    trainningDownloadExcelLabelDateFrom: "Data do treinamento de",
    trainningDownloadExcelLabelDateTo: "Data do treinamento até",
    trainningDownloadExcelErrorDateFrom: "Data do treinamento de é obrigatório",
    trainningDownloadExcelErrorDateTo: "Data do treinamento até é obrigatório",
  },
};

export default translate;
