import React from "react";
import EscalonadaQuestionarioRecuperar from "./escalonada-questionario-recuperar";
import { QuestionForm } from "../../../../components";

function EscalonadaQuestionarioVerResposta({ id, idCalendar }) {
  return (
    <>
      <EscalonadaQuestionarioRecuperar
        id={id}
        idCalendar={idCalendar}
        verRespostas={true}
        render={(data) => (
          <QuestionForm questions={data?.questionarios} viewResponses={true} />
        )}
      />
    </>
  );
}

export default EscalonadaQuestionarioVerResposta;
