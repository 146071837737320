/**
 * Arquivo de traduções de strings de texto do sistema baseado no idioma informado.
 * Esta estrutura é padrão a todos os arquivos dentro desta pasta de deve seguir a
 * mesma sequencia de chave_valor dos demais.
 *
 * O padrão é link: { chave: valor }, sendo o "link" o nome da URL do menu em acesso
 * via lowercase.
 *
 * TRADUÇÃO ESP
 */

const translate = {
  components: {
    calendario: {
      sunday: "Domingo",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      prev: "Retroceder",
      next: "Avanzar",
      beforeMonth: "Mes anterior",
      afterMonth: "Próximo mes",
      today: "Hoy",
    },
    filtro_por_periodo: {
      titleApplyFilter: "Aplica filtro para los datos",
      fromToday: "desde hoy.",
      lastDays: "de los últimos",
      days: "días",
      today: "Hoy",
      perPeriod: "Por período",
      filterApplied: "Filtro aplicado en el período",
      filterAppliedErrors: "Filtro aplicado para errores por período definido",
      titleClearFilter: "Borrar filtro aplicado",
      labelClearFilter: "Borrar filtro",
      labelFrom: "Desde",
      labelTo: "Hasta",
      errorDateFrom: "* Fecha desde obligatoria",
      errorDateTo: "* Fecha hasta obligatoria",
      title: "Elige entre las fechas desde y hasta",
      subtitle:
        "Ten cuidado de no elegir la fecha DESDE mayor que la HASTA, de lo contrario tu filtro estará vacío.",
    },

    filtro_avancado: {
      title: "Elige el tipo de filtro a aplicar",
    },
    row_paginate_template: {
      labelButtonAdd: "AÑADIR",
      titlePagination: "Haz clic para cambiar entre las páginas",
      titleButtonOptions: "Haz clic para mostrar las opciones",
      labelButtonOptions: "Opciones",
      titlePage: "Plantilla de paginación como registro",
      titleButtonAdd: "Haz clic para añadir",
    },
    aniversariantes: {
      title: "Lista de cumpleañeros",
      titleCompany: "Cumpleañeros de la empresa",
      erroGetBirthDay: "Error al intentar recuperar los cumpleañeros",
      altImg: "Imagen de cumpleañeros",
      today: "Hoy",
      labelBtnNext: "Próximos",
      greeting: "Felicita 🥳",
    },
    entrada_form: {
      labelBtnSend: "ENVIAR",
    },
    drawer_dialog: {
      title: "Haz clic para cerrar",
      labelBtnClose: "CERRAR",
    },
    fab_extend: {
      title: "Haz clic para incluir",
      text: "Agregar",
    },
    confirmar: {
      yes: "Sí",
      no: "No",
    },
  },
  lesson: {
    titleHeaderTable: "LISTA MAESTRA DE LECCIONES APRENDIDAS",
    nameAndEmail: "NOMBRE / EMAIL",
    dateCreated: "FECHA DE CREACIÓN",
    plant: "PLANT",
    pn: "PART NUMBER",
    descriptionPiece: "PARTE DESCRIPCIÓN",
    categoryAnomaly: "CATEGORÍA DE ANOMALÍA",
    anomalyFound: "ANOMALÍA ENCONTRADA",
    identifyLL: "LL IDENTIFICACIÓN",

    title: "FORMULÁRIO DE LIÇÕES APRENDIDAS",
    labelButtonGoBack: "Voltar",
    titleButtonBack: "Retornar a página de Lições Aprendidas",
    labelDownloadPDF: "Baixar Em PDF",
    titleButtonDownloadPdf: "Clique para baixar em PDF",
    placeholder: "Digite aqui...",
    labelImpact: "IMPACTO DO PROJETO",
    labelPlant: "PLANTA",
    labelPN: "PART NUMBER",
    labelProcessUnit: "UNIDADE DO PROCESSO",
    labelOperation: "OPERAÇÃO",
    labelLine: "LINHA",
    labelAreaAffected: "AREA AFETADA",
    labelDate: "DATA",
    labelDescAnomaly: "DESCRIÇÃO DA ANOMALIA",
    labelTitleEffect1: "QUAL É O SINTOMA efeito do problema ?",
    labelImageKO: "IMAGEM KO",
    labelImageOK: "IMAGEM OK",
    labelCauseRoot: "CAUSA RAIZ",
    labelTitleDescriptionProblem: "DESCRIÇÃO DO PROBLEMA 5W2H",
    labelWhat: "What/ What happened?",
    labelWhy: "Why/ Why it is a problem? ",
    labelWhen: "When/ When did it happen ?",
    labelWhere: "Where/ Where has been detected ?",
    labelWho: "Who/ Who has detected it ?",
    labelHow: "How/ How has it been detected?",
    labelHowMany: "How many/ How many ?",
    labelTitleEffect: "EFEITO",
    labelProblemImage: "PROBLEMA",
    labelSolutionImage: "SOLUÇÃO",
    labelDescProblem: "DESCRIÇÃO DO PROBLEMA",
    labelDesSolution: "DESCRIÇÃO DA SOLUÇÃO",
    labelDrawingMathematicalImage: "DESENHO MATEMÁTICA",
    labelFabricant: "FABRICANTE",
    labelTitleDocDefault: "ATUALIZAÇÃO DOS DOCUMENTOS PADRÃO",
    labelTableTitleDesc: "DESCRIÇÃO",
    labelTableTitleRevision: "NECESSÁRIO REVISAR? - SIM/NÃO",
    labelTableResponsible: "RESPONSÁVEL",
    labelTableData: "DATA",
    labelTableAccomplished: "REALIZADO",
    labelObservation: "OBSERVAÇÃO",
    labelCust: "CUSTO",
    labelBenef: "BENEFICIO",
    labelImageInsert: "Insira a imagem aqui",
    labelBtnAddEffect: "Incluir Efeito",
    labelBtnAddUpdateDoc: "CLIQUE AQUI PARA INSERIR",
    titleRemoveImageUpload: "Clique para escolher uma imagem para upload",
    titleRemoveEffect: "Clique para remover o efeito",
    titleFormAddTypeUpdate: "PREENCHA OS CAMPOS ABAIXO",
    errorListUpdate: "* Campo obrigatório",
    errorRevision: "* Campo obrigatório",
    errorResponsible: "* Campo obrigatório",
    errorDate: "* Campo obrigatório",
    errorAccomplished: "* Campo obrigatório",
    errorObservation: "* Mínimo de 3 caracteres",
    labelBtnSend: "ENVIAR",
    placeholderMoney: " 0,00",
    messageErrors: {
      idImpact: "* Escolha um impacto do projeto",
      plant: "* Escolha uma planta",
      idUnitProcess: "* Escolha uma unidade de processo.",
      data: "* Escolha uma data.",
      operacao: "* Escolha uma operação",
      area: "* Escolha uma linha.",
      pn: "* Escolha um Part Number",
      arvore: "* Escolha um setor",
      anomaly: "* A anomalia deve ser informada",
      imageKO: "imagem_ko",
      imageOK: "imagem_ok",
      causeRoot: "* A causa raiz deve ser informada",
      what: '* A pergunta "O que" deve ser informada',
      why: '* A pergunta "Por que" deve ser informada',
      when: '* A pergunta "Quando" deve ser informada',
      where: '* A pergunta "Onde" deve ser informada',
      who: '* A pergunta "Quem" deve ser informada',
      how: '* A pergunta "Como" deve ser informada',
      how_many: '* A pergunta "Quantos" deve ser informada',
      imageProblem: "imagem_problema",
      imageSolution: "imagem_solucao",
      descriptionProblem: "* A descrição do problema precisa ser informada",
      descriptionSolution: "* A descrição da solução precisa ser informada",
      imageDrawing: "imagem_desenho",
      fabric: "* O fabricante  precisa ser informado",
      effect: "efeito_",
      updateDoc: "atualizacao_documentos",
      coust: "* O custo precisa ser informado",
      benef: "* O beneficio precisa ser informado",
    },
  },
  ponto_eletronico: {
    title: "Registro de marcações",
    closeDrawer: "Fechar",
    btnEnviar: "Enviar",
    labelField: "Periodo",
    mobileFieldDay: "Dia",
    mobileFieldData: "Data",
    mobileFieldInput: "Entrada",
    mobileFieldOut: "Saída",
    mobileFieldView: "Ver",
    goBackEquip: "Retornar a equipe",
    titleUpdateCache: "Clique aqui para atualizar o cache do ponto eletrônico",
    resultPartial: "Resultados Parcial",
    headerOne: "DIA",
    headerTwo: "DATA_BATIDA",
    headerThree: "1E",
    headerFour: "1S",
    headerFive: "2E",
    headerSix: "2S",
    headerSeven: "EXTRA",
    headerEight: "ADICIONAL",
    headerNine: "FALTA",
    headerTen: "ABONO",
    headerEleven: "SITUACAO",
  },
  contra_cheque: {
    titulo: "Nómina",
    campoAnos: "Años",
    campoMeses: "Meses",
    contraChequeTitulo: "Información personal",
    contraChequeMatricula: "Matrícula",
    contraChequeNome: "Nombre",
    contraChequeFuncao: "Función",
    contraChequeAdmissao: "Fecha de admisión",
    contraChequeVencimentos: "Pagos",
    contraChequeDescontos: "Descuentos",
    contraChequeLiquido: "Neto",
    contraChequeBaseDeCalculo: "Base de cálculo",
    contraChequeCod: "Cód",
    contraChequeDescricao: "Descripción",
    contraChequeRef: "Ref",
    contraChequeValor: "Valor",
    btnBaixar: "Descargar",
    btnBaixarTitulo: "Generar PDF de esta nómina",
    cnpj: "CNPJ",
    endereco: "Dirección",
    reciboSalario: "RECIBO DE PAGO DE SALARIO",
    codigo: "Código",
    descricao: "Descripción",
    referencia: "Referencia",
    vencimentos: "Pagos",
    descontos: "Descuentos",
    oneMonth: "ENE",
    twoMonth: "FEB",
    threeMonth: "MAR",
    fourMonth: "ABR",
    fiveMonth: "MAY",
    sixMonth: "JUN",
    sevenMonth: "JUL",
    eightMonth: "AGO",
    nineMonth: "SEP",
    tenMonth: "OCT",
    elevenMonth: "NOV",
    twelveMonth: "DIC",
    thirteenMonth: "13°",
    updateCache: "Actualizar caché de la hoja abierta",
  },
  galeria_de_fotos: {
    titleBtnAdd: "Añadir un nuevo álbum",
    labelBtnAddAlbum: "Nuevo Álbum",
    labelBtnAddPhoto: "Añadir Fotos",
    titleEditAlbum: "Cambiar el asunto y el título del álbum",
    titleDelAlbum: "Eliminar álbum, incluye fotos, me gusta y comentarios",
    labelEditAlbum: "Editar Álbum",
    labelDelAlbum: "Eliminar Álbum",
    titleViewOptions: "Haz clic para ver las opciones",
    titleViewPhotosAlbum: "Haz clic aquí para ver las fotos",
    postedOn: "Publicado en",
    titleTotalOf: "Total de",
    titleInAlbum: "fotos en el álbum",
    titleAlbumVisibleTo: "Álbum visible para",
    titleFormAddAlbum: "Formulario para registrar un álbum",
    descriptionFormAddAlbum:
      "Rellena los campos para la intención de crear un álbum de fotos describiendo un título, asunto y visibilidad del álbum",
    labelFormTitleAlbum: "* Título",
    placeholderTitleAlbum: "Describe el título aquí",
    errorTitleAlbum: "* Mínimo de 3 caracteres",
    labelFormSubjectAlbum: "* Asunto",
    placeholderSubjectAlbum: "Describe el asunto aquí",
    errorSubjectAlbum: "* Mínimo de 3 caracteres",
    labelPlantAlbum: "* Álbum visible para",
    errorPlantAlbum: "Elige al menos una planta",
    labelBtnSendAlbum: "GUARDAR",
    titleFormDelAlbum: "¿Estás seguro de que quieres eliminar?",
    subjectFormDelAlbum:
      "Si optas por eliminar, perderás fotos, me gusta y comentarios sobre este álbum.",
    labelBtnNo: "No",
    labelBtnYes: "Sí",
    listOfPhotos: "Fotos del álbum",
    titleImageOfAlbum: "Imagen del álbum",
    titleTotalOfLikes: "Total de me gusta",
    titleClickViewLegend: "Haz clic para editar la leyenda de la foto",
    titleClickDelPhoto: "Haz clic para editar la leyenda de la foto",
    titleTotalOfComments: "Total de comentarios",
    altPhoto: "Imagen del álbum",
    titlePhotoOfAlbum: "Foto del álbum",
    goBack: "Retroceder",
    downloadPhoto: "Descargar",
    closePhoto: "CERRAR",
    nextPhoto: "SIGUIENTE",
    thisFile: "Este archivo",
    notAccept: "no es un tipo aceptable",
    labelFormFilesPhoto:
      "Elige las fotos que se insertarán para formar parte de este álbum.",
    labelFormFile: "Fotos",
    labelBtnSendPhoto: "ENVIAR",
    listOfComments: "Lista de comentarios",
    confirmationDelComment: "¿Realmente quieres eliminar este comentario?",
    placeholderAddComment: "Escribe tu comentario",
    listOfLikes: "Lista de Me gusta",
    titleClickChooseLike: "Haz clic para elegir el me gusta",
    youLike: "Te gustó",
    closeLike: "Cerrar",
    like: "Me gusta",
    titleFormUpdLegend: "Formulario para editar la leyenda",
    writeNewLegendPhoto: "Escribe la nueva leyenda para la foto",
    labelFormLegend: "* Leyenda",
    placeholderFormLegend: "Describe la leyenda aquí",
    errorLegend: "Mínimo de 3 caracteres",
    labelBtnSendLegend: "Mínimo de 3 caracteres",
    titleFormDelPhoto: "¿Estás seguro de que quieres eliminar?",
    subtitleFormDelPhoto:
      "Si optas por eliminar, perderás todos los me gusta y comentarios sobre esta foto",
  },
  feed_rh: {
    titleBirthDayCompany: "Cumpleaños de la empresa",
    errStatusNotOK: "Error al intentar obtener el estado emocional",
    errUnknown: "ERROR DESCONOCIDO",
    titleEmotionalClimate: "Clima empresarial",
    descriptionEmotionalClimate: "¿Cómo te sientes?",
    today: "Hoy",
    btnNewPost: "NUEVA PUBLICACIÓN",
    feedNotFound: "No se ha creado ninguna publicación hasta ahora.",
    btnEdit: "Editar",
    btnDelete: "Eliminar",
    titlePostedOn: "Publicado en:",
    viewLikes: "Ver me gusta",
    notComments: "sin comentarios",
    notLikes: "sin me gusta",
    viewMinus: "Ver menos",
    viewMore: "Ver más",
    postedOn: "Publicado en ",
    titleWhenComments: "Cuántos comentarios",
    altImageFeed: "Imagen del logo en la publicación",
    comment: "comentario",
    comments: "comentarios",
    titleLikedYou: "Te gustó esto",
    titleLike: "Me gusta la publicación",
    like: "me gusta",
    likes: "me gusta",
    animationLike: "Me gusta",
    animationHappy: "Feliz",
    animationZen: "Emocionado",
    animationLove: "Enamorado",
    animationConfused: "Confundido",
    animationSad: "Triste",
    animationUngry: "Enojado",
    titleFeedLikes: "Cantidad de me gusta por tipo",
    formDelTitleFeedDel: "¿Estás seguro de esto?",
    formDelSubtitleFeedDel:
      "Si eliges confirmar la eliminación de la publicación, perderás todos los comentarios y me gusta en esta publicación.",
    formAddUpdLabelTitle: "Título",
    formAddUpdLabelSubject: "Asunto",
    formAddUpdLabelObservation: "Observación",
    formAddUpdLabelFile: "Archivo",
    formAddUpdLabelPlant: "Publicación visible para",
    formAddUpdPlaceholderTitle: "Describe el título aquí",
    formAddUpdPlaceholderSubject: "Describe el asunto aquí",
    formAddUpdPlaceholderObservation: "Describe la observación aquí",
    formAddUpdPlaceholderFile: "Archivo",
    formAddUpdPlaceholderPlant: "Publicación visible para",
    formAddUpdErrorTitle:
      "* El campo de título debe tener al menos 2 caracteres",
    formAddUpdErrorSubject:
      "* El campo de asunto debe tener al menos 2 caracteres",
    formAddUpdErrorFile: "* El archivo no fue enviado",
    formAddUpdErrorPlant: "* Elija al menos una unidad para adjuntar",
  },
  ouvidoria_view: {
    title: "Defensoría",
    id: "ID",
    total: "Total de defensorías",
    subject: "Asunto",
    situation: "Situación",
    dateCreated: "Fecha de creación",
    interaction: "Interacción: ",
    labelComponentPagination: "Haz clic para alternar entre las páginas",
    themePanel: "Temas",
    typePanel: "Tipos",
    titleBtnAdd: "Haz clic para abrir una nueva defensoría",
    titleBtnSearch: "Haz clic para buscar una defensoría por Token",
    labelBtnAdd: "DEFENSORÍA",
    labelBtnSearch: "TOKEN",
    noData: "No se encontraron defensorías",
    noDataInteraction: "Defensoría no encontrada",
    titleAdd: "Abrir defensoría",
    description: "Descripción",
    plant: "Planta",
    attach: "Anexo",
    placeholderSubject: "Escribe el asunto",
    placeholderText: "Más detalles sobre la defensoría",
    errorId: "* Elige al menos un ID",
    errorType: "* Necesario elegir un tipo",
    errorTheme: "* Necesario elegir un tema",
    errorSubject: "* Mínimo de 3 caracteres",
    errorDescription: "* Describe tu solicitud",
    errorSituation: "* Elige el nuevo estado",
    detailsOmbudsman: "Detalles",
    titleBtnGoBack: "Volver a la pantalla de defensorías",
    labelBtnGoBack: "Volver",
    titleInteraction: "Interactuar",
    labelBtnInteraction: "INTERACTUAR",
    ombudsman: "Defensoría: ",
    titleTab1: "Historial",
    titleTab2: "Detalles",
    placeholderSearchToken: "Token",
    titleSearchToken: "Búsqueda por Token",
    titleOmbudsmanCopyToken: "Defensoría anónima creada",
    descriptionToken:
      "Si deseas seguir esta defensoría haz clic en el token para copiarlo.",
    tokenCopySuccess: "Token copiado con éxito",
    labelBtnFilter: "Filtro Avanzado",
    titleBtnFilter: "Haz clic para realizar un filtro avanzado.",
    titleFilter: "Selecciona el filtro",
    titleFilterId: "Haz clic para seleccionar el filtro por ID",
    titleFilterTheme: "Haz clic para seleccionar el filtro por tema",
    titleFilterType: "Haz clic para seleccionar el filtro por tipo",
    titleFilterSituation: "Haz clic para seleccionar el filtro por estado",
    statusOpen: "Abierto",
    statusClosed: "Cerrado",
    statusMonitoring: "En análisis",
    ombudsmanStatus: "Estado de la Defensoría",
    ombudsmanNumber: "Número de la Defensoría",
    createdAt: "Creado en",
    lastInteraction: "Última interacción",
    type: "Tipo",
    theme: "Tema",
    owner: "Solicitante",
    situacao: "Situación",
  },
  colaboradores: {
    pageTitle: "Mantenimiento de colaboradores",
    fabTitle: "Agregar un nuevo colaborador",
    fabLabel: "AGREGAR",
    labelMat: "Matrícula",
    placeholderMat: "000000",
    labelThree: "ÁRBOL",
    placeholderThree: "Elige el árbol",
    labelIsManager: "¿Es gerente?",
    errorMat: "* Campo obligatorio",
    errorThree: "* Campo obligatorio",
    formAddTitle: "Registro de colaborador",
    formAddDescription:
      "Ingrese la matrícula del colaborador, el sector y si es un gerente.",
    filterLabelManager: "Gerente",
    filterLabelEligible: "Elegible",
    filterLabelActive: "Activo",
    filterLabelInactive: "Inactivo",
    labelTotal: "Total",
    optionsLabel: "Opciones",
    optionLabelMenu: "CAMBIAR MENÚ(S)",
    optionLabelManager: "GERENTE X SITUACIÓN X ELEGIBLE",
    optionLabelInfoPerson: "CAMBIAR INFORMACIÓN PERSONAL",
    dateOfContract: "Admisión",
    labelManagerOfThree: "Gerente del Árbol",
    colabItemTitle: "Cantidad de menús asignados",
    menuOfAccessTitle: "Lista de menús de acceso",
    menuOfAccessDescription:
      "Listado de todos los menús disponibles que pueden ser (o están) asignados al colaborador",
    placeholderFilterColab: "Filtro para los menús disponibles",
    labelActiveInactiveAll: "Activar / Desactivar TODOS",
    managerActiveEligibleTitle: "Gerente X Activo X Elegible",
    managerActiveEligibleDescription:
      "Activa o desactiva al colaborador para ser gerente, activa o desactiva para ser elegible o si está autorizado para iniciar sesión en el sistema.",
    labelEnableDisable: "Habilitar / Deshabilitar",
    labelPasswdTitle: "Contraseña",
    labelPasswdDescription: "Ingrese una nueva contraseña para el colaborador",
    labelPasswdPlaceholder: "Ingrese la contraseña",
    errorPasswd: "* La contraseña debe tener al menos 6 caracteres",
    labelBtnSavePasswd: "Guardar Nueva Contraseña",
    labelThreeTitle: "Árbol",
    labelThreeDescription: "Cambia el árbol del colaborador si lo deseas",
    labelThreePlaceholder: "Elige el árbol",
    labelBtnSaveThree: "Actualizar Árbol",
    labelEmailTitle: "Correo electrónico",
    labelEmailDescription:
      "Cambia o asigna un nuevo correo electrónico al colaborador",
    labelEmailPlaceholder: "Ej: nombre@email.com",
    errorEmail: "* Ej: nombre@email.com",
    labelBtnSaveEmail: "Cambiar Correo Electrónico",
    labelName: "Nombre",
    placeholderName: "Nombre del colaborador",
    errorName: "* Campo obligatorio",
    labelPlant: "Planta",
    placeholderPlant: "Elige la planta",
    errorPlant: "* Campo obligatorio",
    labelFunction: "Cargo",
    placeholderFunction: "Elige el cargo del colaborador",
    errorFunction: "* Campo obligatorio",
    labelDateAdmission: "Fecha de admisión",
    placeholderDateAdmission: "Elige la fecha de admisión",
    errorDateAdmission: "* Campo obligatorio",
    placeholderPassword: "Introduce la contraseña",
    labelPassword: "Contraseña",
    errorPassword: "* La contraseña debe tener al menos 6 caracteres",
    addColabTab1: "INCLUSIÓN AUTOMÁTICA",
    addColabTab2: "INCLUSIÓN MANUAL",
    formAddTitleManual: "Inclusión manual del colaborador",
    formAddDescriptionManual:
      "Introduce los datos del colaborador para la inclusión manual.",
  },
  minha_equipe: {
    noDataTitle1: "Elige una de las opciones",
    noDataTitle2: "laterales",
    noDataTitle3: "del panel para ver los datos",
    placeholderSearchEmployee: "Introduce el nombre o el puesto",
    optionsPanelPointTitle: "Punto electrónico",
    optionsPanelPointDescription: "Ver el punto electrónico del equipo",
    optionsPanelBankOfHoursTitle: "Banco de horas",
    optionsPanelBankOfHoursDescription: "Ver el banco de horas del equipo",
    optionsPanelBankOfHoursGeneralTitle: "Banco de horas general",
    optionsPanelBankOfHoursGeneralDescription: "Ver el banco de horas general",
    optionsPanelPerformanceEvaluationTitle: "Evaluación de rendimiento",
    optionsPanelPerformanceEvaluationDescription: "",
    myEquipBankOfHoursGeneralPageTitle: "Banco de horas general",
    myEquipBankOfHoursGeneralCredit: "Crédito",
    myEquipBankOfHoursGeneralDebit: "Débito",
    myEquipBankOfHoursGeneralBalance: "Saldo",
    myEquipBankOfHoursPageTitle: "Banco de horas del equipo",
    myEquipBankOfHoursFinalBalance: "SALDO FINAL",
    myEquipPointPageTitle: "Punto electrónico del equipo",
    myEquipPointLabelPeriod: "Periodo del punto",
    myEquipPointTotalResultPartial: "Resultados parciales",
    myEquipQuestionTitle: "Cuestionario de competencias conductuales",
    myEquipQuestionView:
      "Haz clic en un colaborador para ver la evaluación de rendimiento.",
    myEquipQuestionErrorName: "* Es necesario completar",
    myEquipQuestionObsEvaluatorLabel: "Observación del evaluador",
    myEquipQuestionObsEvaluatorPlaceholder:
      "Introduce las observaciones del evaluador",
    myEquipQuestionObsEvaluatedLabel: "Observación del evaluado",
    myEquipQuestionObsEvaluatedPlaceholder:
      "Introduce las observaciones del evaluado",
    myEquipQuestionEvaluatorError: "* Introduce detalles sobre el evaluador",
    myEquipQuestionEvaluatedError: "* Introduce detalles sobre el evaluado",
    myEquipQuestionErrorUnknown: "ERROR DESCONOCIDO",
    myEquipQuestionTitleObsQuestion: "Observaciones sobre el cuestionario",
    myEquipQuestionEvaluationResponseTitle: "Evaluaciones respondidas",
    myEquipQuestionDownloadPdfTitle:
      "Haz clic en el botón para descargar la evaluación en PDF",
    myEquipQuestionDownloadPdfBtnLabel: "Descargar PDF",
    myEquipQuestionHeaderResponseName: "Nombre",
    myEquipQuestionHeaderResponseMat: "Matrícula",
    myEquipQuestionHeaderResponseFunction: "Función",
    myEquipQuestionHeaderResponseEvaluator: "Evaluador",
    myEquipQuestionHeaderResponseDateOfEvaluation: "Fecha de la evaluación",
    myEquipQuestionHeaderResponsePageTitle: "Evaluación de rendimiento",
    myEquipQuestionViewEvaluationResponsePageTitle: "Observaciones",
    myEquipQuestionEvaluationResponseAskBody: "Respuesta",
    myEquipQuestionEvaluationResponseObsEvaluatorTitle:
      "Respuesta del evaluador",
    myEquipQuestionEvaluationResponseObsEvaluatedTitle:
      "Respuesta del evaluado",
  },
  cadastro_projetos: {
    titlePage: "Registro de Proyectos",
    title: "Título",
    includeProject: "Incluir Proyecto",
    titleAdvancedFilter: "Filtros de búsqueda avanzados",
    advancedFilter: "Filtro avanzado",
    titleILeader: "Listar proyectos en los que soy líder",
    ILeader: "Soy líder",
    titleIMember: "Listar proyectos en los que soy miembro",
    IMember: "Soy miembro",
    titleMyDeport: "Listar proyectos de mi departamento",
    myDeport: "Mi departamento",
    valueNotFound: "No encontrado",
    labelSituation: "Situación",
    labelCreatorProject: "Creador del Proyecto",
    labelLeader: "Líder",
    labelCode: "Código",
    labelOpeningDate: "Fecha de apertura",
    labelExpectedDate: "Fecha prevista",
    labelDriver: "Conductor",
    labelDescription: "Descripción",
    labelExpansion: "Expansión",
    labelArea: "Área",
    labelOperation: "Operación",
    labelLoss: "Pérdida",
    labelLossType: "Tipo de pérdida",
    labelProjectType: "Tipo de proyecto",
    labelPillar: "Pilar",
    labelSaving: "Ahorro",
    closeButton: "CERRAR",
    modelsTitle: "Modelos",
    membersTitle: "Miembros",
    attachmentsTitle: "Anexos",
    attach: "Adjunto",
    wait: "Espera ...",
    dateHistory: "Fecha del historial",
    mat: "Matrícula",
    fromSituation: "De la situación",
    toSituation: "A la situación",
    observation: "Observación",
    titleJustify: "Justificaciones definidas en el proyecto",
    subtitle:
      "A continuación, las justificaciones que se definieron en el proyecto",
    descriptionLabel: "Descripción",
    delayFactorLabel: "Factor de retraso",
    actionDescriptionLabel: "Descripción de la acción",
    executedActionLabel: "Acción ejecutada",
    newExpectedDateLabel: "Nueva fecha prevista",
    justificationDateLabel: "Fecha de justificación",
    plant: "Planta",
    leaderProject: "Líder del proyecto",
    descriptionChecklist: "Descripción de la lista de verificación",
    matEvaluator: "Matrícula del evaluador",
    labelIDMatrixDQA: "ID Matriz D / QA",
    situation: "Situación",
    descriptionProject: "Descripción del proyecto",
    dateAvailable: "Fecha de evaluación",
    overvallGrade: "Nota general",
    responseChecklist: "Respuestas de la lista de verificación",
    titleChecklist: "Título:",
    description: "Descripción:",
    response: "Respuesta:",
    comment: "Comentario:",
    approved: "Aprobado",
    reproved: "Reprobado",
    pending: "Pendiente",
    open: "Abierto",
    fi: "Validación FI",
    cd: "Validación CD",
    canceled: "Cancelado",
    justify: "Justificar",
    seeJustify: "Ver justificación",
    seeChecklist: "Ver listas de verificación",
    seeValuesCD: "Valores del CD",
    changeMembers: "Cambiar miembro(s)",
    returnCD: "Devolver Validación CD",
    closed: "Cerrado",
    clearFilter: "Limpiar filtro",
    titleCard: "Haz clic para limpiar el filtro",
    days: "día(s)",
    leader: "Líder",
    late: "Retrasado por",
    members: "Miembros",
    code: "Código",
    area: "Área",
    openListProject: "Apertura",
    previst: "Previsto",
    newDatePrevist: "Nueva Fecha Prevista",
    labelOpen: "Apertura",
    labelNewDatePrevist: "Nueva Fecha Prevista",
    viewHist: "Ver historial",
    btnOptions: "Opciones",
    alterStatus: "Cambio de estado",
    descriptionUpdStatus:
      "Describe (opcional) por qué estás cambiando al estado",
    observationPlaceholder: "Una observación a hacer",
    attachFI: "Anexo FI (obligatorio)",
    attachCD: "Anexo CD",
    attachLabel: "Anexo",
    labelDateClosedLabel: "Fecha de Cierre",
    placeholderDateClosed: "Define la fecha de cierre",
    btnSend: "ENVIAR",
    errorAttachFI: "Para enviar la Validación FI, es obligatorio el anexo",
    errorDateClosed: "Se debe informar la nueva fecha de cierre",
    errorNoLimit: "Sin límite",
    formDateSaving: "Formulario datos Saving",
    valBenefic: "Valor del beneficio",
    valCust: "Valor del costo",
    valBC: "Valor BC",
    valSaving: "Valor Saving",
    typeSaving: "Tipo de Saving",
    typeBC: "Tipo BC",
    fieldRequired: "Campo obligatorio",
    register: "REGISTRAR",
    chooseChecklist: "Elige la lista de verificación",
    chooseChecklistPlaceholder: "Elige una lista de verificación",
    responseChecklistInfo: "RESPONDER",
    commentPlaceholder: "Comenta tu respuesta",
    optionError: "Elige una opción",
    commentError: "No debe estar en blanco",
    titleFilterAdvanced: "FILTRO APLICADO",
    titleCancelMember: "Cancelar la inclusión del miembro",
    titleRemoveMember: "Eliminar miembro del proyecto",
    titleAlterMember: "Cambio de miembros del proyecto",
    projectMembers: "Miembros del proyecto",
    newMember: "Nuevo miembro",
    membersParticipants: "Miembros participantes",
    save: "GUARDAR",
    addMember: "AÑADIR MIEMBRO",
    collaborator: "Colaborador",
    cancel: "Cancelar la inclusión del miembro",
    chooseCollaborator: "Por favor, elige al colaborador",
    defineParticipation: "Falta definir {0} % de participación",
    removeMember: "Eliminar miembro del proyecto",
    changeProjectMembers: "Cambio de miembros del proyecto",
    participantMembers: "Miembros participantes",
    error100Percent:
      "Se ha alcanzado el 100% de participación. Imposible incluir miembros",
    errorBefore100Percent1: "Falta definir ",
    errorBefore100Percent2: " % de participación",
    pilar: "Pilar",
    sector: "Sector",
    lateFilterAdvanced: "Retrasados",
    typeOfProject: "Tipo de proyecto",
    applyFilter: "Aplicar filtro",
    errorProjectCode: "Es necesario elegir al menos 1 código de proyecto",
    errorPlant: "Es necesario elegir al menos 1 planta",
    errorPilar: "Es necesario elegir al menos 1 pilar",
    errorSector: "Es necesario elegir al menos 1 sector",
    errorLate: "Es necesario elegir al menos 1 sector",
    errorTypeOfProject: "Es necesario elegir al menos 1 tipo de proyecto",
    descricao: "Descripción",
    expansao: "¿Es un proyecto de expansión?",
    placeholderDescription: "Escribe una descripción",
    errorDateOpen: "Formato DD/MM/AAAA",
    errorDirection: "Elige un director",
    errorDescription1: "No debe estar en blanco (máximo ",
    errorDescription2: " caracteres)",
    errorExpansion: "Elige una opción",
    errorArea: "Elige un área",
    errorModel: "Elige al menos un modelo",
    errorOperation: "Elige una operación",
    errorLose: "Elige una pérdida",
    errorTypeLose: "Elige una opción",
    errorTypeProject: "Elige una opción",
    errorMatriz: "No debe estar en blanco",
    errorSaving: "Escribe en formato 0,00",
    errorLider: "Elige una opción",
    errorMemberNull: "🤔 Rellena el miembro",
    errorPercentNot100: "El total de porcentajes es inferior al 100%",
    errorPercentMore100: "El total de porcentajes es superior al 100%",
    errorNotLeader: "🤔 Rellena el líder del proyecto.",
    errorMemberLast: "🤔 Rellena el último miembro incluido.",
    errorTotal100: "Se ha alcanzado el 100% de participación",
    btnClose: "CERRAR",
    btnSave: "Guardar",
    titleLaunchProject: "Lanzamiento de proyecto",
    labelDateOpen: "Fecha de apertura",
    labelDirection: "Director",
    labelExpansionProject: "¿Es un proyecto de expansión?",
    labelModel: "Modelos",
    labelLose: "Pérdida",
    labelTypeLose: "Tipo de pérdida",
    labelTypeOfProject: "Tipo de proyecto",
    labelPilar: "Pilar",
    labelMatriz: "ID Matriz D / QA",
    labelSavingAdd: "Saving previsto",
    labelLeaderAdd: "Líder del proyecto",
    labelMember: "Miembro",
    labelPercent: "Participación del miembro",
    labelAnexo: "Anexo(s)",
    labelMemberNumber: "Miembro",
    labelPercentMember: "Participación del miembro",
    labelSavingInfo:
      "Atención, introduce un valor de saving previsto, si no lo sabes, escribe el número 0,00",
    btnNewMember: "Nuevo miembro",
    optYes: "Sí",
    optNo: "No",
    optCronic: "Crónico",
    optExpordic: "Esporádico",
    titleRemoveMemberAdd: "Eliminar miembro",
    titleFormAdd: "Formulario de justificación",
    descriptionAddJustify: "Describe los detalles del retraso del proyecto",
    descricao_da_acao: "Descripción de la solución",
    fator_atraso: "Factor de retraso",
    acao: "Acción",
    registrar: "Registrar",
    placeholderDescriptionAddJustify: "Describe aquí la razón del retraso",
    placeholderDescriptionAction:
      "Describe la acción para evitar la recurrencia de este factor de retraso",
    errorDescription: "Mínimo de 3 caracteres",
    errorDescriptionAction: "Mínimo de 3 caracteres",
    errorFactor: "Elige al menos un factor",
    errorAction: "Elige una acción",
    pointTitle: "Puntuación alcanzada",
    considerAcceptCd:
      "¿Consideras aceptable avanzar el proyecto a VALIDACIÓN CD?",
    btnResponseAfter: "RESPONDER DESPUÉS",
    btnReproved: "REPROBADO",
    btnApproved: "APROBADO",
  },
  cartao_seguranca: {
    sector: "Sector",
    errorSector: "* Deve ser preenchido",
    labelSector: "Sector",
    errorOpenDate: "* Debe ser completado",
    errorTypeCard: "* Debe elegirse uno",
    errorCategory: "* Indica la categoría",
    errorDescriptionCard:
      "* No debe estar vacío y no debe superar los 230 caracteres",
    errorMat: "* Elige una matrícula",
    errorOperation: "* Indica la operación o N/A",
    errorTurn: "* Indica el turno",
    errorArea: "* Indica un área",
    errorModel: "* Indica el modelo de la tarjeta",
    labelTypeCard: "Elige el tipo de tarjeta",
    nameLeader: "NOMBRE LÍDER",
    mat: "MATRÍCULA",
    labelArea: "ÁREA",
    labelModel: "MODELO",
    labelCategory: "CATEGORÍA",
    labelOperation: "OPERACIÓN",
    labelTurn: "TURNO",
    labelDateOpen: "FECHA DE APERTURA",
    labelDescription: "DESCRIPCIÓN",
    placeholderDescription: "Describe el problema...",
    labelAttachment: "ANEXO(S)",
    textBtnSave: "GUARDAR",
    titleFormJustify: "Justificación / Observación",
    labelJustify: "* Justificación",
    placeholderJustify: "Escribe tu justificación...",
    errorMinChar: "Mínimo de 3 caracteres",
    textBtnReprove: "RECHAZAR",
    textBtnApprove: "APROBAR",
    titleMoveToAnalitycs: "Mover para análisis",
    subtitleMoveToAnalitycs:
      "Completa los campos del formulario para determinar la causa raíz y la acción prevista.",
    labelRootCause: "Causa raíz",
    placeholderRootCause: "Escribe la causa raíz del problema...",
    labelAction: "Acción prevista",
    placeholderAction: "Escribe la acción prevista para resolver el problema",
    labelDatePrev: "Fecha prevista",
    labelResponsible: "Responsable",
    titleJustifyAndObservation: "Justificación / Observación",
    subtitleJustifyAndObservation:
      "Decide si la tarjeta debe pasar al estado Abierto o ser Cancelada.",
    textBtnCancel: "CANCELAR",
    textBtnValidate: "VALIDAR",
    attachment: "Anexo",
    titleCompleteEnvironment: "VALIDACIÓN DEL MEDIO AMBIENTE",
    titleCompleteHealth: "VALIDACIÓN DE LA SALUD",
    titleCompleteSafety: "VALIDACIÓN DE LA SEGURIDAD",
    titleCompleteValidation: "VALIDACIÓN DE",
    card: "TARJETA",
    date: "FECHA",
    identify: "IDENTIFICACIÓN",
    name: "NOMBRE",
    leaderName: "NOMBRE LÍDER",
    areaAndTurn: "ÁREA/TURNO",
    descriptionProblem: "DESCRIPCIÓN DEL PROBLEMA",
    local: "LOCAL",
    model: "MODELO",
    activity: "ACTIVIDAD",
    operation: "OPERACIÓN",
    description: "DESCRIPCIÓN",
    justifyAndObservation: "JUSTIFICACIÓN / OBSERVACIÓN",
    planType: "PLAN DE ACCIÓN (SOLUCIÓN)",
    rootCause: "CAUSA RAÍZ",
    actions: "ACCIONES PREVISTAS",
    dateInclude: "FECHA DE INCLUSIÓN",
    datePreview: "FECHA PREVISTA",
    analyticsAndEfficiency: "ANÁLISIS DE LA EFICACIA",
    statusInclude: "Incluido (A validar)",
    statusCancel: "Cancelado",
    statusOpen: "Abierto",
    statusAnalisys: "En análisis",
    statusClosed: "Cerrado",
    statusOpenTitle: "Tarjeta con estado abierto",
    statusIncludeTitle: "Tarjeta con estado incluido a validar",
    statusCancelTitle: "Tarjeta con estado cancelado",
    statusAnalisysTitle: "Tarjeta con estado en análisis",
    statusClosedTitle: "Tarjeta con estado cerrado",
    searchTitleLeader: "Búsqueda por líder",
    searchTitleCode: "Búsqueda por código",
    searchTitleTurn: "Búsqueda por turno",
    searchTitleArea: "Búsqueda por área",
    titlePage: "Tarjeta de Seguridad",
    textCardTypeSecurity: "Seguridad",
    textCardTypeHealth: "Salud",
    textCardTypeEnvironmental: "Ambiental",
    titleFabAdd: "Agregar nueva tarjeta",
    titleButtonCleanFilter: "Limpiar filtro de estado aplicado",
    textButtonCleanFilter: "Limpiar filtro",
    textOptionViewCard: "Ver tarjeta",
    textOptionValidateReject: "Validar / Rechazar",
    textOptionSendAnalysis: "Enviar para análisis",
    textOptionJustify: "Justificar",
    textOptions: "Opciones",
    textType: "Tipo",
    textLeader: "Líder",
    textDescription: "Descripción",
    textOpenDate: "Fecha de apertura",
    textFabAdd: "AGREGAR",
    view: "Ver",
    front: "Frente",
    back: "Reverso",
  },
  helpdesk: {
    titleMoreOptions: "Haz clic para ver más opciones.",
    titleInfoRequester: "Todos los tickets abiertos por este solicitante",
    placeholderGetRequestTicket: "Escribe el nombre",
    labelFilterAgent: "Soy Agente",
    titleFilterAgent: "Muestra solo tickets donde yo soy el agente",
    labelFilterRequester: "Solicitantes",
    titleFilterRequester:
      "Determina de qué solicitante quieres ver los tickets",
    labelMaintenanceSubjectSuperAgent: "MANTENIMIENTO DE ASUNTO",
    labelMaintenanceStatusSuperAgent: "MANTENIMIENTO DE ESTADO",
    labelMaintenanceCriticalitySuperAgent: "MANTENIMIENTO DE CRITICIDAD",
    labelBtnNewTicket: "NUEVO TICKET",
    titleSubnects: "Asuntos",
    titleStatus: "Estado",
    titleAnimationNoData: "No hay tickets en el filtro informado",
    titleComponentPagination: "Haz clic para alternar entre las páginas",
    titleFieldId: "ID",
    titleFieldPlant: "Planta",
    titleFieldRequester: "Solicitante",
    titleFieldCriticality: "Criticidad",
    titleFieldAgent: "Agente",
    titleFieldStatus: "Estado",
    titleFieldTitle: "Título",
    titleFieldLastInteraction: "Interacción / Plazo",
    titleBtnOrderTicket: "Haz clic para ordenar.",
    titleTotalTickets: "Total de tickets creados",
    titleBtnNewTicket: "Haz clic para abrir un nuevo ticket",
    titleTicket: "Haz clic para ver detalles del ticket",
    titleTicketNumber: "Ticket N°",
    titleTicketNotAssigned: "No asignado",
    titleSubject: "Asunto",
    titleInteraction: "Interacción",
    titlePlant: "Planta",
    titleAgent: "Agente",
    titleIndicatorLate: "Este ticket está atrasado (el plazo ha expirado)",
    labelIndicatorLate: "Atrasado",
    titleBtnDelete: "Haz clic para eliminar el estado",
    titleBtnMove: "Haz clic para definir a qué estado puede llevar este",
    title: "Mantenimiento de estado",
    subtitle:
      "Crear, modificar, eliminar y activar/desactivar estados utilizados en el helpdesk.",
    headerName: "Estado",
    headerAuthorized: "Autorizado para interactuar",
    headerColor: "Color",
    headerActive: "Situación",
    headerDelete: "Eliminar",
    headerMove: "De / A",
    labelActive: "Activo",
    labelInactive: "Inactivo",
    labelAgent: "Agente",
    labelRequester: "Solicitante",
    titleBtnAdd: "Incluir",
    titleFromTo: "Definición de destino Estado",
    subtitleFromTo:
      "Elige los destinos a los que el estado que está a la izquierda puede seguir cuando tenga que ser movido.",
    canInteract: " puede interactuar en este estado.",
    labelBtn: "GUARDAR",
    fromStatus: "HELPDESK EN ESTE ESTADO",
    toStatus: "PUEDE IR A ESTOS ESTADOS",
    titleDel: "Eliminación de estado",
    subtitleDel: "¿Realmente quieres eliminar el estado?",
    titleAddStatus: "Agregar Estado",
    labelName: "Nombre del estado",
    labelAuthorized: "Autorizado para interactuar",
    labelSituation: "Situación",
    labelColor: "Color",
    errorName: "* El nombre debe tener al menos 3 caracteres",
    errorAuthorized: "* Define quién podrá interactuar en este estado",
    erroSituation: "* Elige entre activar o desactivar el asunto",
    errorColor: "* Elige un color para el estado",
    placeholderName: "Nombre del estado",
    placeholderAuthorized: "Autorizado para interactuar",
    placeholderSituation: "Situación",
    placeholderColor: "Color",
    labelActivated: "Activado",
    labelDeactivated: "Desactivado",
    titleBtnGoBack: "Volver a la pantalla de tickets",
    labelBtnGoBack: "VOLVER",
    labelFilterAdvancedTicket: "Ticket",
    labelFilterAdvancedSubject: "Asunto",
    labelFilterAdvancedAgent: "Agente",
    labelFilterAdvancedStatus: "Estado",
    labelFilterAdvancedDelayed: "Retrasado",
    labelFilterAdvancedApplicant: "Solicitante",
    labelFilterAdvancedSeverity: "Criticidad",
    labelFilterAdvancedExclude: "¿Realmente quieres excluir el filtro?",
    titleFilterAdvancedMyFilters: "Mis filtros guardados",
    labelNoAccessToLocalStorage: "Sin acceso a la función de grabación local",
    titleFilterAdvancedChooseFilter: "Elige el filtro a aplicar",
    titleFilterAdvancedNoFilterSelected: "Ningún filtro seleccionado",
    labelFilterAdvancedSaveFilter: "Guardar Filtro",
    labelFilterAdvancedQuestionSaveFilter: "Define un nombre para el filtro",
    errorFilterAdvancedTicket: "* Por favor, elige al menos 1",
    errorFilterAdvancedSubject: "* Por favor, elige al menos 1",
    errorFilterAdvancedAgent: "* Por favor, elige al menos 1 agente",
    errorFilterAdvancedStatus: "* Por favor, elige al menos 1 estado",
    errorFilterAdvancedApplicant: "* Por favor, elige al menos 1 solicitante",
    errorFilterAdvancedSeverity: "* Por favor, elige al menos 1 criticidad",
    titleFilterAdvancedDeleteFilter: "¿Realmente quieres eliminar el filtro?",
    titleDetails: "Detalles",
    titleInteractions: "Interacciones",
    labelStatus: "Estado",
    labelTicketNumber: "Ticket Número",
    labelCreated: "Creado en",
    labelLastInteraction: "Última interacción",
    labelDeadline: "Fecha límite",
    labelSubject: "Asunto",
    titleCriticality: "Nivel de criticidad",
    titleAlterCriticality: "Cambiar criticidad",
    labelNoAgent: "No asignado",
    titleRequestersInCopy: "Colaboradores en copia",
    labelBtnEdit: "Editar",
    labelAgentResponsible: "Agente Responsable",
    titleClickHereToStartAnInteraction:
      "Haz clic aquí para iniciar una interacción",
    titleClickToIncrement:
      "Haz clic para incrementar más detalles sin actualizar el estado",
    labelClose: "CERRAR",
    labelIncrement: "INCREMENTAR",
    labelAttachment: "ANEXO",
    placeholderDescription: "Escribe la descripción",
    errorDescription: "Es necesario llenar la descripción del incidente",
    placeholderStatusTo: "Cambiar el estado del ticket...",
    errorStatusTo: "Elige el estado que recibirá la solicitud",
    labelSendEmail: "Enviar correo electrónico de notificación",
    titleAlterAgent: "Cambiar agente responsable",
    titleMainCritical: "Mantenimiento de criticidad",
    subtitleMainCritical:
      "Crear, cambiar, eliminar y activar/desactivar criticidades usadas en el helpdesk.",
    headerNameCritical: "Criticidad",
    headerIcon: "Icono",
    labelDeactive: "Inactivo",
    titleBtnAddActive: "Activo",
    titleAddCritical: "Agregar Criticidad",
    titleAddCriticalUpd: "Actualizar criticidad",
    labelNameAddCritical: "Nombre de la criticidad",
    labelIcon: "Icono",
    errorNameAddCritical: "* El nombre debe tener al menos 3 caracteres",
    errorIcon: "* El icono debe tener al menos 1 carácter",
    errorSituationAddCritical:
      "* Elige entre activar o desactivar la criticidad",
    placeholderNameAddCritical: "Nombre de la criticidad",
    placeholderIcon: "Icono",
    placeholderSituationAddCritical: "Situación",
    labelDeactiveAddCritical: "Desactivado",
    titleSubjectMain: "Mantenimiento de asuntos",
    subtitleSubject:
      "Crear, cambiar, eliminar y activar/desactivar asuntos usados en el helpdesk.",
    headerNameSubject: "Asunto",
    headerPraz: "Plazo (días)",
    titleSubjectBtnAdd: "Agregar",
    titleSubjectDel: "Eliminación de asunto",
    subtitleSubjectDel: "¿Realmente quieres eliminar el asunto?",
    titleSubjectAdd: "Agregar Asunto",
    titleSubjectAddUpd: "Actualizar asunto",
    labelNameSubjectAdd: "Nombre del asunto",
    labelPraz: "Plazo (en días)",
    errorNameSubjectAdd: "* El nombre debe tener al menos 3 caracteres",
    errorPraz: "* El plazo debe ser de al menos 1 día",
    errorSituationSubjectAdd: "* Elige entre activar o desactivar el asunto",
    placeholderNameSubjectAdd: "Nombre del asunto",
    placeholderPraz: "Plazo (en días)",
    placeholderSituationSubjectAdd: "Situación",
    titleAlterEnvolved: "Cambiar lista de colaboradores en copia",
    labelInvolved: "Colaboradores en Copia",
    errorInvolved: "* Elige al menos 1 colaborador",
    titleAlterCritical: "Cambiar criticidad de la llamada",
    labelCriticality: "Criticidad de la llamada",
    errorCriticality: "* Elige una criticidad válida",
    labelTitleAdd: "Título",
    labelFiles: "Archivos",
    labelBtnInteraction: "INTERACTUAR",
    labelColabCopy: "Colaboradores en Copia",
    placeholderTitle: "Describe un título para el problema",
    errorTitle: "* Mínimo de 3 caracteres",
    errorSubject: "* Elige un asunto",
    errorMat: "* Elige la matrícula del solicitante",
    titleNewTicket: "Apertura de Ticket",
    advancedFilter: "Filtro avanzado",
  },
  rnci_ncp: {
    titleCancelStatus: "¿Realmente desea cancelar este estado?",
    subtitleCancelStatus: "El RNCI se eliminará y ya no se podrá recuperar",
    subtitleCancelStatus2: "El RNCI volverá al estado ABIERTO",
    subtitleCancelStatus3: "El RNCI volverá al estado SUBIDA DE LÍNEA",
    tituloBuscaAvancada: "Búsqueda avanzada",
    descricaoBuscaAvancada:
      "Elija entre las opciones y realice una búsqueda avanzada.",
    wait_generate_pdf: "Espere, generando PDF...",
    rotuloBtnFiltro: "BÚSQUEDA AVANZADA",
    tituloSemDados: "No se encontraron datos",
    tituloSemDadosRetorno: "Intentando cargar datos necesarios...",
    titulo: "RCNI",
    tituloPaginacao: "Haga clic para alternar entre las páginas",
    codigo: "Código",
    anomalia: "Anomalía: ",
    data: "Fecha de creación: ",
    area: "Área: ",
    rotuloBotaoAdd: "Nuevo RNCI",
    tituloBotaoAdd: "Crear nuevo RNCI",
    tituloForm: "ALERTA DE CALIDAD INTERNA",
    formAuditor: "AUDITOR",
    formConjunto: "CONJUNTO",
    formModelo: "MODELO",
    formTurno: "TURNO",
    formQtdKO: "CANTIDAD KO",
    formQtdSuspeita: "CANTIDAD SOSPECHOSA",
    formOperacao: "OPERACIÓN",
    formRNCI: "RNCI N°",
    formPN: "PN",
    formRastreabilidade: "RASTREABILIDAD",
    formSubidaDeLinha: "SUBIDA DE LÍNEA",
    formFalhaForaControle: "FALLA FUERA DE CONTROL",
    formTwttp: "TWTTP",
    formData: "FECHA",
    formHora: "HORA",
    formErrorAuditor: "Informe al menos un colaborador",
    formErrorConjunto: "Elija al menos un área",
    formErrorModelo: "Elija al menos un modelo",
    formErrorTurno: "Elija al menos un turno",
    formErrorQtdKO: "Mínimo 0",
    formErrorQtdSuspeita: "Mínimo 0",
    formErrorOperacao: "Operación",
    formErrorRNCI: "Número RNCI",
    formErrorPN: "Informe el PN",
    formErrorRastreabilidade: "Informe la rastreabilidad",
    formErrorSubidaDeLinha: "¿Subió de línea?",
    formErrorFalhaForaControle: "¿Fue una falla fuera de control?",
    formErrorTwttp: "Obligatorio",
    formErrorData: "Informe la fecha",
    formErrorHora: "Informe la hora",
    formErrorAnomalia: "Informe la anomalía",
    formAuditorPlaceholder: "Informe el auditor",
    formConjuntoPlaceholder: "Área",
    formModeloPlaceholder: "Modelo",
    formTurnoPlaceholder: "Turno",
    formQtdKOPlaceholder: "EJ: 1",
    formQtdSuspeitaPlaceholder: "EJ: 1",
    formOperacaoPlaceholder: "Operación",
    formRNCIPlaceholder: "Número RNCI",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Trazabilidad",
    formSubidaDeLinhaPlaceholder: "Subida de línea",
    formFalhaForaControlePlaceholder: "Falla fuera de control",
    formTwttpPlaceholder: "Twttp",
    formDataPlaceholder: "Fecha",
    formHoraPlaceholder: "Hora",
    formAnomaliaPlaceholder: "Describe la anomalía...",
    formAnomalia: "ANOMALÍA",
    formSim: "SÍ",
    formNao: "NO",
    twttpGerou: "Generó el problema",
    twttpDeixou: "Dejó pasar",
    twttpNaoAplicavel: "No aplicable",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "IDENTIFICACIÓN DE RACK O CAJA",
    formOque: "¿QUÉ?",
    formQuando: "¿CUÁNDO?",
    formOnde: "¿DÓNDE?",
    formQuem: "¿QUIÉN?",
    formQual: "¿CUÁL?",
    formComo: "¿CÓMO?",
    formErrorOque: "Obligatorio",
    formErrorQuando: "Obligatorio",
    formErrorOnde: "Obligatorio",
    formErrorQuem: "Obligatorio",
    formErrorQual: "Obligatorio",
    formErrorComo: "Obligatorio",
    formOquePlaceholder: "¿Qué pasó?",
    formQuandoPlaceholder: "¿Cuándo ocurrió?",
    formOndePlaceholder: "¿Dónde ocurrió?",
    formQuemPlaceholder: "¿Quién identificó?",
    formQualPlaceholder: "¿Cuál es el nivel del problema?",
    formComoPlaceholder: "¿Cómo ocurrió?",
    formFenomenoRevisado: "FENÓMENO REVISADO",
    formErrorFenomenoRevisado: "Obligatorio",
    formFenomenoRevisadoPlaceholder: "Describa el fenómeno.",
    formCausaRaiz: "POSIBLE CAUSA RAÍZ",
    formCausaRaizPlaceholder: "Describa aquí...",
    formErrorCausaRaiz: "Obligatorio",
    tituloBotaoVoltar: "Haga clic para volver",
    rotuloBotaoFormSalvar: "GUARDAR",
    tituloBotaoRemoverImagem: "Haga clic para eliminar la imagen",
    formRotuloImagem: "Inserte la imagen aquí",
    formErrorImagemOK: "Imagen obligatoria",
    formErrorImagemKO: "Imagen obligatoria",
    formErrorImagemIdent: "Error al enviar la imagen",
    situacaoQualidade: "CALIDAD",
    situacaoSubida: "SUBIDA DE LÍNEA",
    situacaoAberto: "ABIERTO",
    opcaoDetalhes: "Detalles",
    opcaoSubida: "Subida de línea",
    opcaoDownloadPdf: "Descargar en PDF",
    opcaoQualidade: "Mover a calidad",
    rotuloBotaoVerOpcoes: "OPCIONES",
    tituloBotaoVerOpcoes: "Muestra las opciones de manipulación del RNCI",
    tituloFormSubida: "Formulario de subida de línea",
    formPlaceholderTempoGasto: "Informe el tiempo gastado",
    formTempoGasto: "Tiempo gastado",
    formPlaceholderMod: "Informe la mano de obra directa",
    formMod: "MOD",
    formPlaceholderMoi: "Informe la mano de obra indirecta",
    formMoi: "MOI",
    formPlaceholderOK: "Informe la cantidad OK",
    formOK: "Cantidad OK",
    formPlaceholderKO: "Informe la cantidad KO",
    formKO: "Cantidad KO",
    formPlaceholderObservacao: "Describa una observación",
    formObservacao: "Observación",
    formErrorTempoGasto: "* Por favor, informe el tiempo gastado HH:MM",
    formErrorMod: "* Valor mínimo 0",
    formErrorMoi: "* Valor mínimo 0",
    formErrorOK: "* Valor mínimo 0",
    formErrorKO: "* Valor mínimo 0",
    formErrorObservacao: "* Describa una observación mínimo 3 caracteres",
    tituloFormQualidade: "Formulario de Calidad",
    formPlaceholderCodigoProjeto: "Informe el código del proyecto",
    formCodigoProjeto: "Código del Proyecto",
    formPlaceholderProjeto: "¿Este RNCI tiene vínculo con algún proyecto?",
    formProjeto: "¿Este RNCI tiene vínculo con algún proyecto?",
    formPlaceholderAcoes: "Describa las acciones",
    formAcoes: "Acción",
    formErrorProjeto: "* Elija Sí o No",
    formErrorCodigoProjeto: "* Informe el código del proyecto",
    formErrorAcoes: "* Describa la acción mínimo 3 caracteres",
    abaForm1: "RNCI",
    abaSubida: "SUBIDA DE LÍNEA",
    abaQualidade: "CALIDAD",
    rotuloBotaoVoltar: "VOLVER",
    subidaLinhaMod: "Mano de obra Directa",
    subidaLinhaMoi: "Mano de obra Indirecta",
    subidaLinhaTempo: "Tiempo de trabajo",
    subidaLinhaOK: "Cantidad de piezas OK",
    subidaLinhaKO: "Cantidad de piezas KO",
    subidaLinhaObs: "Observación de la mano de obra",
    qualidadeCodProjeto: "Número del Proyecto",
    qualidadeObs: "Acciones",
    filtroNomeStatus: "ESTADO",
    filtroNomeStatusErro: "* Elija al menos uno",
    filtroNomeMatricula: "MATRÍCULA",
    filtroNomeMatriculaErro: "* Elija al menos uno",
    filtroNomeArea: "ÁREA",
    filtroNomeAreaErro: "* Elija al menos una",
    filtroNomeTurno: "TURNO",
    filtroNomeTurnoErro: "* Elija al menos uno",
    filtroNomeRnci: "CÓDIGO RNCI",
    filtroNomeRnciErro: "* Elija al menos uno",
    filtroNomeOperacao: "OPERACIÓN",
    filtroNomeOperacaoErro: "* Elija al menos una",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Elija al menos uno",
    opcaoExcluirRnci: "Eliminar RNCI",
    opcaoRetornarAbertoRnci: "Volver a abierto",
    opcaoRetornarSubidaDeLinhaRnci: "Volver a subida de línea",
  },
  manutencao_variaveis: {
    titlePage: "Mantenimiento de variables",
    tabOne: "Colaboradores",
    tabTwo: "Variables",
    tabThree: "Variables Globales",
    labelBtnAddVar: "Añadir",
    labelBtnEditVar: "Editar",
    labelBtnDelVar: "Eliminar",
    labelBtnAddVarGlobal: "Añadir",
    titleEditVarGlobal:
      "Edita la variable tanto en su descripción como en su valor",
    labelBtnEditVarGlobal: "Editar",
    titleDelVarGlobal: "Elimina esta variable global (Solo autorizado)",
    labelBtnDelVarGlobal: "Eliminar",
    labelValueActualy: "Valor actual:",
    labelVarName: "Nombre de la Variable",
    placeholderVarName: "Escribe el nombre de la variable",
    labelVarDescription: "Descripción",
    placeholderVarDescription: "Escribe la descripción de la variable",
    labelVarValue: "Valor",
    placeholderVarValue: "Escribe el valor inicial de la variable",
    errorVarDescription: "* Mínimo de 3 caracteres",
    errorVarName: "* Mínimo de 3 caracteres",
    errorVarValue: "* Campo Obligatorio",
    titleNewVar: "Registro de nueva variable global",
    descriptionNewVar:
      "Rellena los valores a continuación para registrar una nueva variable global en el sistema.",
    titleFormAddVarUser: "MANTENIMIENTO DE VARIABLES DE USUARIOS",
    labelVarUserName: "Variable",
    placeholderVarUserName: "Escribe el nombre de la variable",
    errorVarUserName: "* Mínimo de 3 caracteres",
    labelVarUserDescription: "Descripción",
    placeholderVarUserDescription:
      "Una descripción de lo que se pretende atender",
    errorVarUserDescription: "* Mínimo 3 caracteres",
    labelBtnVarUserSend: "ENVIAR",
    titleFormDelVarGlobal: "¿Realmente deseas eliminar la variable?",
    descriptionFormDelVarGlobal1: "Si realmente deseas eliminar la variable",
    descriptionFormDelVarGlobal2: " haz clic en SÍ.",
    tabVarGlobal1: "Editar valor",
    tabVarGlobal2: "Editar descripción",
    labelEditVarGlobalValue: "Valor",
    placeholderEditVarGlobalValue: "Escribe el nuevo valor de la variable",
    errorEditVarGlobalValue: "* Campo obligatorio",
    descriptionEditVarGlobal:
      "Escribe a continuación el valor que debe ser asignado a la variable",
    labelEditVarGlobalDescription: "Descripción",
    placeholderEditVarGlobalDescription:
      "Escribe la descripción de la variable",
    errorEditVarGlobalDescription: "* Mínimo de 3 caracteres",
    descriptionEditVarGlobalDesc:
      "Escribe a continuación la descripción a ser asignada a la variable",
    titleListVarColab: "Lista de variables del usuario",
  },
  absenteismo: {
    title: "Absentismo",
    labelDateFrom: "Fecha desde",
    labelDateTo: "Fecha hasta",
    errorDateFormat: "Formato inválido",
    labelBtnSearch: "Buscar",
    labelViewAll: "Ver todos",
  },
  twttp: {
    labelSituationViewDetails: "Situación",
    labelSectorViewDetails: "Sector",
    labelInterviewedViewDetails: "Entrevistado",
    labelInterviewerViewDetails: "Entrevistador",
    labelInterviewDateViewDetails: "Fecha de la entrevista",
    labelPrevDateViewDetails: "Fecha prevista",
    labelActionDateViewDetails: "Fecha límite para la acción",
    labelTypeViewDetails: "Tipo de TWTTP",
    labelProblemViewDetails: "Problema",
    labelProcessViewDetails: "Proceso / Máquina",
    labelModelViewDetails: "Modelo(s)",
    labelErrorFeatureViewDetails: "Característica del error",
    labelErrorDetailViewDetails: "Detalle sobre el error",
    labelProblemOriginViewDetails: "Origen del problema",
    labelFactorViewDetails: "Factor",
    labelShiftViewDetails: "Turno",
    labelRejectionJustifyViewDetails: "Justificación de rechazo",
    labelTimeViewDetails: "Tiempo necesario para el análisis",
    labelAttachmentViewDetails: "Anexo",
    labelAttachmentValidationViewDetails: "Anexo(s) Validación",
    labelCodeFilterAdvanced: "Código",
    labelTypeFilterAdvanced: "Tipo",
    labelOriginFilterAdvanced: "Origen",
    labelInterviewerFilterAdvanced: "Entrevistador",
    labelInterviewedFilterAdvanced: "Entrevistado",
    labelSectorFilterAdvanced: "Sector",
    labelAreaFilterAdvanced: "Área",
    labelLateFilterAdvanced: "Retrasados",
    labelColabInterviewToInterview: "Colaborador(a) entrevistado(a)",
    labelInterviewerToInterview: "Entrevistador(a)",
    errorValueRequiredToInterview: "* Valor obligatorio",
    labelJustifyToInterview: "Nota explicativa",
    placeholderJustifyToInterview: "Escribe una nota explicativa...",
    errorJustifyRequiredToInterview: "* Mínimo de 3 caracteres",
    labelActionToInterview: "Acción a tomar",
    descriptionColabToInterview: "Colaborador(a) a ser entrevistado(a)",
    descriptionToInterview: "Entrevistador(a)",
    titleTwttpResponseQuestion: "TWTTP ID -",
    titleAvatarToInterview: "Entrevistado",
    titleAvatarToInterviewer: "Entrevistador (evaluador)",
    titleTwttpResponseDateOpen: "Fecha de la entrevista",
    labelTwttpResponseQuestion: "Pregunta",
    labelTwttpResponse: "Respuesta",
    labelTwttpResponseNote: "Nota explicativa",
    labelJustifyFormMove: "* Justificación",
    placeholderJustifyFOrmMove: "Escribe una justificación...",
    errorJustifyRequiredFormMove: "* Mínimo 3 caracteres",
    titleReprovedFormMove: "Reprobar",
    subtitleReprovedFormMove:
      "Indica el motivo por el cual deseas reprobar este TWTTP.",
    titleConfirmFormMove1: "¿Realmente deseas actualizar a",
    subtitleConfirmFormMove:
      "Este cambio no se puede deshacer. Si confirmas, el estado del twttp cambiará a",
    errorAttachmentRequiredFormMoveValidation: "Anexo obligatorio",
    titleFormMoveValidation: "Mover TWTTP para Validación",
    subtitleFormMoveValidation:
      "Para que este TWTTP pueda ser movido a la validación, se debe incluir un anexo en el campo de abajo",
    errorUnknown: "Error desconocido",
    labelOpenDateFormAdd: "Fecha de apertura",
    labelTwttpTypeFormAdd: "Tipo de TWTTP",
    labelProblemFormAdd: "Problema",
    labelProcessMachineFormAdd: "Proceso / Máquina",
    labelModelsFormAdd: "Modelos",
    labelSectorInterviewedFormAdd: "Sector a ser entrevistado",
    labelErrorFeatureFormAdd: "Característica del error",
    labelErrorDetailFormAdd: "Describe el error en detalle",
    labelProblemOriginFormAdd: "Origen del problema",
    labelFactorFormAdd: "Factor",
    labelShiftFormAdd: "Turno",
    labelAnalysisTeamFormAdd: "Tiempo necesario para el análisis",
    labelAttachmentFormAdd: "Anexo",
    titleTwttpFormAdd: "TWTTP",
    placeholderDetailErrorFormAdd: "Describe el error en detalle",
    errorAttachmentRequiredFormAdd: "Anexo obligatorio",
    errorOpenDateRequiredFormAdd: "Por favor, introduce la fecha",
    errorTwttpTypeRequiredFormAdd: "Por favor, introduce el tipo de TWTTP",
    errorProblemRequiredFormAdd: "Por favor, introduce el problema",
    errorAreaRequiredFormAdd: "Por favor, introduce el área",
    errorModelRequiredFormAdd: "Se debe seleccionar al menos un modelo",
    errorSectorRequiredFormAdd: "Por favor, introduce el sector",
    errorDetailErrorRequiredFormAdd:
      "Por favor, proporciona detalles del error",
    errorProblemOriginRequiredFormAdd:
      "Por favor, proporciona el origen del problema",
    errorErrorFeatureRequiredFormAdd:
      "Por favor, proporciona la característica del error",
    errorAnalysisTeamRequiredFormAdd:
      "Por favor, proporciona los equipos para el análisis",
    errorFactorRequiredFormAdd: "Por favor, rellena el factor",
    errorShiftRequiredFormAdd: "Por favor, rellena el turno",
    titleTwttpConfirm: "¿Realmente deseas cancelar este TWTTP?",
    subtitleTwttpConfirm:
      "Una vez realizada esta modificación, no será posible cancelar este procedimiento",
    labelWaitToPdf: "Espera, generando PDF...",
    errorInternal: "ERROR INTERNO DEL SERVIDOR",
    titleTwttp: "TWTTP - HERCA",
    labelCleanFilter: "Limpiar filtro",
    titleNoData: "No hay datos para mostrar",
    labelToInterview: "Entrevistar",
    labelFinishInterview: "Finalizar entrevista",
    labelToValidation: "Enviar para validación",
    labelValidation: "Aprobar",
    labelMoveToExecution: "Mover a ejecución",
    labelCancel: "Cancelar",
    labelCode: "Código",
    labelSector: "Sector",
    labelArea: "Área",
    labelProblem: "Problema",
    labelOptions: "Opciones",
    titleOpenDateTwttp: "Fecha de apertura del Twttp",
    titleOpenDate: "Fecha de apertura",
    titlePrazActionTwttp: "Plazo para la acción del Twttp",
    titlePrazAction: "Plazo para la acción",
    labelFilterAdvanced: "Filtro avanzado",
    titleAddNewTwttp: "Agregar nuevo Twttp",
    labelBtnAddTwttp: "AGREGAR",
    twttpStatusA: "Abierto",
    twttpStatusV: "Validación",
    twttpStatusP: "En entrevista",
    twttpStatusS: "En ejecución",
    twttpStatusE: "Cerrado",
    twttpStatusVR: "Ver respuestas",
    twttpStatusC: "Cancelado",
    twttpStatusBPDF: "Descargar TWTTP PDF",
    twttpStatusR: "Rechazado",
    labelViewAnswers: "Ver respuestas",
    labelReprove: "Reprobar",
    twttpStatusY: "V. Producción",
    labelToValidationHigher: "Enviar para validación Producción",
    titleFormMoveValidationProd:
      "Enviando el TWTTP del estado Validación Producción a Validación",
    subtitleFormMoveValidationProd:
      "Si estás seguro de que quieres mover el TWTTP a validación, haz clic en SÍ",
  },
  registro_emocional: {
    errorInternal: "Error interno del servidor",
    errorUnknown: "Error desconocido al intentar recuperar emociones",
    title: "Registro del clima empresarial",
    labelQuantity: "Cantidad",
    subtitleNotFound: "Nada encontrado",
    subtitleNoFeelings: "No se registraron sentimientos",
    subtitleBiggestFeeling: "Mayor sentimiento en el periodo",
    titleCompanyFeels: "Cómo se siente la empresa en general",
    titleEmotionGraph: "Clima empresarial por día",
    subtitleEmotionGraph: "Mayor sentimiento registrado cada día",
    titleEmotionGraphLottie1: "Tuvimos más colaboradores",
    titleEmotionGraphLottie2: "en este día",
    titleEmotionGraphToday: "Lista de emociones seleccionadas este día",
    labelSector: "Sector",
    subtitleOtherReaction: "Otras reacciones presentadas en el periodo",
    titleOtherReactionLottie: "Cómo se siente la empresa en general",
    labelPerQuantity: "(por cantidad)",
  },

  gestao_helpdesk: {
    labelFilter: "Filtro por período",
    labelFilterAdvanced: "Filtro avanzado",
    tickets: "Tickets",
    titleChartStatus: "Tickets por estado",
    titleChartSubject: "Tickets por asunto",
    titleChartAgent: "Tickets por agente",
    titleChartSector: "Tickets por sector",
    titleChartUser: "Tickets por usuario",
    titleChartMonth: "Tickets por mes",
    timeMedium: "Tiempo medio de atención (general)",
    titleChartTimeMedium: "Tiempo medio de atención por Asunto",
    filterNameStatus: "Estado",
    filterErrorStatus: "* Elige al menos un Estado",
    filterNameAgent: "Agente",
    filterErrorAgent: "* Elige al menos un Agente",
    filterNameSubject: "Asunto",
    filterErrorSubject: "* Elige al menos un Asunto",
    filterNameSector: "Sector",
    filterErrorSector: "* Elige al menos un Sector",
    filterNameUser: "Usuario",
    filterErrorUser: "* Elige al menos un Usuario",
    filterNamePlant: "Planta",
    filterErrorPlant: "* Elige al menos una Planta",
    titleSearchAdvanced: "Búsqueda avanzada",
    descriptionSearchAdvanced:
      "Elige entre las opciones y monta una búsqueda avanzada.",
  },
  ferias: {
    titleBtnDownloadReport: "Descargar el informe de vacaciones no firmadas",
    labelBtnDownloadReport: "VACACIONES NO FIRMADAS",
    labelGeoAccept: "¡Ya has proporcionado tu ubicación!",
    labelGeo: "Permitir localización",
    titleSignature: "Tu firma",
    clearSignature: "Borrar",
    saveSignature: "Guardar",
    titleImageLoad: "Imagen del usuario cargada",
    labelPhoto: "Tomar foto",
    labelPhotoInsert: "Foto insertada",
    labelPhotoRemove: "Eliminar foto",
    alertRemoveImage: "¿Realmente quieres eliminar la foto?",
    labelTerm:
      "Marca aquí si estás de acuerdo con los valores informados arriba.",
    labelSaveBtn: "CONFIRMAR RECEPCIÓN",
    labelBtnDownloadWarningVacation: "Recibo de Vacaciones",
    errorNotFoundIP: "Error al intentar recuperar la IP",
    errorNotExistsWarningVacation:
      "No existe un aviso de vacaciones para descargar",
    title: "Recibos de vacaciones",
    labelBtn: "Firmar",
    initialVacation: "Inicio",
    finalVacation: "Final",
    returnOfVacation: "Regreso de las vacaciones",
    quantityOfDays: "Cantidad de días",
    dateOfPayment: "Fecha de pago",
    signature: "Firmado",
    notSignature: "No firmado",
    labelBtnVacation: "Recibo Vacaciones",
    labelBtnSalaryBonus: "Recibo Bono",
    titleSalaryBonus: "BONO (TOTAL)",
    titleVacation: "VACACIONES (Total)",
    verb: "Verbo",
    provent: "PROVENTO",
    discount: "DESCUENTO",
  },
  estatisticas: {
    labelToday: "Hoy",
    labelThisMonth: "Este mes",
    labelLastSixMonths: "Últ. 6 meses",
    labelByPeriod: "Por periodo",
    labelAccessBySO: "Accesos por S.O",
    labelAccessByBrowser: "Accesos por navegador",
    labelAccessByRoute: "Accesos por ruta",
    title: "Estadísticas de acceso",
    labelQuantity: "Cantidad",
    periodOf: "Periodo de",
    periodTo: "hasta",
    access: "acceso(s)",
    titleAccessByMenu: "Accesos por página",
    titleTopSevenUser: "Top 7 Usuarios",
    titleAccessByDay: "Accesos por día",
    dateFrom: "Fecha desde",
    dateTo: "Fecha hasta",
    titleFilterByPeriod: "Filtro por periodo 🧐",
    subtitleFilterByPeriod: "🔎 Elige una fecha en la que deseas ver los datos",
    format: "Formato",
    btnSend: "ENVIAR",
  },
  manutencao_ativos: {
    titlePage: "Mantenimiento de Activos",
    subtitlePage:
      "Elija entre las opciones disponibles para realizar el mantenimiento de los activos que se utilizan en varias páginas.",
    labelArea: "Área",
    titleLabelArea: "Haga clic aquí para realizar el mantenimiento del Área",
    labelModelo: "Modelo",
    titleLabelModelo:
      "Haga clic aquí para realizar el mantenimiento del modelo",
    labelPerda: "Pérdida",
    titleLabelPerda:
      "Haga clic aquí para realizar el mantenimiento de la pérdida",
    labelPilar: "Pilar",
    titleLabelPilar: "Haga clic aquí para realizar el mantenimiento del pilar",
  },

  manutencao_area: {
    titleArea: "Mantenimiento del Área",
    searchAreaPlaceholder: "Ingrese el nombre del área",
    modelInactive: "Modelo Inactivo (no se puede usar)",
    modelActive: "Modelo activo",
    active: "ACTIVA",
    inactive: "INACTIVA",
    titleBtnIconCancel: "Cancelar edición del área",
    titleBtnIconEdit: "Haga clic para editar el área",
    titleSituationArea: "Situación del área",
    titleSituationAreaActive: "Activa para uso",
    titleSituationAreaInactive: "Desactivada para uso",
    titlePlant: "Planta",
    listOfModels: "Lista de modelos",
    labelModelName: "Modelo",
    titleModelThis: "Este modelo está",
    titleModelActive: " activado ",
    titleModelInactive: " desactivado ",
    titleModelThis2: "para esta área",
    placeholderAreaName: "Ingrese el nombre del área",
    errorAreaName: "* Campo obligatorio",
    labelArea: "Área",
    titleName: "Nombre",
    errorAreaMin: "* Mínimo de 3 caracteres",
    titleAddArea: "Inclusión de una nueva área",
    subtitleAddArea: "Ingrese el nombre de la nueva área a incluir",
  },
  manutencao_modelo: {
    situationA: "Situación: ACTIVO",
    situationDefault: "Situación: INACTIVO",
    labelSituationActive: "ACTIVO",
    titleSituationActive: "Mostrar solo modelos activos",
    labelSituationInactive: "INACTIVO",
    titleSituationInactive: "Mostrar solo modelos inactivos",
    placeholderModelName: "Ingrese el nombre del modelo",
    labelNoDataModel: "No se encontraron modelos en el filtro proporcionado",
    labelModelName: "Modelo",
    errorModelName: "* Obligatorio",
    titleModelAdd: "Registro de modelo",
    subtitleModelAdd:
      "Ingrese a continuación el nombre del nuevo modelo a insertar",
    titleName: "Nombre",
    titleBtnCancel: "Cancelar edición del modelo",
    titleBtnEdit: "Haga clic para editar el modelo",
    titleModelEdit: "Visualización y edición del modelo",
    titleModel: "Mantenimiento de modelos",
    subtitleModel:
      "Haga clic en alguno de los modelos para ver las opciones disponibles",
  },
  manutencao_perda: {
    labelWaitDesenv: "Espere... Página en desarrollo",
  },
  manutencao_pilar: {
    labelWaitDesenv: "Espere... Página en desarrollo",
  },
  dados_cadastrais: {
    errorServer: "Error interno del servidor",
    male: "Masculino",
    female: "Femenino",
    depLabelNameDepend: "Nombre del dependiente",
    depPlaceholerNameDepend: "Nombre del dependiente",
    depLabelCpfDepend: "CPF del dependiente",
    depPlaceholerCpfDepend: "EX: 000.000.000-00",
    depLabelBirthDepend: "Fecha de nacimiento",
    depPlaceholerBirthDepend: "dd/mm/yyyy",
    depLabelSexDepend: "Sexo",
    depLabelSchoolingDepend: "Educación",
    depLabelDegreeDepend: "Grado de parentesco",
    depErrorNameDepend: "* Mínimo de 3 caracteres",
    depErrorSexDepend: "* Obligatorio",
    depErrorCpfDepend: "* EX: 000.000.000-00",
    depErrorBirthDepend: "* EX: 00/00/0000",
    depErrorSchoolingDepend: "* Obligatorio",
    depErrorDegreeDepend: "* Nombre del dependiente",
    titleDepend: "Formulario para mantenimiento de dependiente",
    depConfirmDelete: "¿Realmente quieres eliminar a este dependiente?",
    depTitleBtnCConfigm: "Haz clic para confirmar el registro del dependiente",
    depLabelBtnConfigm: "Confirmar",
    depTitleBtnEdit: "Haz clic para cambiar algún campo del dependiente",
    depLabelBtnEdit: "Editar",
    depTitleBtnDelete: "Haz clic para eliminar a este dependiente",
    depLabelBtnDelete: "Eliminar",
    depLabelBirthNasDepend: "Nacimiento",
    depLabelCpfDepend2: "CPF",
    titlePage: "Dependientes",
    titleFabAddDepend: "Haz clic para agregar un dependiente",
    labelFabAddDepend: "Agregar dependiente",
    subtitleListOfDependent:
      "Lista con los dependientes vinculados a tu registro. Verifica la información y confírmala (cuando sea necesario) o edita al dependiente eliminándolo.",
    titleNotDependent: "No se encontraron dependientes para ti",
    labeName: "Nombre",
    placeholderName: "Nombre completo",
    labelCpf: "CPF",
    placeholderCpf: "EX: 000.000.000-00",
    labelPhone: "Teléfono",
    placeholderPhone: "EX: (00) 00000-0000",
    labelRg: "RG",
    placeholderRg: "Escribe aquí",
    labelEmail: "Correo electrónico",
    placeholderEmail: "correo@ejemplo.com",
    labelBirth: "Nacimiento",
    placeholderBirth: "DD/MM/YYYY",
    labelCourse: "Curso",
    placeholderCourse: "Curso ...",
    labelCourseDate: "Fecha del curso",
    labelMaritalStatus: "Estado civil",
    labelSchooling: "Educación",
    labelDisability: "Discapacidad",
    labelDisabilityType: "Tipo de discapacidad",
    labelCep: "Código Postal",
    placeholderCep: "EX: 00000-000",
    labelAddress: "Dirección",
    placeholderAddress: "Calle de ejemplo",
    labelNumber: "Número",
    placeholderNumber: "Ej: 100",
    labelComplement: "Complemento",
    placeholderComplement: "complemento",
    labelCityCode: "Código de la ciudad",
    placeholderCityCode: "Código de la ciudad",
    labelDistrict: "Barrio",
    placeholderDistrict: "Barrio de ejemplo",
    labelCity: "Ciudad",
    placeholderCity: "Ciudad",
    labelState: "Estado",
    placeholderState: "Estado",
    labelReferencePoint: "Punto de referencia",
    placeholderReferencePoint: "Punto de referencia",
    labelEmergencyName: "Nombre de emergencia",
    placeholderEmergencyName: "Nombre de emergencia",
    labelEmergencyContact: "Contacto de emergencia",
    placeholderEmergencyContact: "EX: (00) 00000-0000",
    labelAttachments: "Adjunto(s)",
    errorName: "* Obligatorio",
    errorCpf: "Ej: 000.000.000-11",
    errorPhone: "Ej: (00) 00000-0000",
    errorRg: "* Mínimo 3 caracteres",
    errorEmail: "Ej: ejemplo@email.com",
    errorBirth: "Ej: 00/00/0000",
    errorMaritalStatus: "* Elige un estado civil",
    errorSchooling: "* Elige una educación",
    errorDisability: "* Obligatorio",
    errorDisabilityType: "* Obligatorio",
    errorCep: "* EX: 00000-000",
    errorAddress: "* Obligatorio",
    errorNumber: "* Obligatorio",
    errorCityCode: "* Obligatorio",
    errorDistrict: "* Obligatorio",
    errorCity: "* Obligatorio",
    errorState: "* Obligatorio",
    errorReferencePoint: "* Obligatorio",
    errorEmergencyName: "* Obligatorio",
    errorEmergencyContact: "* Ej: (00) 00000-0000",
    errorCepNot200:
      "Error al intentar consultar el código postal. Inténtalo más tarde.",
    errorCepNotFound: "Código postal inexistente",
    titleFormUpd: "Datos de registro",
    titleUpdPasswd: "Configuraciones del usuario",
    titleForm: "Actualización de contraseña",
    titleField1: "Nueva contraseña",
    titleField2: "Confirmar contraseña",
    placeholderField1: "Ingresa la nueva contraseña",
    placeholderField2: "Confirma la nueva contraseña",
    btnUpdate: "Actualizar contraseña",
    msgError: "LAS CONTRASEÑAS NO SON IGUALES",
    msgFieldError: "Mínimo de 3 caracteres",
    cardTitleName: "Nombre",
    cardTitleCpf: "CPF",
    cardTitlePhone: "Teléfono",
    cardTitleBirth: "Nacimiento",
    cardTitleRg: "RG",
    cardTitleEmail: "Correo electrónico",
    cardTitleMaritalStatus: "Estado civil",
    cardTitleSchooling: "Educación",
    cardTitleCourse: "Curso",
    cardTitleCourseDate: "Fecha del curso",
    cardTitleDisability: "Discapacidad",
    cardTitleDisabilityType: "Tipo de discapacidad",
    cardTitleCep: "Código Postal",
    cardTitleStreet: "Calle",
    cardTitleNumber: "N°",
    cardTitleComplement: "Complemento",
    cardTitleReferencePoint: "Punto de referencia",
    cardTitleNeighborhood: "Barrio",
    cardTitleCity: "Ciudad",
    cardTitleState: "Estado",
    cardTitleMunicipalityCode: "Código del municipio",
    cardTitleEmergencyName: "Nombre de emergencia",
    cardTitleEmergencyContact: "Contacto de emergencia",
    cardTitleAttachment: "Adjunto",
    cardTitleAttachmentClickHere: "Haz clic aquí",
    approved: "Aprobado",
    inAnalysis: "En análisis",
    pending: "Pendiente",
    titleApproved: "Información recibida y confirmada",
    titleInAnalysis: "Información pendiente de análisis",
    titlePending: "Esperando definición de la situación",
    tab1: "Personales",
    tab2: "Dependientes",
    tab3: "Contraseña",
  },

  dados_cadastrais_page: {
    titlePage: "Relación de datos de registro",
    subtitlePage:
      "Haz clic en un registro para cambiar su estado, o ver los dependientes.",
    titleViewAttachments: "Adjunto(s) relacionado(s) con el registro",
    subtitleViewAttachments:
      "A continuación se muestra una lista de todos los adjuntos que fueron enviados por el colaborador y que forman parte de este registro de datos",
    attachment: "Adjunto",
    titleBtnAttach1: "Hay",
    titleBtnAttach2: "adjunto(s) en este registro",
    titleConfirm: "Confirma la recepción del registro",
    titleReject: "Rechaza los datos de registro enviados",
    titleActiveDeactivePend:
      "Activar/Desactivar la visualización de registros pendientes",
    titleActiveDeactiveOk:
      "Activar/Desactivar la visualización de registros confirmados",
    titleViewDependents: "Ver los dependientes vinculados al colaborador",
    titleDependents: "Dependientes vinculados al colaborador",
    errorInternal: "ERROR INTERNO DEL SERVIDOR",
    errorUnknown: "Error desconocido",
    labelFilterPendent: "PENDIENTE",
    labelFilterOK: "OK",
  },
  banco_de_horas: {
    titlePage: "Banco de horas",
    titleUpdateCache: "Actualizar la caché del banco de horas",
    saldoFinal: "SALDO FINAL",
  },

  documentos: {
    detailsHeaderFileVisibleForPlant: "Visible para todos desde",
    detailsHeaderFileOwner: "Dueño del archivo",
    editNameFileNewName: "Nuevo nombre",
    editNameFileNewNamePlaceholder: "Ingrese el nuevo nombre del archivo",
    editNameFileNewNameError: "* Mínimo de 3 caracteres",
    editNameFileTitle: "Ingrese el nuevo nombre",
    labelEditVisibilityBy: "Visible por",
    labelEditFuncPermissionView:
      "Empleados con permiso para ver (dejar en blanco para sin restricción)",
    errorVisibilityBy: "* Elija al menos una planta para ver",
    titleEditVisibility: "Establecer nivel de visibilidad",
    labelItemFileMobileCreated: "Creado",
    labelItemFIleMobileFile: "Archivo",
    labelItemCreated: "Creado",
    labelItemFile: "Archivo",
    withPermissionToView: "tiene permiso para ver",
    labelItemMenuDetails: "Detalles",
    labelItemMenuRename: "Renombrar",
    labelItemMenuCut: "Cortar",
    labelItemMenuAlterVisibility: "Cambiar visibilidad",
    labelItemMenuDelete: "Eliminar",
    labelItemTrashDetails: "Detalles",
    labelItemTrashRecover: "Recuperar de la papelera",
    labelItemTrashDeleteForever: "Eliminar permanentemente",
    titleConfirmDelete: "¿Realmente quieres eliminar?",
    subtitleConfirmDelete:
      "Esta acción no se puede deshacer. Si es un directorio, también se eliminarán todos los archivos debajo.",
    subtitleConfirmDeleteFile:
      "Esta acción no se puede deshacer. El elemento se eliminará permanentemente de la papelera",
    labelNewFolder: "Nombre de la carpeta",
    placeholderNewFolder: "Ingrese el nombre de la carpeta",
    labelVisibilityBy: "Visible por",
    labelFuncPermittedView:
      "Empleados con permiso para ver (dejar en blanco para sin restricción)",
    errorNewFolder: "* El nombre debe tener al menos 2 caracteres.",
    errorNotFiles: "Debe existir archivos",
    titleNewFolder: "Creación de una nueva carpeta",
    titleTrash: "Papelera de elementos eliminados",
    labelNewFiles: "Nuevos archivos",
    placehlderNewFiles: "Elija archivos para enviar",
    titleUploadNewFiles: "Nuevos archivos en la carpeta",
    errorInternalServer: "ERROR INTERNO DEL SERVIDOR",
    titleViewFile: "Detalles sobre el elemento",
    file: "Archivo",
    fromFile: "del archivo",
    fromFolder: "de la carpeta",
    created: "Creado",
    createdBy: "Creado por",
    linkToDownload: "Enlace para descargar",
    secondaryLinkToDownload: "Haga clic para descargar el archivo",
    titleAccessRestricted: "Acceso restringido solo para",
    titleAccessPermitted: "Acceso permitido a",
    labelAllPlantsView: "Todos de las plantas con vista pueden ver",
    labelBtnGoBack: "VOLVER",
    labelBtnZoomOut: "ZOOM OUT",
    labelBtnZoomIn: "ZOOM IN",
    labelBtnNext: "SIGUIENTE",
    labelBtnClose: "CERRAR",
    descPageOf1: "Página",
    descPageOf2: "de",
    titleAccessTrash: "Acceso a la papelera",
    titleUploadFiles: "Subir archivos",
    titleCreateFolder: "Crear nueva carpeta",
    labelBtnCancel: "Cancelar",
    labelBtnPaste: "Pegar aquí",
    titleAnimationNoData: "No hay archivos en esta carpeta",
  },
  oportunidades: {
    titleHistVacation: "Historial de trabajo del candidato",
    fromPhase: "De la fase",
    toPhase: "A la fase",
    observation: "Observación",
    btnHistAttachment: "Adjunto",
    histData: "Fecha",
    errorInternal: "ERROR INTERNO DEL SERVIDOR",
    errorUnknown: "ERROR DESCONOCIDO",
    illiterate: "ANALFABETO",
    incompletePrimary: "HASTA LA 4ª SERIE INCOMPLETA (PRIMARIA INCOMPLETA)",
    completePrimary: "CON 4ª SERIE COMPLETA DEL 1º GRADO (PRIMARIA COMPLETA)",
    incompleteSecondary: "PRIMER GRADO (ESCUELA MEDIA) INCOMPLETO",
    completeSecondary: "PRIMER GRADO (ESCUELA MEDIA) COMPLETO",
    incompleteHighSchool: "SEGUNDO GRADO (ESCUELA SUPERIOR) INCOMPLETO",
    completeHighSchool: "SEGUNDO GRADO (ESCUELA SUPERIOR) COMPLETO",
    incompleteCollege: "CARRERA UNIVERSITARIA INCOMPLETA",
    completeCollege: "CARRERA UNIVERSITARIA COMPLETA",
    completeMaster: "MÁSTER COMPLETO",
    completeDoctorate: "DOCTORADO COMPLETO",
    specialization: "POSTGRADO/ESPECIALIZACIÓN",
    postDoctorate: "POST-DOCTORADO",
    labelWaitGeneratePDF: "Espera, generando PDF...",
    isOk: "Completada",
    isOpen: "Abierta",
    labelBtnClose: "Cerrar",
    titleScholarship: "Educación",
    titlePhone: "Móvil",
    titleDateInscription: "Fecha de inscripción",
    titleSector: "Sector",
    titleEmail: "E-mail",
    titleAttachment: "Adjunto(s) del candidato",
    attachment: "Adjunto",
    titleAnswerQuestionnaire: "Respuestas al cuestionario",
    titleBtnDownloadPdf: "Haz clic aquí para descargar una versión en PDF",
    labelBtnDownloadPdf: "DESCARGAR RESPUESTAS",
    hidden: "Ocultar",
    show: "Mostrar Candidatos Registrados para el trabajo",
    labelPhaseActualy: "Fase actual",
    titleVacationPage: "Candidatos para el trabajo",
    phase: "FASE",
    titleBtnViewHistory: "Ver el historial de movimientos",
    labelBtnViewHistory: "Historial",
    titleBtnViewAnswers: "Ver respuestas al cuestionario",
    labelBtnViewAnswers: "Respuestas",
    titleBtnChangePhase: "Cambiar el candidato a la siguiente fase",
    labelBtnChangePhase: "Cambiar Fase",
    labelEmail: "E-mail",
    placeholderEmail: "Tu email",
    labelPhone: "Móvil",
    placeholderPhone: "(00) 00000-0000",
    labelSchooling: "Educación *",
    placeholderWriteHere: "Escribe aquí",
    errorQuestion: "* Por favor, responde a la pregunta",
    labelToPhase: "Siguiente fase a mover",
    labelObservation: "Observación",
    placeholderObservation: "Introduce la siguiente fase aquí",
    labelFile: "Archivo",
    placeholderFile: "Introduce un archivo",
    errorObservation: "* Por favor, introduce al menos 3 caracteres",
    errorToPhase:
      "* Elige la fase en la que el candidato será movido a la siguiente fase",
    titleAlterPhase: "Cambio de fase",
    subtitleNewPhase:
      "Indica la nueva fase en la que se ha trasladado al candidato.",
    labelOpenDate: "Fecha de apertura",
    labelVacation: "Trabajo",
    labelFunction: "Función",
    labelDescription: "Descripción",
    labelActivity: "Actividad",
    labelIamCandidate: "Ya soy candidato",
    labelRegister: "POSTULARSE",
    titleOpenDate: "Fecha de inclusión del trabajo",
    titlePage: "Oportunidades",
    titleNoData: "No hay oportunidades en este momento",
    titleSituationActualy: "Situación actual del trabajo",
  },
  painel_de_notificacao: {
    titlePage: "Panel de notificaciones",
    subtitleSendMessageBySector: "Envío de mensajes por sector",
    titleSendNotificationBySector: "Envío de notificaciones por sector",
    titleSendNotificationByPlant: "Envío de notificaciones por planta",
    errorTitle: "Mínimo de 3 caracteres",
    errorDescription: "Mínimo de 3 caracteres",
    errorSector: "Selecciona al menos un sector",
    labelSector: "Elige el sector",
    labelTitle: "Título",
    placeholderTitle: "Escribe un título",
    labelDescription: "Descripción",
    placeholderDescription: "Escribe una descripción",
    labelBtnRemoveSector: "Eliminar sectores seleccionados",
    titleCanReceiveNotifications: "Pueden recibir notificaciones",
    titleCannotReceiveNotifications: "No pueden recibir notificaciones",
    labelClearFilter: "Borrar filtro",
    titlePageSendOneNotification: "Envío de una notificación individual",
    subtitlePageSendOneNotification1: "Notificando al usuario",
    subtitlePageSendOneNotification2: "del sector",
    titlePageSendPlantNotification: "Envío de una notificación individual",
    subtitlePageSendPlantNotification:
      "Rellena los campos para enviar una notificación a toda la planta de",
  },
  manutencao_componentes: {
    titlePage: "Mantenimiento de Estructuras",
    titleFab: "Inserción de un Nuevo Componente",
    labelFab: "NUEVO COMPONENTE",
    components: "componentes",
    descriptionPortuguese: "Descripción Portuguesa",
    titlePageFormAdd: "INSERCIÓN DE UN NUEVO COMPONENTE",
    labelCode: "Código",
    placeholderCode: "Código",
    labelDescriptionPortuguese: "Descripción Portuguesa",
    placeholderDescriptionPortuguese: "Descripción en portugués",
    labelDescriptionEnglish: "Descripción Inglesa",
    placeholderDescriptionEnglish: "Descripción en inglés",
    labelDescriptionItalian: "Descripción Italiana",
    placeholderDescriptionItalian: "Descripción en italiano",
    labelComponentMath: "Matemáticas del Componente",
    placeholderComponentMath: "Matemáticas del Componente",
    labelSupplier: "Proveedor",
    placeholderSupplier: "Proveedor",
    labelType: "Tipo",
    placeholderType: "Tipo",
    labelMass: "Masa",
    placeholderMass: "Masa",
    labelMaterialStandard: "Norma de Material",
    placeholderMaterialStandard: "Especificación de Pintura",
    labelWeldingAreaSpecification: "Especificación de Área de Soldadura",
    placeholderWeldingAreaSpecification: "Especificación de Área de Soldadura",
    labelUnitMeasure: "Unidad de Medida",
    placeholderUnitMeasure: "Unidad de Medida",
    labelThreadProtectiveApplication: "Aplicación Protectora de Rosca",
    placeholderThreadProtectiveApplication: "Aplicación Protectora de Rosca",
    labelComponentImage: "Imagen del Componente",
    placeholderComponentImage: "archivo",
    expectMax: "* Se espera como máximo",
    characters: "caracteres",
  },
  informe_rendimentos: {
    titlePage: "Informe de ingresos",
    titleNoData: "Haz clic en algún año para descargar el informe de ingresos",
    titleChip: "Año de",
    titleAnimation: "Informe de ingresos. Haz clic en el botón para descargar",
    titleButton: "Haz clic para descargar el informe del año de",
    labelButton: "DESCARGAR AÑO",
  },
  budget: {
    titleClickValue: "Haz clic para ver detalles",
    infoTable: "* Haz clic en los valores de VLR REAL para ver los detalles",
    month: "",
    nothing: "No hay datos para devolver",
    tab1: "Resumen",
    tab2: "Analítico",
    title: "Detalles",
    headerDescription: "DESCRIPCIÓN DE LA CUENTA",
    headerHistory: "HISTÓRICO",
    headerClientSupplier: "CLIENTE / PROVEEDOR",
    headerMonth: "MES",
    headerAccount: "CUENTA",
    headerValue: "VALOR",
    titleNoData: "No hay datos",
  },

  ncp: {
    tituloBuscaAvancada: "Búsqueda avanzada",
    descricaoBuscaAvancada:
      "Elija entre las opciones y monte una búsqueda avanzada.",
    tituloNaoConformidade: "EVIDENCIAS DE NO CONFORMIDAD",
    rotuloBtnFiltro: "BÚSQUEDA AVANZADA",
    tituloSemDados: "No se encontraron datos",
    tituloSemDadosRetorno: "Intentando cargar datos necesarios...",
    titulo: "NCP",
    tituloPaginacao: "Haga clic para alternar entre las páginas",
    codigo: "Código",
    anomalia: "Anomalía: ",
    data: "Fecha de creación: ",
    area: "Área: ",
    rotuloBotaoAdd: "Nuevo NCP",
    tituloBotaoAdd: "Crear nuevo NCP",
    tituloForm: "ALERTA DE NO CONFORMIDAD DEL PROCESO",
    formAuditor: "AUDITOR",
    formConjunto: "CONJUNTO",
    formModelo: "MODELO",
    formTurno: "TURNO",
    formQtdKO: "CANTIDAD KO",
    formQtdSuspeita: "CANTIDAD SOSPECHOSA",
    formOperacao: "OPERACIÓN",
    formNCP: "NCP N°",
    formPN: "PN",
    formRastreabilidade: "RASTREABILIDAD",
    formData: "FECHA",
    formHora: "HORA",
    formErrorAuditor: "Informe al menos un colaborador",
    formErrorConjunto: "Elija al menos un área",
    formErrorModelo: "Elija al menos un modelo",
    formErrorTurno: "Elija al menos un turno",
    formErrorQtdKO: "Mínimo 0",
    formErrorQtdSuspeita: "Mínimo 0",
    formErrorOperacao: "Operación",
    formErrorNCP: "NCP N°",
    formErrorPN: "Informe el PN",
    formErrorRastreabilidade: "Informe la rastreabilidad",
    formErrorSubidaDeLinha: "¿Subió de línea?",
    formErrorFalhaForaControle: "¿Fue fallo fuera de control?",
    formErrorTwttp: "Obligatorio",
    formErrorData: "Informe la fecha",
    formErrorHora: "Informe la hora",
    formErrorAnomalia: "Informe la anomalía",
    formAuditorPlaceholder: "Informe el auditor",
    formConjuntoPlaceholder: "Área",
    formModeloPlaceholder: "Modelo",
    formTurnoPlaceholder: "Turno",
    formQtdKOPlaceholder: "EJ: 1",
    formQtdSuspeitaPlaceholder: "EJ: 1",
    formOperacaoPlaceholder: "Operación",
    formNCPPlaceholder: "NCP N°",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Rastreabilidad",
    formDataPlaceholder: "Fecha",
    formHoraPlaceholder: "Hora",
    formAnomaliaPlaceholder: "Describa la anomalía...",
    formAnomalia: "ANOMALÍA",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "IDENTIFICACIÓN DE RACK O CAJA",
    formOque: "¿QUÉ?",
    formQuando: "¿CUÁNDO?",
    formOnde: "¿DÓNDE?",
    formQuem: "¿QUIÉN?",
    formQual: "¿CUÁL?",
    formComo: "¿CÓMO?",
    formErrorOque: "Obligatorio",
    formErrorQuando: "Obligatorio",
    formErrorOnde: "Obligatorio",
    formErrorQuem: "Obligatorio",
    formErrorQual: "Obligatorio",
    formErrorComo: "Obligatorio",
    formOquePlaceholder: "¿Qué ocurrió?",
    formQuandoPlaceholder: "¿Cuándo ocurrió?",
    formOndePlaceholder: "¿Dónde ocurrió?",
    formQuemPlaceholder: "¿Quién identificó?",
    formQualPlaceholder: "¿Cuál es el nivel del problema?",
    formComoPlaceholder: "¿Cómo ocurrió?",
    formFenomenoRevisado: "FENÓMENO REVISADO",
    formErrorFenomenoRevisado: "Obligatorio",
    formFenomenoRevisadoPlaceholder: "Describa el fenómeno.",
    formCausaRaiz: "POSIBLE CAUSA RAÍZ",
    formCausaRaizPlaceholder: "Describa aquí...",
    formErrorCausaRaiz: "Obligatorio",
    tituloBotaoVoltar: "Haga clic para regresar",
    rotuloBotaoFormSalvar: "GUARDAR",
    tituloBotaoRemoverImagem: "Haga clic para remover la imagen",
    formRotuloImagem: "Inserte aquí la imagen",
    formErrorImagemOK: "Imagen obligatoria",
    formErrorImagemKO: "Imagen obligatoria",
    formErrorImagemIdent: "Error en el envío de la imagen",
    situacaoQualidade: "CALIDAD",
    situacaoSubida: "SUBIDA DE LÍNEA",
    situacaoAberto: "ABIERTO",
    opcaoDetalhes: "Detalles",
    opcaoSubida: "Subida de Línea",
    opcaoDownloadPdf: "Descargar en PDF",
    opcaoQualidade: "Mover Calidad",
    rotuloBotaoVerOpcoes: "OPCIONES",
    tituloBotaoVerOpcoes: "Muestra las opciones de manipulación del NCP",
    tituloFormSubida: "Formulario de subida de línea",
    formPlaceholderTempoGasto: "Informe el tiempo gastado",
    formTempoGasto: "Tiempo gastado",
    formPlaceholderMod: "Informe la mano de obra directa",
    formMod: "MOD",
    formPlaceholderMoi: "Informe la mano de obra indirecta",
    formMoi: "MOI",
    formPlaceholderOK: "Informe la cantidad OK",
    formOK: "Cantidad OK",
    formPlaceholderKO: "Informe la cantidad KO",
    formKO: "Cantidad KO",
    formPlaceholderObservacao: "Describa una observación",
    formObservacao: "Observación",
    formErrorTempoGasto: "* Favor informar el tiempo gastado HH:MM",
    formErrorMod: "* Valor mínimo 0",
    formErrorMoi: "* Valor mínimo 0",
    formErrorOK: "* Valor mínimo 0",
    formErrorKO: "* Valor mínimo 0",
    formErrorObservacao: "* Describa una observación mínimo 3 caracteres",
    tituloFormQualidade: "Formulario de Calidad",
    formPlaceholderCodigoProjeto: "Informe el código del proyecto",
    formCodigoProjeto: "Código del Proyecto",
    formPlaceholderProjeto: "¿Este NCP tiene vínculo con algún proyecto?",
    formProjeto: "¿Este NCP tiene vínculo con algún proyecto?",
    formPlaceholderAcoes: "Describa las acciones",
    formAcoes: "Acción",
    formErrorProjeto: "* Elija Sí o no",
    formErrorCodigoProjeto: "* Informe el código del proyecto",
    formErrorAcoes: "* Describa la acción mínimo 3 caracteres",
    abaForm1: "NCP",
    abaSubida: "SUBIDA DE LÍNEA",
    abaQualidade: "CALIDAD",
    rotuloBotaoVoltar: "VOLVER",
    subidaLinhaMod: "Mano de obra Directa",
    subidaLinhaMoi: "Mano de obra Indirecta",
    subidaLinhaTempo: "Tiempo de trabajo",
    subidaLinhaOK: "Cantidad de piezas OK",
    subidaLinhaKO: "Cantidad de piezas KO",
    subidaLinhaObs: "Observación de la mano de obra",
    qualidadeCodProjeto: "Número del Proyecto",
    qualidadeObs: "Acciones",
    filtroNomeStatus: "ESTADO",
    filtroNomeStatusErro: "* Elija al menos uno",
    filtroNomeMatricula: "MATRÍCULA",
    filtroNomeMatriculaErro: "* Elija al menos uno",
    filtroNomeArea: "ÁREA",
    filtroNomeAreaErro: "* Elija al menos uno",
    filtroNomeTurno: "TURNO",
    filtroNomeTurnoErro: "* Elija al menos uno",
    filtroNomeNcp: "CÓDIGO NCP",
    filtroNomeNcpErro: "* Elija al menos uno",
    filtroNomeOperacao: "OPERACIÓN",
    filtroNomeOperacaoErro: "* Elija al menos uno",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Elija al menos uno",
    opcaoExcluirNcp: "Excluir NCP",
    opcaoRetornarAbertoNcp: "Volver a abierto",
    opcaoRetornarSubidaDeLinhaNcp: "Volver a subida de línea",
    titlePageCancel: "¿Realmente desea cancelar este estado?",
    subtitleCancel: "El NCP será excluido y no podrá ser recuperado",
    subtitleCancel2: "El NCP volverá al estado ABIERTO",
    subtitleCancel3: "El NCP volverá al estado SUBIDA DE LÍNEA",
  },
  treinamento: {
    labelResponsibility: "Responsable",
    trainningDateTraining: "Fecha del Entrenamiento",
    instructor: "Instructor",
    local: "Local",
    titleViewTrainning: "Ver información del Entrenamiento",
    labelViewTrainning: "VER ENTRENAMIENTO",
    labelBtnOptions: "Opciones",
    titleEditTrainning: "Editar información del Entrenamiento",
    labelEditTrainning: "EDITAR ENTRENAMIENTO",
    titleAddParticipant: "Agregar alumnos al entrenamiento",
    labelAddParticipant: "AGREGAR ALUMNOS",
    labelViewHistory: "VER HISTORIAL",
    titleViewHistory: "VER HISTORIAL DEL ENTRENAMIENTO",
    labelAlterStatus: "CAMBIAR ESTADO",
    titleAlterStatus: "Cambiar estado del entrenamiento",
    optionTextNewTrainning: "Nuevo Entrenamiento",
    optionTitleNewTrainning:
      "Haga clic aquí para registrar nuevos entrenamientos",
    optionTextMaintenceCouse: "Mantenimiento de Cursos",
    optionTitleMaintenceCouse: "Haga clic aquí para mantenimiento de cursos",
    optionTextProfessor: "Mantenimiento de Instructor",
    optionTitleProfessor: "Haga clic aquí para mantenimiento de instructores",
    titlePage: "Entrenamiento",
    titlePaginationFilter: "Visualización paginada.",
    trainningNoData: "No hay datos para mostrar",
    labelDescription: "Descripción",
    placeholderDescription: "Descripción del curso",
    labelDuration: "Duración",
    placeholderDuration: "Duración del curso",
    labelModel: "Modelo",
    placeholderModel: "Modelo del curso",
    errorDescription: "Mínimo de 1 y máximo de 150 caracteres",
    errorDuration: "Formato inválido. Ejemplo: 00:00",
    trainningAddUpdInstTitleAdd: "Agregar Instructor",
    trainningAddUpdInstTitleUpd: "Actualizar Instructor",
    trainningAddUpdInsLabelName: "Nombre",
    trainningAddUpdInsErrorName:
      "El nombre del instructor debe tener entre 1 y 250 caracteres",
    trainningAddUpdInsErrorMat:
      "La matrícula del instructor debe tener 6 caracteres",
    trainningAddUpdInsErrorPlant: "Seleccione la planta",
    trainningAddUpdInsErrorOrigin: "Seleccione el origen del instructor",
    trainningAddUpdInsErrorEntity:
      "La entidad del instructor debe tener entre 1 y 150 caracteres",
    trainningAddUpdInsPlaceholderName: "Nombre del instructor",
    trainningAddUpdInsPlaceholderMat: "Matrícula del instructor",
    trainningAddUpdInsPlaceholderPlant: "Seleccione la planta",
    trainningAddUpdInsPlaceholderEntity: "Entidad del instructor",
    trainningAddUpdInsLabelMat: "Matrícula",
    trainningAddUpdInsLabelPlant: "Planta",
    trainningAddUpdInsLabelOrigin: "Origen del instructor",
    trainningAddUpdInsLabelEntity: "Entidad",
    trainningAddUpdInsLabelInstructor: "Instructor interno",
    titleTrainningCourse: "Mantenimiento de Cursos",
    textFabNewCouse: "Nuevo Curso",
    titleFabNewCouse: "Haga clic aquí para registrar nuevos cursos",
    labelCourseBtnOptions: "Opciones",
    titleEdtCourse: "Editar Curso",
    labelEdtCourse: "Editar",
    titleDelCourse: "Eliminar Curso",
    labelDelCourse: "Eliminar",
    subtitleDelCourse: "¿Desea realmente eliminar el curso?",
    titleDelInstructor: "Eliminar Instructor",
    subtitleDelInstructor: "¿Desea realmente eliminar el instructor?",
    titleFormAddNewCourse: "Agregar nuevo entrenamiento",
    labelCourse: "* Curso",
    labelInstructor: "* Instructor",
    labelDateInit: "* Fecha de inicio",
    labelLocal: "* Local",
    placeholderCourse: "Seleccione un curso",
    placeholderInstructor: "Seleccione un instructor",
    placeholderDateInit: "Ingrese la fecha de inicio",
    placeholderLocal: "Ingrese el local",
    errorCourse: "Seleccione un curso.",
    errorInstructor: "Seleccione un instructor.",
    errorDateInit: "Ingrese la fecha de inicio.",
    errorLocal: "Ingrese el local.",
    labelTypeOfCourse: "Tipo de curso",
    labelEfficacy: "Eficacia",
    titleDeleteTrainning: "Eliminar Entrenamiento",
    labelDeleteTrainning: "ELIMINAR ENTRENAMIENTO",
    titlePageDeleteTrainning: "Eliminar Entrenamiento",
    subtitlePageDeleteTrainning:
      "¿Realmente quieres eliminar el entrenamiento?",

    labelTheme: "Temas",
    labelApply: "Desarrollo (donde aplicable)",
    labelDevelopment: "Desarrollo de Formación",
    labelObservation: "Observaciones",
    labelDocument: "Documentos",
    labelMoreTimeEfficiency: "¿Necesita más tiempo para verificar la eficacia?",
    labelMoreTrainning: "¿Necesita más entrenamiento?",
    labelApproved: "Para la aprobación del curso",
    labelFinish: "Para el cierre de la relación",
    placeholderTypeOfCourse: "Ingrese el tipo de curso",
    placeholderEfficacy: "Ingrese la eficacia",

    placeholderTheme: "Ingrese los temas",
    placeholderApply: "Ingrese el desarrollo donde aplicable",
    placeholderDevelopment: "Ingrese el desarrollo de formación",
    placeholderObservation: "Ingrese las observaciones",
    placeholderDocument: "Ingrese los documentos",
    placeholderMoreTimeEfficiency:
      "Ingrese si necesita más tiempo para verificar la eficacia",
    placeholderMoreTrainning: "Ingrese si necesita más entrenamiento",
    placeholderApproved: "Ingrese para la aprobación del curso",
    placeholderFinish: "Ingrese para el cierre de la relación",
    labelReponsibility: "* Responsable",
    placeholderResponsibility: "Informe el responsable",
    labelDateRealized: "Fecha de realización",
    placeholderDateRealized: "Informe la fecha de realización",
    labelPresence: "Presencia",
    placeholderPresence: "Informe la presencia",
    labelCertificate: "Certificado",
    placeholderCertificate: "Informe el certificado",
    errorResponsibility: "Informe el responsable.",
    trainningFormAlterStatusTitle: "Cambiar situación del entrenamiento",
    trainningFormAlterStatusObservation: "Observación",
    trainningFormAlterStatusErrorObservation: "La observación es obligatoria",
    trainningFormAlterStatusFrom: "De",
    trainningFormAlterStatusTo: "Para",
    trainningInstructorTitle: "Mantenimiento de Instructores",
    textFabNewInstructor: "Nuevo Instructor",
    titleFabNewInstructor: "Agregar nuevo instructor",
    tilteEdtInstructor: "Editar Instructor",
    labelEdtInstructor: "Editar",

    labelDelInstructor: "Eliminar",
    titleFormStudents: "Agregar alumnos",
    titleFormStudentsSubtitle: "Añadir estudiantes a la formación",
    studentNumber: "Estudiante N°",
    studentNewAdd: "Agregar estudiante a la formación",
    studentRemove: "Eliminar estudiante de la formación",
    labelBtnSaveStudents: "GUARDAR",
    labelBtnClearAllStudents: "ELIMINAR TODOS",
    statusOpen: "ABIERTO",
    statusInTraining: "EN FORMACIÓN",
    statusDelivered: "ENTREGA COMPLETA",
    trainningViewHistoryTitle: "Ver historial de formación",
    trainningViewHistoryLabelMat: "Matrícula",
    trainningViewHistoryLabelName: "Nombre",
    trainningViewHistoryLabelFromStatus: "De situación",
    trainningViewHistoryLabelToStatus: "A situación",
    trainningViewHistoryLabelDate: "Fecha del registro",
    trainningViewHistoryLabelObservation: "Observación",
    titleBtnGoBack: "Haga clic para volver",
    labelBtnGoBack: "VOLVER",
    labelNoDataTrainning: "No se encontró formación",
    titleListOfTrainning: "LISTA DE FORMACIÓN",
    labelCodeN: "RAD N°",
    labelData: "FECHA",
    titleTypeOfTrainning: "TIPO DE FORMACIÓN",
    titleEfficiency: "EFICACIA, a evaluar por:",
    labelEfficiencyInfo:
      "Por favor, al final del RAD, describa la metodología de evaluación de la eficacia y adjunte evidencias cuando sea aplicable",
    labelLocale: "LOCAL",

    themeAndAttachs: "TEMAS Y ANEXOS",
    trainningName: "(Nombre)",
    trainningAssigned: "(Firma)",
    trainningProffessor: "PROFESOR(ES)",
    trainningPersonal: "ENTRENADOR(ES)",
    titleDevelop: "Donde sea aplicable: DESARROLLO ESPERADO DE LA FORMACIÓN",
    tableCellNumber: "NÚMERO DE CREDENCIAL",
    tableName: "NOMBRE Y APELLIDO",
    tableAssigned: "FIRMA",
    tableAvailable: "EVALUACIÓN DE EFICACIA\n(resultado, fecha)",
    titleDevelopFormattion:
      "DESARROLLO DE FORMACIÓN ALCANZADO (tras verificación de eficacia)",
    subtitleDevelopFormattion1: "Nivel",
    subtitleDevelopFormattion2:
      "¿alcanzó el nivel? Inserte el número (#) asociado al func.",
    labelObservationInfo: "OBSERVACIÓN",
    labelDocumentDistribution: "DOCUMENTOS DISTRIBUIDOS",
    titleMoreEfficiency: "¿Necesita más tiempo para verificar la eficacia?",
    titleMoreTrainning:
      "En caso de resultado negativo o parcialmente positivo de la evaluación de la eficacia de uno o más participantes:",
    titleMoreTrainning2: "¿Necesita más formación?",
    toApproved: "PARA APROBACIÓN DEL CURSO (HR/EHS/QS/PLM)",
    toFinish: "PARA CIERRE DE RELACIÓN (HR/EHS/QS/PLM)",
    downloadPDF: "Descargar PDF",
    clearFilter: "Limpiar filtro",
    optionTextDownloadExcel: "Descargar Excel",
    optionTitleDownloadExcel:
      "Haga clic aquí para descargar los datos en Excel",
    trainningDownloadExcelTitle: "Descargar Excel",
    trainningDownloadExcelLabelDateFrom: "Fecha de entrenamiento desde",
    trainningDownloadExcelLabelDateTo: "Fecha de entrenamiento hasta",
    trainningDownloadExcelErrorDateFrom:
      "La fecha de inicio del entrenamiento es obligatoria",
    trainningDownloadExcelErrorDateTo:
      "La fecha de finalización del entrenamiento es obligatoria",
  },
};

export default translate;
