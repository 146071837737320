import {
  Box,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Grow,
  Stack,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";
import _ from "lodash";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSet, useToggle } from "react-use";
import { AnimacaoSemDados, EntradaForm, H6, Icone } from "../../../components";
import { obterValidador, VALIDADOR_TIPO } from "../../../utils/validadores";
import {
  selectAgentesFormatado,
  selectStatusFormatado,
  selectAssuntos,
  selectCriticidade,
  selectTickets,
} from "./helpdesk-seletores";
import { helpdeskFiltroAdd } from "./helpdesk-actions";
import { useHistory } from "react-router-dom";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";

const selectColaboradores = (state) => state?.helpdesk?.colaboradores;

export const selectColaboradoresFormatadosFiltro = createSelector(
  selectColaboradores,
  (val) => {
    return _.map(val, (item) => [
      `${item.matricula}_${item.planta}`,
      `${item.planta} - ${item.nome}`,
    ]);
  }
);

const rotuloFiltroAvancadoTicket = "Ticket";
const rotuloFiltroAvancadoAssunto = "Assunto";
const rotuloFiltroAvancadoAgente = "Agente";
const rotuloFiltroAvancadoStatus = "Status";
const rotuloFiltroAvancadoAtrasado = "Atrasado";
const rotuloFiltroAvancadoSolicitante = "Solicitante";
const rotuloFiltroAvancadoCriticidade = "Criticidade";

//
const HELPDESK_FILTRO_SALVO = "HELPDESK_FILTRO_STORAGE";

const helpdeskObterFiltrosAvancadosSalvos = () => {
  const localStorage = window.localStorage || null;

  if (localStorage && localStorage.getItem(HELPDESK_FILTRO_SALVO)?.length > 0) {
    const filtros = JSON.parse(localStorage.getItem(HELPDESK_FILTRO_SALVO));
    const listaFiltros = _.map(_.keys(filtros), (k) => ({
      titulo: k,
      valor: filtros[k],
    }));
    return listaFiltros;
  }
  return [];
};
//
const helpdeskSalvarFiltrosAvancados = (nome, valores, t) => {
  const localStorage = window.localStorage || null;
  if (!localStorage) {
    alert(t("helpdesk.labelNoAccessToLocalStorage"));
    return false;
  }
  if (localStorage.getItem(HELPDESK_FILTRO_SALVO)?.length > 0) {
    const filtros = JSON.parse(localStorage.getItem(HELPDESK_FILTRO_SALVO));
    filtros[nome] = valores;
    localStorage.setItem(HELPDESK_FILTRO_SALVO, JSON.stringify(filtros));
  } else {
    localStorage.setItem(
      HELPDESK_FILTRO_SALVO,
      JSON.stringify({ [nome]: valores })
    );
  }
};
//
const helpdeskExcluirFiltrosAvancados = (nome) => {
  const localStorage = window.localStorage || null;
  if (!localStorage) {
    return false;
  }

  if (localStorage.getItem(HELPDESK_FILTRO_SALVO)?.length > 0) {
    const filtros = JSON.parse(localStorage.getItem(HELPDESK_FILTRO_SALVO));
    const novo = {};
    _.forEach(_.keys(filtros), (k) => {
      if (k !== nome) {
        novo[k] = filtros[k];
      }
    });

    localStorage.setItem(HELPDESK_FILTRO_SALVO, JSON.stringify(novo));
  }
};

function HelpdeskFiltroAvancado() {
  const { t } = useTranslation();
  const tituloFiltroAvancado = t("helpdesk.titleFilterAdvancedChooseFilter");
  const tituloSemFiltroSelecionado = t(
    "helpdesk.titleFilterAdvancedNoFilterSelected"
  );
  const rotuloFiltroAvancadoSalvar = t(
    "helpdesk.labelFilterAdvancedSaveFilter"
  );
  const rotuloPerguntaSalvarFiltro = t(
    "helpdesk.labelFilterAdvancedQuestionSaveFilter"
  );

  //
  const dispatch = useDispatch();
  const [set, { has, toggle }] = useSet(new Set([]));
  const [salvarFiltro, setSalvarFiltro] = useToggle();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const history = useHistory();
  const largura = useTheme().breakpoints.values.md;

  const tiposDeFiltro = [
    {
      label: t("helpdesk.labelFilterAdvancedTicket"),
      rotulo: rotuloFiltroAvancadoTicket,
      icone: "ConfirmationNumber",
    },
    {
      label: t("helpdesk.labelFilterAdvancedSubject"),
      rotulo: rotuloFiltroAvancadoAssunto,
      icone: "Edit",
    },
    {
      label: t("helpdesk.labelFilterAdvancedAgent"),
      rotulo: rotuloFiltroAvancadoAgente,
      icone: "Engineering",
    },
    {
      label: t("helpdesk.labelFilterAdvancedStatus"),
      rotulo: rotuloFiltroAvancadoStatus,
      icone: "SwapHoriz",
    },
    {
      label: t("helpdesk.labelFilterAdvancedDelayed"),
      rotulo: rotuloFiltroAvancadoAtrasado,
      icone: "Schedule",
    },
    {
      label: t("helpdesk.labelFilterAdvancedApplicant"),
      rotulo: rotuloFiltroAvancadoSolicitante,
      icone: "Person",
    },
    {
      label: t("helpdesk.labelFilterAdvancedSeverity"),
      rotulo: rotuloFiltroAvancadoCriticidade,
      icone: "PriorityHigh",
    },
  ];
  //
  const onSubmit = useCallback(
    (valor) => {
      const itens = [];
      Object.keys(valor).forEach((k) => {
        itens.push([
          k,
          Array.isArray(valor[k])
            ? _.map(valor[k], (arr) => arr.value).join(",")
            : k === "atrasado"
            ? valor[k]
            : valor[k].value,
        ]);
      });
      // Para salvar os filtros avancados
      if (salvarFiltro) {
        const nome = window.prompt(rotuloPerguntaSalvarFiltro);
        if (nome) {
          helpdeskSalvarFiltrosAvancados(nome, itens);
        }
      }
      // Se for mobile devemos retornar a pagina
      let funcaoRetornarSucesso;

      if (isMobile) {
        funcaoRetornarSucesso = history.goBack;
      }
      const parametros = new URLSearchParams(itens);

      dispatch(
        helpdeskFiltroAdd(parametros, "filtro_avancado", funcaoRetornarSucesso)
      );
    },
    [dispatch, rotuloPerguntaSalvarFiltro, salvarFiltro, isMobile, history]
  );
  return (
    <Container
      maxWidth="lg"
      sx={{ width: !isMobile ? largura : "auto", minHeight: "50vh" }}
    >
      <H6>{tituloFiltroAvancado}</H6>
      <Divider sx={{ my: 1 }} />
      <Stack
        direction="row"
        sx={{ width: "100%", overflowX: "auto" }}
        spacing={1}
      >
        {tiposDeFiltro.map((ele, idx) => (
          <Chip
            key={idx}
            clickable
            color="primary"
            onClick={() => toggle(ele.rotulo)}
            label={ele.label}
            variant={has(ele.rotulo) ? "filled" : "outlined"}
            icon={<Icone icone={ele.icone} />}
          />
        ))}
      </Stack>
      {set.size === 0 ? (
        <AnimacaoSemDados titulo={tituloSemFiltroSelecionado} />
      ) : (
        <Grow in unmountOnExit>
          <Box>
            <HelpdeskFiltroAvancadoForm
              filtros={[...set]}
              onSubmit={onSubmit}
            />
            <FormControlLabel
              control={<Switch />}
              onChange={setSalvarFiltro}
              value={salvarFiltro}
              label={rotuloFiltroAvancadoSalvar}
            />
          </Box>
        </Grow>
      )}
      <HelpdeskFiltroAvancadoSalvo />
    </Container>
  );
}

const HelpdeskFiltroAvancadoForm = ({ filtros, onSubmit }) => {
  const { t } = useTranslation();
  const erroFiltroAvancadoTicket = t("helpdesk.errorFilterAdvancedTicket");
  const erroFiltroAvancadoAssunto = t("helpdesk.errorFilterAdvancedSubject");
  const erroFiltroAvancadoAgente = t("helpdesk.errorFilterAdvancedAgent");
  const erroFiltroAvancadoStatus = t("helpdesk.errorFilterAdvancedStatus");
  const erroFiltroAvancadoSolicitante = t(
    "helpdesk.errorFilterAdvancedApplicant"
  );
  const errorFiltroAvancadoCriticidade = t(
    "helpdesk.errorFilterAdvancedSeverity"
  );
  //
  const ticketLista = useSelector(selectTickets);
  const ticketAssunto = useSelector(selectAssuntos);
  const ticketStatus = useSelector(selectStatusFormatado);
  const ticketAgentes = useSelector(selectAgentesFormatado);
  const ticketSolicitantes = useSelector(selectColaboradoresFormatadosFiltro);
  const ticketCriticidade = useSelector(selectCriticidade);

  const schema = [];
  const schemaMessageError = {};
  let schemaValidator = {};
  filtros.forEach((ele) => {
    switch (ele) {
      case rotuloFiltroAvancadoAtrasado:
        schema.push({
          label: t("helpdesk.labelFilterAdvancedDelayed"),
          type: "switch",
          name: "atrasado",
        });
        break;
      case rotuloFiltroAvancadoTicket:
        schema.push({
          label: t("helpdesk.labelFilterAdvancedTicket"),
          type: "select",
          name: "id_ticket",
          isMulti: true,
          autoFormat: true,
          itens: ticketLista,
        });
        schemaMessageError.id_ticket = erroFiltroAvancadoTicket;
        schemaValidator.id_ticket = obterValidador(
          VALIDADOR_TIPO.selectMultiplo
        );
        break;
      case rotuloFiltroAvancadoAssunto:
        schema.push({
          label: t("helpdesk.labelFilterAdvancedSubject"),
          type: "select",
          name: "assunto",
          isMulti: true,
          autoFormat: true,
          itens: ticketAssunto,
        });
        schemaMessageError.assunto = erroFiltroAvancadoAssunto;
        schemaValidator.assunto = obterValidador(VALIDADOR_TIPO.selectMultiplo);
        break;
      case rotuloFiltroAvancadoAgente:
        schema.push({
          label: t("helpdesk.labelFilterAdvancedAgent"),
          type: "select",
          name: "agente",
          isMulti: true,
          autoFormat: true,
          itens: ticketAgentes,
        });
        schemaMessageError.agente = erroFiltroAvancadoAgente;
        schemaValidator.agente = obterValidador(VALIDADOR_TIPO.selectMultiplo);
        break;
      case rotuloFiltroAvancadoSolicitante:
        schema.push({
          label: t("helpdesk.labelFilterAdvancedApplicant"),
          type: "select",
          name: "solicitante",
          isMulti: true,
          autoFormat: true,
          itens: ticketSolicitantes,
        });
        schemaMessageError.solicitante = erroFiltroAvancadoSolicitante;
        schemaValidator.solicitante = obterValidador(
          VALIDADOR_TIPO.selectMultiplo
        );
        break;
      case rotuloFiltroAvancadoCriticidade:
        schema.push({
          label: t("helpdesk.labelFilterAdvancedSeverity"),
          type: "select",
          name: "criticidade",
          autoFormat: true,
          isMulti: true,
          itens: ticketCriticidade,
        });
        schemaMessageError.criticidade = errorFiltroAvancadoCriticidade;
        schemaValidator.criticidade = obterValidador(
          VALIDADOR_TIPO.selectMultiplo
        );
        break;
      case rotuloFiltroAvancadoStatus:
      default:
        schema.push({
          label: t("helpdesk.labelFilterAdvancedStatus"),
          type: "select",
          name: "status",
          autoFormat: true,
          isMulti: true,
          itens: ticketStatus,
        });
        schemaMessageError.status = erroFiltroAvancadoStatus;
        schemaValidator.status = obterValidador(VALIDADOR_TIPO.selectMultiplo);
        break;
    }
  });
  //
  schemaValidator = yup.object().shape(schemaValidator);

  return (
    <EntradaForm
      schema={schema}
      schemaMessageError={schemaMessageError}
      schemaValidator={schemaValidator}
      onSubmit={onSubmit}
    />
  );
};
//
const HelpdeskFiltroAvancadoSalvo = () => {
  const { t } = useTranslation();
  const tituloFiltroAvancadoFiltrosSalvos = t(
    "helpdesk.titleFilterAdvancedMyFilters"
  );
  const dispatch = useDispatch();
  const [, setToggle] = useToggle();
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  const history = useHistory();
  const listaFiltroSalvos = helpdeskObterFiltrosAvancadosSalvos();

  const funcaoAplicarFiltro = useCallback(
    (filtro) => {
      const parametros = new URLSearchParams(filtro);
      let funcaoRetornarSucesso;
      if (isMobile) {
        funcaoRetornarSucesso = history.goBack;
      }
      dispatch(
        helpdeskFiltroAdd(parametros, "filtro_avancado", funcaoRetornarSucesso)
      );
    },
    [dispatch, isMobile, history]
  );
  //
  const funcaoExcluirFiltro = useCallback(
    (nome) => {
      if (window.confirm(t("helpdesk.titleFilterAdvancedDeleteFilter"))) {
        helpdeskExcluirFiltrosAvancados(nome);
        setToggle();
      }
    },
    [setToggle, t]
  );

  return (
    <>
      {listaFiltroSalvos?.length > 0 && (
        <H6>{tituloFiltroAvancadoFiltrosSalvos}</H6>
      )}
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {listaFiltroSalvos?.map((ele) => (
          <Chip
            onDelete={() => funcaoExcluirFiltro(ele.titulo)}
            label={ele.titulo}
            key={ele.titulo}
            clickable
            onClick={() => funcaoAplicarFiltro(ele.valor)}
            variant="outlined"
            color="primary"
          />
        ))}
      </Stack>
    </>
  );
};

export default HelpdeskFiltroAvancado;
