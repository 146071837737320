import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useSelector } from "react-redux";
import { selectorCardSecurityGetModel } from "./cartao-seguranca-selectors";

import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import Logo from "../../images/logo.svg";
import { Body1 } from "../../components";

// Componente que exibe os detalhes do cartao
const VerDetalhesCartao = ({
  cartao_x_validacao,
  id_cartao,
  cartao_x_justificativa,
  cartao_x_solucao,
  cartao_x_anexo,
  cartao_tipo,
  cartao_tipo_x_categoria,
  cartao,
  cartao_x_modelo,
}) => {
  const { t } = useTranslation();
  // Verifica se e mobile
  const isMobile = useTheme()?.breakpoints?.values["sm"] > window.innerWidth;
  // Controla o estado da exibicao do lado do cartao
  const [virar, setVirar] = useState(false);
  //
  return (
    <Container
      disableGutters={isMobile}
      sx={{ py: 2, maxHeight: "80vh" }}
      maxWidth="md"
    >
      <FormControlLabel
        control={
          <Switch
            checked={virar}
            onChange={(e) => setVirar(e.target.checked)}
          />
        }
        label={`${t("cartao_seguranca.view")} ${
          virar ? t("cartao_seguranca.front") : t("cartao_seguranca.back")
        }`}
      />
      <ReactCardFlip flipDirection="horizontal" isFlipped={virar}>
        <LayoutCartao cor={cartao.cor}>
          <CartaoLadoA
            onClick={() => setVirar(true)}
            cartao={cartao}
            id_cartao={id_cartao}
            cartao_x_validacao={cartao_x_validacao}
            cartao_tipo={cartao_tipo}
            cartao_tipo_x_categoria={cartao_tipo_x_categoria}
          />
        </LayoutCartao>
        <LayoutCartao cor={cartao.cor}>
          <CartaoLadoB
            onClick={() => setVirar(false)}
            id_cartao={id_cartao}
            cartao_x_solucao={cartao_x_solucao}
            cartao_x_justificativa={cartao_x_justificativa}
          />
        </LayoutCartao>
      </ReactCardFlip>

      <Divider sx={{ my: 2 }} />
      <Stack spacing={1} direction="row" flexWrap="wrap">
        {cartao_x_anexo &&
          cartao_x_anexo[cartao.id] &&
          cartao_x_anexo[cartao.id].map((ele, idx) => (
            <Button
              size="small"
              variant="outlined"
              target="_blank"
              key={idx}
              href={ele}
            >
              {idx + 1}° {t("cartao_seguranca.attachment")}
            </Button>
          ))}
      </Stack>
      <br />
      <br />
    </Container>
  );
};

// Componente simples para dar cor e envolver o cartao
const LayoutCartao = ({ cor, children }) => {
  return (
    <Paper sx={{ minHeight: 600, p: 2, backgroundColor: cor, color: "black" }}>
      {children}
    </Paper>
  );
};
// Componente para gerar o cartao do lado A
const CartaoLadoA = ({
  id_cartao,
  cartao_x_validacao,
  cartao_tipo_x_categoria,
  cartao,
  cartao_tipo,
}) => {
  const { t } = useTranslation();
  const [matricula_lider, nome_lider] = cartao.mat_lider.split("-");
  const [matricula_cartao, nome_matricula_cartao] = cartao.matricula.split("-");
  //
  const cartaoModelo = useSelector(selectorCardSecurityGetModel(id_cartao));
  // Pega a lista de tipos dos cartoes baseado no cartao selecionado
  const lista_categoria =
    cartao_tipo_x_categoria[
      cartao_tipo.filter((ele) => ele[1] === cartao.cartao_tipo)[0][0]
    ];
  const objJust = cartao_x_validacao?.hasOwnProperty(id_cartao)
    ? cartao_x_validacao[id_cartao]
    : null;

  // Funcao que cria um validador do titulo
  const fnTituloComplemento = (titu) => {
    switch (titu) {
      case "AMBIENTAL":
        return t("cartao_seguranca.titleCompleteEnvironment");
      case "SAÚDE":
        return t("cartao_seguranca.titleCompleteHealth");
      case "SEGURANÇA":
        return t("cartao_seguranca.titleCompleteSafety");
      default:
        return `${t("cartao_seguranca.titleCompleteValidation")} ${titu}`;
    }
  };

  return (
    <Stack>
      <Stack
        sx={{ backgroundColor: "white" }}
        alignItems="center"
        justifyContent="space-evenly"
        direction="row"
      >
        <img alt="Logo Tiberina" src={Logo} height={24} />
        <Divider orientation="vertical" flexItem />
        <Typography variant="h6">
          {t("cartao_seguranca.card")} {cartao.cartao_tipo}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <Typography variant="subtitle2">N° {cartao.id}</Typography>
          <Typography variant="subtitle2">
            {t("cartao_seguranca.date")}:{" "}
            {format(parseISO(cartao.data_abertura), "dd/MM/yy")}
          </Typography>
        </Stack>
      </Stack>
      <Typography sx={{ color: "white" }} variant="h6" align="center">
        {t("cartao_seguranca.identify")}
      </Typography>
      <Stack
        sx={{ p: 1, backgroundColor: "white" }}
        justifyContent="space-between"
        direction="row"
      >
        <Typography variant="subtitle2">
          {t("cartao_seguranca.name")}: {nome_matricula_cartao}
        </Typography>

        <Typography variant="subtitle2">
          {t("cartao_seguranca.mat")}: {matricula_cartao}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        sx={{ p: 1, backgroundColor: "white" }}
        justifyContent="space-between"
        direction="row"
      >
        <Typography variant="subtitle2">
          {t("cartao_seguranca.leaderName")}: {nome_lider}
        </Typography>

        <Typography variant="subtitle2">
          {t("cartao_seguranca.mat")}: {matricula_lider}
        </Typography>
      </Stack>
      <Divider />
      <Stack sx={{ p: 1, backgroundColor: "white" }} direction="row">
        <Typography variant="subtitle2">
          {t("cartao_seguranca.areaAndTurn")}: {cartao.turno}
        </Typography>
      </Stack>
      <Typography sx={{ color: "white" }} variant="h6" align="center">
        {t("cartao_seguranca.descriptionProblem")}
      </Typography>
      <Stack
        sx={{ backgroundColor: "white" }}
        justifyContent="center"
        direction={{ xs: "column", md: "row" }}
      >
        {lista_categoria.map((ele) => (
          <FormControlLabel
            key={ele[0]}
            control={<Checkbox checked={ele[1] === cartao.categoria} />}
            label={ele[1]}
          />
        ))}
      </Stack>
      <Stack sx={{ px: 1, backgroundColor: "white" }} direction="row">
        <Typography fontWeight="bold" component="span">
          {t("cartao_seguranca.local")}: {cartao.area}
        </Typography>
      </Stack>
      <Divider />
      <Stack sx={{ px: 1, backgroundColor: "white" }} direction="row">
        {cartaoModelo && (
          <Typography fontWeight="bold" component="span">
            {t("cartao_seguranca.model")}: {cartaoModelo[1]}
          </Typography>
        )}
      </Stack>
      <Divider />
      <Stack sx={{ px: 1, backgroundColor: "white" }} direction="row">
        <Typography fontWeight="bold" component="span">
          {t("cartao_seguranca.activity")}:
        </Typography>
      </Stack>
      <Divider />
      <Stack sx={{ px: 1, backgroundColor: "white" }} direction="row">
        <Typography fontWeight="bold" component="span">
          {t("cartao_seguranca.operation")}: {cartao.operacao}
        </Typography>
      </Stack>
      <Divider />
      <Stack sx={{ px: 1, backgroundColor: "white" }} direction="column">
        <Typography fontWeight="bold">
          {t("cartao_seguranca.description")}:
        </Typography>
        <Typography variant="body2">{cartao.descricao}</Typography>
      </Stack>
      <Typography sx={{ color: "white" }} variant="h6" align="center">
        {fnTituloComplemento(cartao.cartao_tipo.toUpperCase())}
      </Typography>
      <Stack sx={{ p: 1, backgroundColor: "white" }} direction="column">
        <Typography fontWeight="bold" align="center">
          {t("cartao_seguranca.justifyAndObservation")}
        </Typography>
        <Stack direction="row" gap={1}>
          <Typography sx={{ py: objJust ? 0 : 6, flex: 1 }} variant="body2">
            {objJust && objJust?.justificativa}
          </Typography>
          <Body1>
            {t("cartao_seguranca.sector")}: {cartao?.setor && cartao.setor}
          </Body1>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row">
          <Typography sx={{ flex: 2 }} variant="body2">
            {t("cartao_seguranca.name")}:{" "}
            {objJust && objJust?.matricula.split("-")[1]}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography sx={{ mx: 1, flex: 1 }} variant="body2">
            {t("cartao_seguranca.mat")}:{" "}
            {objJust && objJust?.matricula.split("-")[0]}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography sx={{ mx: 1, flex: 1 }} variant="body2">
            {t("cartao_seguranca.date")}:{" "}
            {objJust &&
              objJust?.data_inclusao &&
              format(parseISO(objJust.data_inclusao), "dd/MM/yy H:m")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
// Componente para gerar o cartao do lado B
const CartaoLadoB = ({
  id_cartao,
  cartao_x_justificativa,
  cartao_x_solucao,
}) => {
  const { t } = useTranslation();
  const obj = cartao_x_solucao?.hasOwnProperty(id_cartao)
    ? cartao_x_solucao[id_cartao]
    : null;
  const objJust = cartao_x_justificativa?.hasOwnProperty(id_cartao)
    ? cartao_x_justificativa[id_cartao]
    : null;

  return (
    <Stack>
      <Typography sx={{ color: "white" }} variant="h6" align="center">
        {t("cartao_seguranca.planType")}
      </Typography>

      <Stack sx={{ p: 1, backgroundColor: "white" }} direction="row">
        <Typography sx={{ mr: 1 }} fontWeight="bold" component="subtitle2">
          {t("cartao_seguranca.rootCause")}:
        </Typography>

        <Typography variant="body1">{obj && obj.causa_raiz}</Typography>
      </Stack>
      <Divider />
      <Stack sx={{ p: 1, backgroundColor: "white" }} direction="row">
        <Typography fontWeight="bold" component="subtitle2">
          {t("cartao_seguranca.actions")}:
        </Typography>
        <Typography
          sx={{ ml: 1, py: obj === null ? 10 : 0, flex: 1 }}
          variant="body1"
        >
          {obj && obj.acao_prevista}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={1}>
          <Typography sx={{ px: 0.5 }} variant="caption">
            <Typography component="span" variant="caption" fontWeight="bold">
              {t("cartao_seguranca.dateInclude")}: &nbsp;
            </Typography>
            {obj && format(parseISO(obj?.data_inclusao), "dd/MM/yy")}
          </Typography>
          <Divider />
          <Typography sx={{ px: 0.5 }} variant="caption">
            <Typography component="span" variant="caption" fontWeight="bold">
              {t("cartao_seguranca.datePreview")}: &nbsp;
            </Typography>
            {obj && format(parseISO(obj?.data_prevista), "dd/MM/yy")}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack sx={{ p: 1, backgroundColor: "white" }} direction="row">
        <Typography sx={{ flex: 2 }} variant="body2">
          {t("cartao_seguranca.name")}:{" "}
          {obj && obj?.mat_nome_lider.split("-")[1]}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography sx={{ mx: 1, flex: 1 }} variant="body2">
          {t("cartao_seguranca.mat")}:{" "}
          {obj && obj?.mat_nome_lider.split("-")[0]}
        </Typography>
      </Stack>
      <Divider />

      <Typography sx={{ color: "white" }} variant="h6" align="center">
        {t("cartao_seguranca.analyticsAndEfficiency")}
      </Typography>
      <Stack sx={{ p: 1, backgroundColor: "white" }} direction="column">
        <Typography fontWeight="bold" align="center">
          {t("cartao_seguranca.justifyAndObservation")}
        </Typography>
        <Typography sx={{ py: objJust ? 0 : 6 }} variant="body2">
          {objJust && objJust?.justificativa}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Stack direction="row">
          <Typography sx={{ flex: 2 }} variant="body2">
            {t("cartao_seguranca.name")}:{" "}
            {objJust && objJust?.matricula.split("-")[1]}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography sx={{ mx: 1, flex: 1 }} variant="body2">
            {t("cartao_seguranca.mat")}:{" "}
            {objJust && objJust?.matricula.split("-")[0]}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography sx={{ mx: 1, flex: 1 }} variant="body2">
            {t("cartao_seguranca.date")}:{" "}
            {objJust &&
              objJust?.data_inclusao &&
              format(parseISO(objJust.data_inclusao), "dd/MM/yy H:m")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VerDetalhesCartao;
