import { Container, List, Paper } from "@mui/material";
import React from "react";
import PrizeDrawItem from "../components/prize-draw-item";
import PrizeDrawOptionsAuditor from "../components/prize-draw-options-auditor";
import { green, red } from "@mui/material/colors";

function EscalonadaCalendarioLista({ escalonates }) {
  return (
    <Container maxWidth={false}>
      <List>
        {escalonates.map((ele) => (
          <Paper
            sx={{
              my: 1,
              border: `3px solid ${
                ele.escalonada.questionario_respondido ? green[500] : red[500]
              }`,
            }}
            elevation={3}
            key={ele.id}
          >
            <PrizeDrawItem
              item={ele.escalonada}
              id={ele.escalonada.id}
              areaName={ele.escalonada.area_nome}
              modelsName={ele.escalonada.modelos}
              name={ele.escalonada.nome}
              mat={ele.escalonada.matricula}
              avatar={ele.auditor_avatar}
              plant={ele.escalonada.planta}
              dateCreated={ele.escalonada.data_criacao}
              dateRegister={ele.escalonada.data_registro}
              status={ele.escalonada.status}
              pontuation={ele.escalonada.pontuacao}
              auditor={`${ele.auditor_matricula} - ${ele.auditor_nome}`}
              questionAnswer={ele.escalonada.questionario_respondido}
              attachment={ele.anexo}
              options={
                <PrizeDrawOptionsAuditor
                  id={ele.escalonada.id}
                  item={ele.escalonada}
                  idCalendar={ele.id}
                />
              }
            />
          </Paper>
        ))}
      </List>
    </Container>
  );
}

export default EscalonadaCalendarioLista;
